<template>
  <div id="section-unity" ref="sectionMain" class="sections section-support page-wrapper" style="text-align:center;">
    <div class="section-wrapper full no-pad pos-relative" style="padding:80px 0;">
      <!-- <div class="section-inner-wrapper flex flex-dir-row flex-dir-col-mobile">
        <div class="statement flex flex-center" style="overflow:hidden;">
          <div class="clause-wrapper"
            data-aos="slide-up" data-aos-once="true" data-aos-offset="999999" data-aos-delay="100"
            :class="(isScrollMidPoint ? 'aos-animate' : '')">
            <h4 class="clause-1 font-roboto-mono cwhite" style="font-size:1.2rem;">
              YOUR SUPPORT MATTERS
            </h4>
            <h4 class="clause-2 font-roboto cwhite pad-top">
              Join us to help heal broken hearts by supporting like minded projects.
            </h4>
          </div>
        </div>
        <div class="statement pos-relative">
          <div class="img-wrapper is-cursor is-faded hover-enlarge">
            <div class=""
              data-aos="slide-up" data-aos-once="true" data-aos-offset="999999" data-aos-delay="300"
              :class="(isScrollMidPoint ? 'aos-animate' : '')">
              <img src="../../assets/ahmaud.png"
                class="img-responsive" style="width:100%;"
                :style="{
                  transform: isScrollMidPoint ? 'scale(1.1)' : 'scale(1.0)',
                  transformOrigin: 'center',
                  transition: 'transform 8s ease-in-out',
                }" />
              <div class="hanging flex flex-center div-wrapper">
                <div class="" style="width:100%;text-align:center;">
                  <img src="../../assets/logo_glp.png"
                    class="img-responsive hover-logo"
                    style="width:60%;" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="statement pos-relative is-faded hover-enlarge">
          <div class="img-wrapper is-cursor is-faded hover-enlarge">
            <div class=""
              data-aos="slide-up" data-aos-once="true" data-aos-offset="999999" data-aos-delay="300"
              :class="(isScrollMidPoint ? 'aos-animate' : '')">
              <img src="../../assets/mygood.png"
                class="img-responsive" style="width:100%;"
                :style="{
                  transform: isScrollMidPoint ? 'scale(1.1)' : 'scale(1.0)',
                  transformOrigin: 'center',
                  transition: 'transform 8s ease-in-out',
                }" />
              <div class="hanging flex flex-center div-wrapper">
                <div class="" style="width:100%;text-align:center;">
                  <img src="../../assets/logo_mygood.png"
                    class="img-responsive hover-logo"
                    style="width:60%;" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="section-inner-wrapper flex flex-dir-row flex-dir-col-mobile">
        <div class="statement flex flex-center" style="overflow:hidden;">
          <div class="clause-wrapper"
            data-aos="slide-up" data-aos-once="true" data-aos-offset="999999" data-aos-delay="100"
            :class="(isScrollMidPoint ? 'aos-animate' : '')">
            <h4 class="clause-1 font-proxima cwhite" style="font-size:1.9rem;font-weight:500;">
              IN POWER AND WITH STRENGTH
            </h4>
          </div>
        </div>
        <div class="statement-fill pos-relative" style="overflow:hidden;">
          <h4 class="clause-main cwhite"
            data-aos="slide-up" data-aos-once="true" data-aos-offset="999999" data-aos-delay="100"
            :class="(isScrollMidPoint ? 'aos-animate' : '')">
            There are a number of foundations put in place following the tragic circumstances that surround the loss of a loved one. All too often these foundations are spearheaded by the families of young men and women whose lives have been cut short by inexplicable acts of violence.
          </h4>
          <h4 class="clause-main cwhite"
            data-aos="slide-up" data-aos-once="true" data-aos-offset="999999" data-aos-delay="100"
            :class="(isScrollMidPoint ? 'aos-animate' : '')">
            We stand in support of these organizations, whose mission it is to highlight the need for equitable justice, social education, mental health awareness and community support for families and individuals in need.
          </h4>
          <h4 class="clause-main cwhite"
            data-aos="slide-up" data-aos-once="true" data-aos-offset="999999" data-aos-delay="100"
            :class="(isScrollMidPoint ? 'aos-animate' : '')">
            Please contact us at: <a href="mailto:info@imotherthefilm.com" target="_blank">info@imotherthefilm.com</a> if you believe we can partner to assist in the work of these foundations.
          </h4>
        </div>
      </div>
      <div class="hanging top left flex flex-center is-hidden-mobile" style="height:100%;left:0;">
        <h4 class="hanging about label-unity bordered uppercase font-roboto-black">
          Unity
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash'
// import Constants from '@/constants'
// import EventBus from '@/controllers/EventBus'
// import { getToken } from '@/utils/auth'

// import DivContainer from '@/components/DivContainer.vue'

export default {
  name: 'section-support',
  props: ['last-position'],
  components: {
    // DivContainer,
  },
  data () {
    return {
      // loading: false,
      posY: 0,
      offsetY: 0,
      isScrollMidPoint: false,
    }
  },
  computed: {
  },
  watch: {
    lastPosition: function (value, oldValue) {
      let posY = this.$refs.sectionMain.getBoundingClientRect().y
      let height = this.$refs.sectionMain.getBoundingClientRect().height
      this.$set(this, 'posY', -posY)
      let dheight = document.documentElement.clientHeight
      let diff = (height - dheight) / 2
      diff = diff > 0 ? -diff : diff
      this.$set(this, 'offsetY', diff)
      this.timerRefresh()
    }
  },
  created () {
  },
  async mounted () {
    this.timer = setInterval(() => this.timerRefresh(), 100)
    this.timerRefresh()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    timerRefresh () {
      let refName = 'sectionMain'
      let ref = this.$refs[refName]
      if (!ref) return false
      let rect = ref.getBoundingClientRect()
      let top = rect && rect.top
      let targetHeight = window.innerHeight * 0.78
      let isMidPoint = (top < targetHeight)
      // console.log('-- isMidPoint', ref, isMidPoint, top, targetHeight)
      this.$set(this, 'isScrollMidPoint', isMidPoint)
    },
  }
}
</script>

<style lang="scss">
@import "../../styles/base.scss";
@import "../../styles/sections.scss";

.section-support {
  .statement {
    width: 33.33%;
    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }

  .statement-fill {
    width: 66.66%;
    padding-right: 16.67%;
    @media screen and (max-width: $mobile) {
      width: 100%;
      padding-right: 0;
    }
  }

  .img-wrapper {
    overflow: hidden;
  }

  .clause-wrapper {
    overflow: hidden;
    width: 100%;
    margin: 50px 12% 50px 22%;
    @media screen and (max-width: $mobile) {
      width: 80%;
      margin: 0px 18% 80px;
    }
  }

  .clause-1 {
    @media screen and (max-width: $mobile) {
      font-size: 1.2em;
    }
  }

  .clause-2 {
    margin-top: 12px;
    line-height: 1.5em;
    @media screen and (max-width: $mobile) {
    }
  }

  .clause-main {
    margin-top: 12px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .div-wrapper {
    top: 0;
    height: 100%;
    width: 100%;
  }

  .section-wrapper {
    @media screen and (max-width: $mobile) {
      padding: 80px 0 0 !important;
    }
  }

  .label-unity {
    left: -48px;
    background-color: white;
    color: black !important;
    font-family: 'Roboto Black' !important;
    font-size: 1.2rem !important;
    letter-spacing: 5px;
    width: 150px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 12px 34px;
    transform: rotate(-90deg);

    @media screen and (max-width: $mobile) {
      top: 100px;
      left: -50px;
      font-size: 1.0rem !important;
      width: 140px;
      padding: 10px 28px;
    }

    @media screen and (min-width: $tablet) and (max-width: ($desktop - 1)) {
      left: -48px;
      font-size: 1.2rem !important;
      width: 140px;
      padding: 10px 28px;
    }
  }

  .hover-enlarge {
    .hover-logo {
      transform: scale(1.0);
      transition: transform 0.2s ease-in;
    }
    &:hover .hover-logo {
      transform: scale(1.1);
    }
  }
}

</style>
