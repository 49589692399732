<template>
  <el-dialog
    v-if="visible"
    class="dialog-vimeo"
    custom-class="dialog-base wrapper dialog-vimeo"
    :title="undefined"
    :visible="visible"
    :width="'90%'"
    :before-close="onClose"
    :close-on-click-modal="closeOnClickModal != null ? closeOnClickModal : true">
    <!-- <div slot="title" class="title-content">
    </div> -->
    <div class="el-dialog__content"
      style="margin-left:auto;margin-right:auto;">
      <div class="embed-container">
        <!-- <vimeo-player ref="player" :video-id="videoID" @ready="onReady" :player-height="height"></vimeo-player> -->
        <iframe
          :src="videoSrc"
          frameborder='0'
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen>
        </iframe>
      </div>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <div class="footer-content has-text-right">
        <el-button
          type="info"
          @click="onClose">
          OK
        </el-button>
      </div>
    </span> -->
  </el-dialog>
</template>

<script>
// import _ from 'lodash'
// import Constants from '@/constants'
// import EventBus from '@/controllers/EventBus'
// import { getToken } from '@/utils/auth'

export default {
  name: 'dialog-vimeo',
  props: ['close-on-click-modal'],
  components: {
  },
  data () {
    return {
      visible: false,
      videoSrc: undefined,
      // videoSrc: 'https://player.vimeo.com/video/685896627?h=ba70c01db9',
      // videoID: '685896627',
      // videoHash: 'ba70c01db9',
			height: 500,
			options: {
				muted: true,
        autoplay: true,
			},
			playerReady: false,
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  async mounted () {
  },
  methods: {
    onReady() {
			this.playerReady = true
		},
		play () {
			this.$refs.player.play()
		},
		pause () {
			this.$refs.player.pause()
		},

    show (src) {
      this.$set(this, 'videoSrc', src)
      this.$set(this, 'visible', true)
    },

    // dialog
    onClose () {
      this.$set(this, 'visible', false)
      this.$emit('on-close')
    },
    // onSubmit () {
    //   this.$emit('on-submit')
    // },
  }
}
</script>

<style lang="scss">
@import "../../styles/base.scss";
@import "../../styles/dialog.scss";
@import "../../styles/sections.scss";

.dialog-vimeo {
  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

</style>
