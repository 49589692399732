<template>
  <div style="width:100%" :style="[{ backgroundColor: (backgroundColor ? backgroundColor : undefined) }]">
    <div class="div-container container" :class="[{ 'is-fluid': full === 'true' }]" :style="[{ paddingTop: (paddingTop ? paddingTop : undefined), paddingBottom: (paddingBottom ? paddingBottom : undefined) }]" >
      <div class="columns is-gapless is-centered is-vcentered">
        <div class="column" :class="[{ 'is-10': full !== 'true' && !columnNum }, columnNum ]">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'div-container',
  props: ['full', 'columnNum', 'paddingTop', 'paddingBottom', 'backgroundColor'],
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  mounted () {
    this.refresh()
  },
  methods: {
    onScrollTo: function (name) {
      this.$scrollTo('#' + name)
    }.bind(this),

    refresh: function () {
    }
  }
}
</script>

<style lang="scss">
@import "../styles/app_setting.scss";

// div container
// it serve as intermediate level between parent and child
// so top or bottom padding is not needed

.div-container {
  // background-color: $color-orange;
  padding-left: 15px;
  padding-right: 15px;

  &.container.is-fluid {
    padding: 0;
    margin: 0;
  }
}

</style>
