<template>
  <el-dialog
    class="dialog-cast"
    custom-class="dialog-base wrapper dialog-cast"
    v-if="visible"
    :title="undefined"
    :visible="visible"
    :width="'90%'"
    :before-close="onClose"
    :close-on-click-modal="closeOnClickModal != null ? closeOnClickModal : true">
    <!-- <div slot="title" class="title-content">
    </div> -->
    <div class="el-dialog__content"
      style="margin-left:auto;margin-right:auto;">
      <div id="section-film" class="sections section-film page-wrapper">
        <div class="section-wrapper full" style="padding-top:30px;padding-bottom:30px;">
          <h2 class="statement title roboto uppercase cwhite"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="300" data-aos-delay="100"
            style="margin-bottom:12px;">
            Cast
          </h2>

          <h4 class="statement"
             data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="200">
            <b>I Mother</b> – Troy Titus-Barrow<br />
            <b>Son</b> – Dakwan Simmons<br />
            <b>Father</b> – Drew Barnes<br />
            <b>Mother</b> – Namakula<br />
            <b>Polaroid Boy</b> – Zepher Hawke<br />
            <br />
            <b>Spoken word reading</b><br />
            <span class="padded">Shola Adewusi</span><br />
            <span class="padded">Namakula</span><br />
            <span class="padded">Daniel Ezra</span><br />
          </h4>

          <h2 class="statement title roboto uppercase cwhite"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="300" data-aos-delay="300"
            style="margin-bottom:12px;">
            Featured Interviewee’s
          </h2>

          <h4 class="statement"
             data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="400">
            Cysoline Allen<br />
            Marcia Allen<br />
            Shakimah Davis<br />
            Junita 'Neicy' Whitaker
          </h4>

          <h2 class="statement title roboto uppercase cwhite"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="300" data-aos-delay="500"
            style="margin-bottom:12px;">
            Portrait Models
          </h2>

          <h4 class="statement"
             data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="600">
            Clorisa Allen<br />
            Johntae Brown<br />
            Rah-Shon Davis<br />
            Marlon Founsette<br />
            Quasim Hicks<br />
            Carolina Martinez<br />
            Ferne Martinez<br />
            Aaliyah Perkins<br />
            Kyiana Perkins<br />
            Sha-Cor Perkins<br />
            Novah Pless<br />
            Rahul Sharma<br />
            Jamal Wade<br />
          </h4>

          <h2 class="statement title roboto uppercase cwhite"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="300" data-aos-delay="700"
            style="margin-bottom:12px;">
            Crew
          </h2>

          <h4 class="statement"
             data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="800">
            <b>Executive Producer</b> - Dee Allen-Barnes<br />
            <b>Executive Producer</b> - Marium Raja<br />
            <b>Co Executive Producer</b> - Troy Titus-Barrow<br />
            <br />
            <b>Producer</b> – Tia Perkins<br />
            <b>Producer</b> – Alexandra Leal-Allen<br />
            <b>Producer</b> – Danika Casas<br />
            <b>Associate Producer</b> – Namakula Nasejje Musoke<br />
            <br />
            <b>Director</b> – Dee Allen-Barnes<br />
            <b>Cinematographer</b> – Michael Girandola<br />
            <b>1st Assistant Camera</b> – Julian Focareta<br />
            <b>1st Assistant Camera</b> – Shad Melivin<br />
            <b>2nd Assistant Camera</b> – Joy Grant<br />
            <b>Camera PA &amp; Sound</b> – Rahul Sharma<br />
            <b>Camera PA</b> – Gysai Mitchel<br />
            <b>Camera PA</b> – Guilermo Lugo<br />
            <b>Grip</b> – Matt Blum<br />
            <b>Gaffer</b> – Christopher Fisher<br />
            <b>Assistant Gaffer</b> – Michael "Banjo" Mullhollan<br />
            <b>Props Master</b> – Alex Rasta<br />
            <b>Assistant Props</b> – Sean Stewart<br />
            <b>Wardobe Stylist</b> – Vincent Oshin<br />
            <br />
            <b>Hair &amp; Make-up Department</b><br />
            <span class="padded">Koni Bennett</span><br />
            <span class="padded">Suzi Hale</span><br />
            <span class="padded">Carolina Martinez</span><br />
            <span class="padded">Ferne Martinez</span><br />
            <br />
            <b>Production Assistants</b><br />
            <span class="padded">Camille Burkett</span><br />
            <span class="padded">Jase Clemmons</span><br />
            <span class="padded">Alan Fernandez</span><br />
            <span class="padded">Blake Rice</span><br />
            <span class="padded">Vivanna Rivera-Castrillon</span><br />
            <span class="padded">Victor Torres</span><br />
            <br />
            <b>Storyboard Artists</b><br />
            <span class="padded">John McKey</span><br />
            <span class="padded">Takia Dunlop-Prophet</span><br />
          </h4>

          <h2 class="statement title roboto uppercase cwhite"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="300" data-aos-delay="900"
            style="margin-bottom:12px;">
            Post Production
          </h2>

          <h4 class="statement"
             data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="800">
            <b>Editor</b> – Sewra G Kidane<br />
            <br />
            <b>Titles Sequence Design</b><br />
            <span class="padded">Elliott Chaffer</span><br />
            <span class="padded">Mae Mann</span><br />
            <span class="padded">Anne Trotman</span><br />
            <br />
            <b>VFX Supervisor and Finishing Artist</b> – Anne Trotman<br />
            <b>Composition & Sound Design</b> – Alexia Riner<br />
            <b>Sound Mix & Additional Design</b> – Mary Walsh<br />
            <br />
            <b>Electric Theater Collective</b><br />
            <b>Colorist</b> – Luke Morrison<br />
            <b>Colorist Executive Producer</b> – Oliver Whitworth<br />
            <!-- <b>Colorist Producer</b> – Oscar Wendt<br /> -->
            <br />
            <b>Harbor Picture Company</b><br />
            <b>Conform Artist</b> – Kevin Szczepanski<br />
            <b>Executive Director Feature & Episodic</b> – Kevin Vale<br />
            <b>Executive Producer</b> – Liz Niles<br />
            <b>DI Producer</b> – Dylan Puchala<br />
            <b>Associate DI Producer</b> – Nick Gammon<br />
            <!-- <b>Director of Sales Feature & Episodic</b> – Rochelle Brown<br /> -->
            <b>Data Operator</b> – Bianca Sanchez<br />
            <b>Imaging Scientist</b> – Matthew Tomlinson<br />
            <b>Imaging Scientist</b> – CJ Julian<br />
            <b>Supervising DI Producer</b> – Peter Boychuk<br />
            <b>Executive Producer</b> – Elizabeth Niles<br />
            <b>Account Executive</b> – Rochelle Brown<br />
            <br />
            <b>Mastering Technicians</b><br />
            <span class="padded">Andrew Minogue</span><br />
            <span class="padded">Anil Balram</span><br />
            <span class="padded">Gino Volpe</span><br />
            <span class="padded">Jorge Piniella</span><br />
            <span class="padded">Matt Mamie</span><br />
            <br />
            <b>Heard City (I Mother Teaser)</b><br />
            <b>Executive Producer</b> – Gloria Pitagorsky<br />
            <b>Sound Design & Mix</b> – Terresa ‘T’ Tate<br />
            <b>Sound Producer</b> – Liana Rosenberg<br />
            <br />
            <b>Fall On Your Sword</b><br />
            <b>Dubbing Mixer/Voice Record</b> – Eric Lutz<br />
            <b>Executive Producer</b> – Lucy Alper<br />
          </h4>

          <!-- <h2 class="statement title roboto uppercase cwhite"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="300" data-aos-delay="900"
            style="margin-bottom:12px;">
            I Mother Teaser
          </h2>

          <h4 class="statement"
             data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="800">
            <b>Sound Mix & Design</b> - T Terressa Tate<br />
            <b>Sound Producer</b> - Liana Rosenberg<br />
            <br />
            <br />
            <b>Production Assistants:</b> Camille Burkett, Jase Clemmons, Alan Fernandez,
            Blake Rice, Victor Torres
          </h4> -->
        </div>
      </div>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <div class="footer-content has-text-right">
        <el-button
          type="info"
          @click="onClose">
          OK
        </el-button>
      </div>
    </span> -->
  </el-dialog>
</template>

<script>
// import _ from 'lodash'
// import Constants from '@/constants'
// import EventBus from '@/controllers/EventBus'
// import { getToken } from '@/utils/auth'

export default {
  name: 'dialog-cast',
  props: ['close-on-click-modal'],
  components: {
  },
  data () {
    return {
      visible: false,
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  async mounted () {
  },
  methods: {
    show () {
      this.$set(this, 'visible', true)
    },
    // dialog
    onClose () {
      this.$set(this, 'visible', false)
      this.$emit('on-close')
    },
    // onSubmit () {
    //   this.$emit('on-submit')
    // },
  }
}
</script>

<style lang="scss">
@import "../../styles/base.scss";
@import "../../styles/dialog.scss";
@import "../../styles/sections.scss";

.dialog-cast {
  padding-bottom: 50px;

  .statement {
    word-break: break-word;
    &.title {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .padded {
      margin-left: 0; // 12px;
    }
  }
}

</style>
