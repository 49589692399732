<template>
  <div id="section-photo" ref="sectionMain" class="sections section-photo page-wrapper pos-relative">

    <!-- <div style="position:absolute;right:0;padding:20px;background-color:magenta;"
      :style="{
        top: `${100 + posY}px`
      }">
      posY: {{ posY.toFixed(2) }}<br />
      height: {{ height.toFixed(2) }}<br />
    </div> -->

    <div class="section-wrapper full">
      <div class="section-inner-wrapper" style="width:100%;">
        <div v-for="(row, rindex) in [0, 1]" :key="`row${rindex}`" :ref="`sec-row-${rindex}`"
          class="sec-wrapper flex" style="overflow:hidden;">
          <div v-for="(col, cindex) in [0, 1]" :key="`row${rindex}col${cindex}`"
            :class="audios[(row * 2) + col].classes"
            @click="onAudioPlay((row * 2) + col)">
            <div class="photo-wrapper pos-relative" :ref="`sec-${(row * 2) + col}`"
              data-aos="slide-up"
              data-aos-once="true"
              data-aos-mirror="true"
              :data-aos-delay="200 * ((row * 2) + col)"
              data-aos-offset="999999"
              data-aos-duration="1200"
              data-aos-easing="easing-custom"
              :class="(getRefInfo(`sec-row-${rindex}`, true).isMidPoint ? 'aos-animate' : '')">
              <img :src="audios[(row * 2) + col].img"
                class="img-responsive"
                :style="{
                  transform: safeTransform(`sec-row-${rindex}`, posY, 0, audios[(row * 2) + col].speedY, (posY / height) + 0.2, audios[(row * 2) + col].speedScale),
                  transformOrigin: 'center',
                  transition: 'transform .8s ease-in-out',
                }" />

              <div class="audio-wave-wrapper"
                :style="{
                  opacity: (audios[(row * 2) + col].isPlaying ? 0.4 : 0.0)
                }">
                <my-av-line
                  v-if="audios[(row * 2) + col].isPlaying"
                  style="margin:auto;"
                  :ref="`audioLine${(row * 2) + col}`"
                  :audio-controls="false"
                  :ref-link="`audioPlayer${(row * 2) + col}`"
                  caps-color="transparent"
                  :canv-width="600"
                  :canv-height="160"
                  :line-color="'#fff'"
                  :line-width="0.5"
                  canv-fill-color="transparent"
                  @playing="onAudioPlaying((row * 2) + col)"
                  @paused="onAudioPaused((row * 2) + col)"
                  @ended="onAudioEnded((row * 2) + col)"
                ></my-av-line>
              </div>

              <div class="audio-wave-wrapper">
                <audio
                  v-if="audios[(row * 2) + col].isPlaying"
                  id="audio" :ref="`audioPlayer${(row * 2) + col}`"
                  :autoplay="false" :loop="false" :controls="false"
                  webkit-playsinline="true"
                  playsinline="true"
                  :muted="false"
                  preload="auto">
                  <source :src="audios[(row * 2) + col].mp4" type="audio/mp4">
                  <source :src="audios[(row * 2) + col].mp3" type="audio/mp3">
                  <source :src="audios[(row * 2) + col].ogg" type="audio/ogg">
                  Your browser does not support the audio element.
                </audio>
              </div>

              <div class="audio-hover">
                <a id="play-pause-button"
                  class="fa"
                  :class="audios[(row * 2) + col].isPaused ? 'fa-play' :
                          audios[(row * 2) + col].isPlaying ? 'fa' : 'fa-play'"
                  style="margin:auto;">
                </a>
              </div>
            </div>
          </div>

          <!-- <div class="sec-2 inline-middle flex flex-end photo-upper-right is-cursor is-audio-hover" @click="onAudioPlay(1)">
            <div class="photo-wrapper" ref="sec2"
              data-aos="slide-up"
              data-aos-once="true"
              data-aos-mirror="true"
              data-aos-delay="0"
              data-aos-offset="999999"
              data-aos-duration="1200"
              data-aos-easing="easing-custom"
              :class="(getRefInfo('sec1').isMidPoint ? 'aos-animate' : '')">
              <img src="../../assets/gallery02.jpg"
                class="img-responsive"
                :style="{
                  transform: safeTransform('sec2', posY, 0, 16, (posY / height) + 0.2, 6),
                  transformOrigin: 'center',
                  transition: 'transform .8s ease-in-out',
                }" />

              <div class="audio-wave-wrapper"
                :style="{
                  opacity: (audios[1].isPlaying ? 0.4 : 0.0)
                }">
                <av-line
                  v-if="isLoaded"
                  style="margin:auto;"
                  ref="audioLine1"
                  :audio-controls="false"
                  ref-link="audioPlayer1"
                  caps-color="transparent"
                  :canv-width="600"
                  :canv-height="160"
                  :line-color="'#fff'"
                  :line-width="0.5"
                  canv-fill-color="transparent"
                  @playing="onAudioPlaying(1)"
                  @paused="onAudioPaused(1)"
                  @ended="onAudioEnded(1)"
                ></av-line>
              </div>

              <div class="audio-wave-wrapper">
                <audio
                  id="audio" ref="audioPlayer1"
                  :autoplay="false" :loop="false" :controls="false"
                  webkit-playsinline="true"
                  playsinline="true"
                  :muted="false"
                  preload="auto">
                  <source :src="audios[1].mp4" type="audio/mp4">
                  <source :src="audios[1].mp3" type="audio/mp3">
                  <source :src="audios[1].ogg" type="audio/ogg">
                  Your browser does not support the audio element.
                </audio>
              </div>

              <div class="audio-hover">
                <a id="play-pause-button"
                  class="fa"
                  :class="audios[1].isPaused ? 'fa-play' :
                          audios[1].isPlaying ? 'fa-pause' : 'fa-play'"
                  style="margin:auto;">
                </a>
              </div>
            </div>
          </div> -->

        </div>
        <!-- <div class="sec-wrapper flex" style="overflow:hidden;">

          <div class="sec-1 inline-middle photo-lower-left is-cursor is-audio-hover" @click="onAudioPlay(2)">
            <div class="photo-wrapper" ref="sec3"
              data-aos="slide-up"
              data-aos-once="true"
              data-aos-mirror="true"
              data-aos-delay="0"
              data-aos-offset="999999"
              data-aos-duration="1200"
              data-aos-easing="easing-custom"
              :class="(getRefInfo('sec3').isMidPoint ? 'aos-animate' : '')">
              <img src="../../assets/gallery03.jpg"
                class="img-responsive"
                :style="{
                  transform: safeTransform('sec3', posY, 0, 10, (posY / height) + 0.2, 16),
                  transformOrigin: 'center',
                  transition: 'transform .8s ease-in-out',
                }" />

              <div class="audio-wave-wrapper"
                :style="{
                  opacity: (audios[2].isPlaying ? 0.4 : 0.0)
                }">
                <av-line
                  v-if="isLoaded"
                  style="margin:auto;"
                  ref="audioLine2"
                  :audio-controls="false"
                  ref-link="audioPlayer2"
                  caps-color="transparent"
                  :canv-width="600"
                  :canv-height="160"
                  :line-color="'#fff'"
                  :line-width="0.5"
                  canv-fill-color="transparent"
                  @playing="onAudioPlaying(2)"
                  @paused="onAudioPaused(2)"
                  @ended="onAudioEnded(2)"
                ></av-line>
              </div>

              <div class="audio-wave-wrapper">
                <audio
                  id="audio" ref="audioPlayer2"
                  :autoplay="false" :loop="false" :controls="false"
                  webkit-playsinline="true"
                  playsinline="true"
                  :muted="false"
                  preload="auto">
                  <source :src="audios[2].mp4" type="audio/mp4">
                  <source :src="audios[2].mp3" type="audio/mp3">
                  <source :src="audios[2].ogg" type="audio/ogg">
                  Your browser does not support the audio element.
                </audio>
              </div>

              <div class="audio-hover">
                <a id="play-pause-button"
                  class="fa"
                  :class="audios[2].isPaused ? 'fa-play' :
                          audios[2].isPlaying ? 'fa-pause' : 'fa-play'"
                  style="margin:auto;">
                </a>
              </div>
            </div>
          </div>

          <div class="sec-2 inline-middle flex flex-end photo-lower-right is-cursor is-audio-hover" @click="onAudioPlay(3)">
            <div class="photo-wrapper" ref="sec4"
              data-aos="slide-up"
              data-aos-once="true"
              data-aos-mirror="true"
              data-aos-delay="0"
              data-aos-offset="999999"
              data-aos-duration="1200"
              data-aos-easing="easing-custom"
              :class="(getRefInfo('sec3').isMidPoint ? 'aos-animate' : '')">
              <img src="../../assets/gallery04.jpg"
                class="img-responsive"
                :style="{
                  transform: safeTransform('sec4', posY, 0, 16, (posY / height) + 0.2, 6),
                  transformOrigin: 'center',
                  transition: 'transform .8s ease-in-out',
                }" />

              <div class="audio-wave-wrapper"
                :style="{
                  opacity: (audios[3].isPlaying ? 0.4 : 0.0)
                }">
                <av-line
                  v-if="isLoaded"
                  style="margin:auto;"
                  ref="audioLine3"
                  :audio-controls="false"
                  ref-link="audioPlayer3"
                  caps-color="transparent"
                  :canv-width="600"
                  :canv-height="160"
                  :line-color="'#fff'"
                  :line-width="0.5"
                  canv-fill-color="transparent"
                  @playing="onAudioPlaying(3)"
                  @paused="onAudioPaused(3)"
                  @ended="onAudioEnded(3)"
                ></av-line>
              </div>

              <div class="audio-wave-wrapper">
                <audio
                  id="audio" ref="audioPlayer3"
                  :autoplay="false" :loop="false" :controls="false"
                  webkit-playsinline="true"
                  playsinline="true"
                  :muted="false"
                  preload="auto">
                  <source :src="audios[3].mp4" type="audio/mp4">
                  <source :src="audios[3].mp3" type="audio/mp3">
                  <source :src="audios[3].ogg" type="audio/ogg">
                  Your browser does not support the audio element.
                </audio>
              </div>

              <div class="audio-hover">
                <a id="play-pause-button"
                  class="fa"
                  :class="audios[3].isPaused ? 'fa-play' :
                          audios[3].isPlaying ? 'fa-pause' : 'fa-play'"
                  style="margin:auto;">
                </a>
              </div>
            </div>
          </div>

        </div> -->
      </div>
    </div>

    <!-- <div class="hanging line line-cross"></div> -->
  </div>
</template>

<script>
import _ from 'lodash'
// import Constants from '@/constants'
// import EventBus from '@/controllers/EventBus'
// import { getToken } from '@/utils/auth'

// import DivContainer from '@/components/DivContainer.vue'
// import VueAplayer from 'vue-aplayer'
// import AudioWaveLoader from '@/components/AudioWaveLoader'
import MyAvLine from '@/components/AudioVisual/AvLine'

import Img01 from '@/assets/gallery01.jpg'
import Img02 from '@/assets/gallery02.jpg'
import Img03 from '@/assets/gallery03.jpg'
import Img04 from '@/assets/gallery04.jpg'

import AudioMarcia from '@/assets/audio_marcia.mp4'
import AudioMarciaMp3 from '@/assets/audio_marcia.mp3'
import AudioMarciaOgg from '@/assets/audio_marcia.ogg'
import AudioNeicey from '@/assets/audio_neicey.mp4'
import AudioNeiceyMp3 from '@/assets/audio_neicey.mp3'
import AudioNeiceyOgg from '@/assets/audio_neicey.ogg'
import AudioMrsallen from '@/assets/audio_mrsallen.mp4'
import AudioMrsallenMp3 from '@/assets/audio_mrsallen.mp3'
import AudioMrsallenOgg from '@/assets/audio_mrsallen.ogg'
import AudioShakimah from '@/assets/audio_shakimah.mp4'
import AudioShakimahMp3 from '@/assets/audio_shakimah.mp3'
import AudioShakimahOgg from '@/assets/audio_shakimah.ogg'

export default {
  name: 'section-photo',
  props: ['last-position'],
  components: {
    // DivContainer,
    // VueAplayer,
    // AudioWaveLoader,
    MyAvLine,
  },
  data () {
    return {
      // loading: false,
      posY: 0,
      height: 0,
      offsetY: 0,
      timer: undefined,
      refs: [
        { name: 'sectionMain', isMidPoint: false, },
        { name: 'sec-row-0', isMidPoint: false, },
        { name: 'sec-row-1', isMidPoint: false, },
      ],
      audios: [
        {
          classes: 'sec-1 inline-middle photo-upper-left is-cursor is-audio-hover',
          refPoint: 'sec-0',
          img: Img01,
          speedY: 10,
          speedScale: 16,
          mp4: AudioMarcia,
          mp3: AudioMarciaMp3,
          ogg: AudioMarciaOgg,
          isPlaying: false,
          isPaused: false,
          // element: undefined,
          // mini: true,
          // autoplay: false,
          // mode: 'single',
          // title: 'Boss Hog Radio',
          // artist: 'Boss Hog Cook-Off',
          // src: AudioMarcia,
          // pic: undefined,
          // lrc: '[00:00.00]lrc here\n[00:01.00]aplayer',
        }, {
          classes: 'sec-2 inline-middle flex flex-end photo-upper-right is-cursor is-audio-hover',
          refPoint: 'sec-0',
          img: Img02,
          speedY: 16,
          speedScale: 6,
          mp4: AudioNeicey,
          mp3: AudioNeiceyMp3,
          ogg: AudioNeiceyOgg,
          isPlaying: false,
          isPaused: false,
        }, {
          classes: 'sec-1 inline-middle photo-lower-left is-cursor is-audio-hover',
          refPoint: 'sec-2',
          img: Img03,
          speedY: 10,
          speedScale: 16,
          mp4: AudioShakimah,
          mp3: AudioShakimahMp3,
          ogg: AudioShakimahOgg,
          isPlaying: false,
          isPaused: false,
        }, {
          classes: 'sec-2 inline-middle flex flex-end photo-lower-right is-cursor is-audio-hover',
          refPoint: 'sec-2',
          img: Img04,
          speedY: 16,
          speedScale: 6,
          mp4: AudioMrsallen,
          mp3: AudioMrsallenMp3,
          ogg: AudioMrsallenOgg,
          isPlaying: false,
          isPaused: false,
        }
      ],
      isLoaded: false,
      audioIndex: undefined,
      audioSrcMp4: AudioMarcia,
      audioSrcMp3: AudioMarciaMp3,
      audioSrcOgg: AudioMarciaOgg,
    }
  },
  computed: {
  },
  watch: {
    lastPosition: function (value, oldValue) {
      this.refresh()
      this.timerRefresh()
    },
  },
  created () {
  },
  async mounted () {
    this.refresh()

    this.timer = setInterval(() => this.timerRefresh(), 100)
    this.timerRefresh()

    // this.$refs.audioPlayer.onended = this.onAudioEnded
    this.$set(this, 'isLoaded', true)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    refresh () {
      let sectionMain = this.$refs.sectionMain
      if (!sectionMain || !sectionMain.getBoundingClientRect) {
        console.error('invalid section main or bounding', sectionMain)
      }
      let posY = sectionMain.getBoundingClientRect().y
      let height = sectionMain.getBoundingClientRect().height
      this.$set(this, 'posY', -posY)
      this.$set(this, 'height', height)
      let dheight = document.documentElement.clientHeight
      let diff = (height - dheight) / 2
      diff = diff > 0 ? -diff : diff
      this.$set(this, 'offsetY', diff)
    },

    safeTransform (refName, y, offsetY, speedY = 6, scale, speedScale = 4) {
      // translateY
      let winHeight = window.innerHeight
      let secHeight = this.height
      let translateY = y
      // translateY = translateY < (winHeight / 2) ? (winHeight / 2) : translateY
      // let maxY = (secHeight + (winHeight / 2))
      // translateY = translateY > maxY ? maxY : translateY

      // ref top
      let topY = 0
      let ref = this.$refs[refName]
      if (ref) {
        if (Array.isArray(ref) && ref.length > 0) {
          ref = ref[0]
        }
        let rect = ref.getBoundingClientRect()
        topY = (rect && rect.top) || 0
        // console.log('-- ref rect: ' + refName, rect)
        if (topY) y = topY
      }
      translateY /= speedY

      // scale
      let scaleY = 1.00 + scale / speedScale
      scaleY = scaleY < 1.00 ? 1.00 : scaleY
      scaleY = scaleY > 1.10 ? 1.10 : scaleY
      return `translateY(${translateY}px) scale(${scaleY})`
    },

    // timer & ref mid point functions

    timerRefresh () {
      for (let r in this.refs) {
        let refInfo = this.refs[r]
        let refName = refInfo.name
        this.setIsMidPoint(refName)
      }
    },

    isMidPoint (refName) {
      let ref = this.$refs[refName]
      // console.log('-- check if midpoint: ' + refName, ref)
      if (!ref) return false
      // if (!ref.getBoundingClientRect) {
      //   console.log('error bounding rect=' + refName + ', type=' + typeof ref + ', len=' + ref.length, ref)
      // }
      let withRef = false
      if (Array.isArray(ref) && ref.length > 0) {
        withRef = true
        ref = ref[0]
      }
      let rect = ref.getBoundingClientRect()
      let top = rect && rect.top
      let targetHeight = window.innerHeight * 1.00
      let isMidPoint = (top < targetHeight)
      // console.log('-- check if midpoint: ' + refName, { withRef, isMidPoint, top, targetHeight })
      return isMidPoint
    },

    setIsMidPoint (refName) {
      let refInfo = this.getRefInfo(refName)
      let isMidPoint = this.isMidPoint(refName)
      if (!isMidPoint) return
      this.$set(refInfo, 'isMidPoint', isMidPoint)
    },

    getRefInfo (refName) {
      let found = _.find(this.refs, (r) => r.name === refName)
      return found || {}
    },

    // audio events

    async onAudioPlay (index) {
      for (let a in this.audios) {
        let aindex = parseInt(a, 10)
        if (index === aindex) continue
        let audioInf = this.audios[a]
        this.$set(audioInf, 'isPlaying', false)
      }
      let audioInfo = this.audios[index]
      this.$set(audioInfo, 'isPlaying', true)
      await this.$nextTick()

      let audioPlayer = this.$refs[`audioPlayer${index}`]
      if (!audioPlayer) {
        console.error(`invalid audio player: ${index}`, audioPlayer)
        return
      }
      if (Array.isArray(audioPlayer) && audioPlayer.length > 0) {
        audioPlayer = audioPlayer[0]
      }

      // let audioInfo = this.audios[index]
      // let isPlaying = audioInfo && audioInfo.isPlaying
      // if (isPlaying) {
      //   await audioPlayer.pause()
      //   return
      // }

      // const audioExts = ['mp3', 'ogg', 'mp4']
      // let audioInfo = this.audios[index]
      // for (let a in audioExts) {
      //   let ext = audioExts[a]
      //   let audio = audioInfo[ext]
      //   let audioSrcName = `audioSrc${_.startCase(ext).replace(' ', '')}`
      //   console.log('-- set:', audioSrcName)
      //   this.$set(this, audioSrcName, audio)
      // }
      this.$set(this, 'audioIndex', index)
      await this.$nextTick()

      // // set load
      // if (!audioPlayer.paused || audioPlayer.currentTime) {
      //   console.log('-- audio not paused, reload')
      //   await audioPlayer.load()
      //   await this.$nextTick()
      // }

      // set index
      let audioLine = this.$refs[`audioLine${index}`]
      if (Array.isArray(audioLine) && audioLine.length > 0) {
        audioLine = audioLine[0]
      }
      console.log('-- audio line', audioLine)
      if (!audioLine.audioCtx) {
        await audioLine.setAnalyser()
      }
      // let audioPlayer = this.$refs[`audioPlayer${index}`]
      console.log('-- audio player', audioPlayer)
      if (!audioPlayer) {
        console.error(`invalid audio player: ${index}`, audioPlayer)
        return
      }
      await audioPlayer.play()
    },

    // onAudioEnded () {
    //   console.log('-- audio ended')
    //   this.$set(this, 'audioIndex', undefined)
    //   let audioExts = ['mp3', 'ogg', 'mp4']
    //   for (let a in audioExts) {
    //     let ext = audioExts[a]
    //     let audioSrcName = `audioSrc${_.startCase(ext).replace(' ', '')}`
    //     this.$set(this, audioSrcName, undefined)
    //   }
    // },

    onAudioPlaying (index) {
      console.log('-- audio playing', index)
      this.$set(this.audios[index], 'isPlaying', true)
      this.$set(this.audios[index], 'isPaused', false)
    },

    onAudioPaused (index) {
      console.log('-- audio paused', index)
      this.$set(this.audios[index], 'isPlaying', false)
      this.$set(this.audios[index], 'isPaused', true)
    },

    onAudioEnded (index) {
      console.log('-- audio ended', index)
      this.$set(this, 'audioIndex', undefined)
      this.$set(this.audios[index], 'isPlaying', false)
      this.$set(this.audios[index], 'isPaused', false)
    },
  }
}
</script>

<style lang="scss">
@import "../../styles/base.scss";
@import "../../styles/sections.scss";

.section-photo {
  .sec-wrapper {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: $mobile) {
      flex-direction: column;
    }
    padding: 0;
  }
  .sec-1 {
    overflow: hidden;
    width: 50%;
    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }
  .sec-2 {
    overflow: hidden;
    width: 50%;
    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }

  .photo-upper-left {
    text-align: left;
    vertical-align: top;
    padding-right: 30px;
    padding-bottom: 90px;
    @media screen and (max-width: $mobile) {
      text-align: center;
      padding: 10px 20px;
      img {
        margin-bottom:0;
      }
    }
  }

  .photo-upper-right {
    text-align: right;
    vertical-align: top;
    @media screen and (max-width: $mobile) {
      text-align: center;
      padding: 10px 20px;
    }
  }

  .photo-lower-left {
    text-align: left;
    vertical-align: bottom;
    padding-left: 3%;
    padding-right: 30px;
    @media screen and (max-width: $mobile) {
      // padding: 10px 20px;
      text-align: center;
      padding: 0;
    }
  }

  .photo-lower-right {
    text-align: right;
    vertical-align: bottom;
    padding-right: 3%;
    padding-top: 90px;
    @media screen and (max-width: $mobile) {
      text-align: center;
      padding: 10px 20px;
      img {
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .photo-wrapper {
    position: relative;
    overflow: hidden;
    background-color: #010206;
  }

  .center-wrapper {
    position: relative;
    overflow: hidden;
  }

  .audio-wave-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    div, canvas {
      width: 100%;
    }
  }

  .is-audio-hover {
    .audio-hover {
      position: absolute;
      display: none;
      align-items: center;
      top: 0;
      width: 100%;
      height: 100%;
      margin: auto;
    }
    &:hover .audio-hover {
      display: flex;
    }
  }

  .aplayer {
    width: 100%;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0;
    overflow: visible;
    position: relative;

    &.aplayer-narrow {
      width: 100%;
    }

    .aplayer-pic {
      width: 100%;
      padding-top: 75%;
      background-color: #f8ece9 !important;
      background-repeat: no-repeat;
      position: relative;

      @media screen and (min-width: $mobile) {
        padding-top: 85%;
      }

      @media screen and (min-width: $tablet) {
        padding-top: 65%;
      }

      @media screen and (min-width: $desktop) {
        padding-top: 65%;
      }

      @media screen and (min-width: $fullhd) {
        padding-top: 75%;
      }

      .aplayer-button {
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
        background: rgba(0, 0, 0, 0)
      }

      .aplayer-play, .aplayer-pause {
        width: 95%;
        height: 95%;
        border: 0;
        top: 0%;
        right: 50%;
        margin: 0 -45% -50% 0;
        padding: 0px;

        .aplayer-icon-play, .aplayer-icon-pause {
          top: 30%;
          left: 30%;
          width: 40%;
          height: 40%;
        }
      }
    }
  }
}

</style>
