import _ from 'lodash'

class ArrayHelper {
  static containsFromArray (array, arrayFrom) {
    if (!array || !arrayFrom) {
      return false
    }
    // console.log('-- contains array', {
    //   array,
    //   arrayFrom
    // })
    // array ['a', 'b', 'c'], array from ['c', 'd'] = true
    // array ['a', 'b', 'c'], array from ['d'] = false
    if (typeof arrayFrom === 'object') {
      if (typeof array === 'string') {
        return arrayFrom.includes(array)
      }
      if (typeof array !== 'object') {
        console.error('invalid array 1 type')
        throw new Error('invalid array 1 type')
      }
      for (let a in array) {
        let value = array[a]
        if (arrayFrom.includes(value)) {
          return true
        }
      }
    } else if (typeof arrayFrom === 'string') {
      if (typeof array === 'string') {
        return array === arrayFrom
      }
      if (typeof array !== 'object') {
        console.error('invalid array 2 type')
        throw new Error('invalid array 2 type')
      }
      return (array.includes(arrayFrom))
    }
    return false
  }

  static copy (list) {
    if (!list) return
    let copies = []
    for (let l in list) {
      let item = list[l]
      if (typeof item === 'string' ||
        typeof item === 'number' ||
        typeof item === 'boolean') {
        copies.push(item)
        continue
      }
      item = _.assign({}, item)
      copies.push(item)
    }
    return copies
  }

  static copyWithKeyinfos (list, keyinfos) {
    if (!list) return
    let copies = []
    let allkeys = _.map(keyinfos, (ki) => ki.key)
    for (let l in list) {
      let item = list[l]
      if (typeof item === 'string' ||
        typeof item === 'number' ||
        typeof item === 'boolean') {
        copies.push(item)
        continue
      }
      item = _.assign({}, item)

      // limit with keys
      let newItem = {}
      for (let key in item) {
        let val = item[key]
        if (!allkeys.includes(key)) continue
        newItem[key] = val
      }
      copies.push(newItem)
    }
    return copies
  }
}

export default ArrayHelper
