
// export const Countries = [
//   { key: 'Malaysia', display: 'Malaysia' }
// ]

// export const States = [
//   { key: 'Selangor', display: 'Selangor' },
//   { key: 'Kuala Lumpur', display: 'Kuala Lumpur' },
//   { key: 'Putrajaya', display: 'Putrajaya' },
//   { key: 'Johor', display: 'Johor' },
//   { key: 'Kedah', display: 'Kedah' },
//   { key: 'Kelantan', display: 'Kelantan' },
//   { key: 'Melaka', display: 'Melaka' },
//   { key: 'Negeri Sembilan', display: 'Negeri Sembilan' },
//   { key: 'Pahang', display: 'Pahang' },
//   { key: 'Penang', display: 'Penang' },
//   { key: 'Perak', display: 'Perak' },
//   { key: 'Perlis', display: 'Perlis' },
//   { key: 'Sabah', display: 'Sabah' },
//   { key: 'Sarawak', display: 'Sarawak' },
//   { key: 'Terengganu', display: 'Terengganu' }
// ]

// export const Areas = [
//   { key: 'IPOH', display: 'IPOH' },
//   { key: 'JB', display: 'JB' },
//   { key: 'KV', display: 'KV' },
//   { key: 'MLK', display: 'MLK' },
//   { key: 'PNG', display: 'PNG' },
//   { key: 'SBN', display: 'SBN' },
//   { key: '----', display: '----' }
// ]

const Countries = Object.freeze({
  STATES: [
    'AE',
    'AF',
    'AP',
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
  ]
})

export default Countries
