<template>
  <el-dialog
    class="dialog-bio"
    custom-class="dialog-base wrapper dialog-bio"
    v-if="visible"
    :title="undefined"
    :visible="visible"
    :width="'90%'"
    :before-close="onClose"
    :close-on-click-modal="closeOnClickModal != null ? closeOnClickModal : true">
    <!-- <div slot="title" class="title-content">
    </div> -->
    <div class="el-dialog__content"
      style="margin-left:auto;margin-right:auto;">
      <div id="section-film" class="sections section-film page-wrapper">
        <div class="section-wrapper full" style="padding-top:30px;padding-bottom:30px;">
          <h2 class="statement title roboto uppercase cwhite"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="300" data-aos-delay="100"
            style="margin-bottom:12px;">
            About Dee Allen-Barnes
          </h2>

          <h4 class="statement"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="200">
            A graduate of the UK’s renowned Goldsmiths University, Dee started his career in London’s Soho as a sound and edit assistant, cutting his teeth on TV drama, documentary and on-air broadcast projects for the likes of the BBC, Channel 4 the Discovery Channel and ITV.

          </h4>

          <h4 class="statement"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="300">
            Whilst at Goldsmiths he majored in film theory and sociology, the key disciplines which underpin his approach as a film maker. In 1998 he began working with Channel 4’s subsidiary channel Film 4 at its inception, first as a post-production producer before eventually joining the networks multi award winning creative services team as a promo’s writer and director.
          </h4>

          <h4 class="statement"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="400">
            He doesn’t consider himself a director in the conventional sense, writing is his passion, the medium of film being one of the disciplines he chooses to tell his stories. His life’s journey both professionally and personally has taken him along differing paths, all of which coalesce to inform and shape his vision of the world he has grown up in.
          </h4>

          <h4 class="statement"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="500">
            “I like to create stories that are rooted in our experiences as members of a community. I’ve met so many interesting people in many different walks of life in some obscure places. These are real characters some of whom have influenced my own life and others who have left a profound impression on me, for good or bad. That’s where my imagination goes when I sit down to write, these shadowy figures I’ve bumped into along the way. The important thing for me is that there are elements of my stories that people can identify with and feel a connection to.”
          </h4>
        </div>
      </div>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <div class="footer-content has-text-right">
        <el-button
          type="info"
          @click="onClose">
          OK
        </el-button>
      </div>
    </span> -->
  </el-dialog>
</template>

<script>
// import _ from 'lodash'
// import Constants from '@/constants'
// import EventBus from '@/controllers/EventBus'
// import { getToken } from '@/utils/auth'

export default {
  name: 'dialog-bio',
  props: ['close-on-click-modal'],
  components: {
  },
  data () {
    return {
      visible: false,
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  async mounted () {
  },
  methods: {
    show () {
      this.$set(this, 'visible', true)
    },
    // dialog
    onClose () {
      this.$set(this, 'visible', false)
      this.$emit('on-close')
    },
    // onSubmit () {
    //   this.$emit('on-submit')
    // },
  }
}
</script>

<style lang="scss">
@import "../../styles/base.scss";
@import "../../styles/dialog.scss";
@import "../../styles/sections.scss";

.dialog-bio {
  padding-bottom: 50px;

  .statement {
    margin-top: 12px;
    margin-bottom: 12px;
    word-break: break-word;

    &.title {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
}

</style>
