const products = {
  state: {
    products: '',
    model: ''
  },
  mutations: {
    SET_PRODUCTS: (state, products) => {
      state.products = products
    },
    SET_MODEL: (state, model) => {
      state.model = model
    }
  },
  actions: {
    setProducts ({ commit }, products) {
      commit('SET_PRODUCTS', products)
    },
    setModel ({ commit }, model) {
      commit('SET_MODEL', model)
    }
  }
}

export default products
