<template>
  <div id="section-loading" ref="sectionMain" class="sections section-loading page-wrapper">
    <div class="section-wrapper flex flex-center"
      :style="{
        opacity: alreadyOpacity
      }">
      <div class="hanging line line-main"
        :style="{
          top: lineTop,
          height: `${lineHeight}%`,
          transform: `scaleY(${lineRatio})`,
          transformOrigin: 'top center',
          transition: 'transform 0.5s ease',
        }">
      </div>
      <div class="overflow-wrapper enter-wrapper">
        <div
          class="enter-content"
          data-aos="slide-right"
          data-aos-once="true"
          data-aos-offset="99999"
          data-aos-delay="500"
          :class="(isSlideOut ? 'aos-animate' : '')">
          <span class="inline-middle uppercase gap-top-xl text-tag is-cursor cwhite" @click="onFadeOut">
            Enter Site
          </span>
          <a class="inline-middle control-arrow gap-top-xl is-cursor" @click="onFadeOut">
            →
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Constants from '@/constants'
// import EventBus from '@/controllers/EventBus'
// import { getToken } from '@/utils/auth'

// import DivContainer from '@/components/DivContainer.vue'

import Image1 from '@/assets/main_bg1.jpg'
import Image2 from '@/assets/main_bg2.jpg'
import Image3 from '@/assets/main_bg3.jpg'
import Image1m from '@/assets/main_bg1m.jpg'
import Image2m from '@/assets/main_bg2m.jpg'
import Image3m from '@/assets/main_bg3m.jpg'

export default {
  name: 'section-loading',
  props: ['last-position'],
  components: {
    // DivContainer,
  },
  data () {
    return {
      // loading: false,
      timer: undefined,
      lineTop: 0,
      lineHeight: 100,
      lineRatio: 0.0,
      alreadyLineOut: false,
      alreadyOpacity: 1.0,
      alreadyDone: false,
      isSlideOut: false,
      images: [
        Image1,
        Image2,
        Image3,
        Image1m,
        Image2m,
        Image3m,
      ]
    }
  },
  computed: {
  },
  watch: {
    lastPosition: function (value, oldValue) {
      // this.timerRefresh()
    }
  },
  created () {
  },
  async mounted () {
    this.timer = setInterval(() => this.timerRefresh(), 100)
    this.timerRefresh()

    for (let i in this.images) {
      let image = this.images[i]
      document.createElement('img').setAttribute('src', image)
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    onTrack (event, label, category = Constants.TRACK_EVENT_CATEGORY.GENERAL, value = undefined) {
      this.$gtag.event(event, {
        'event_category': category,
        'event_label': label,
        ...(
          value != null ?
          { 'value': value } : {}
        ),
      })
    },

    timerRefresh () {
      if (this.alreadyDone) {
        if (this.timer) {
          clearInterval(this.timer)
        }
        return
      }
      if (this.alreadyLineOut) {
        // let opacity = this.alreadyOpacity - 0.05
        // if (opacity <= 0) opacity = 0
        // this.$set(this, 'alreadyOpacity', opacity)
        // if (opacity <= 0) {
        //   this.$emit('on-loading-ended')
        // }
        // this.$emit('on-loading-ended')
        this.$set(this, 'isSlideOut', true)
        if (this.timer) {
          clearInterval(this.timer)
        }
        return
      }
      let ratio = (this.lineRatio + 0.05)
      if (ratio > 1.0) ratio = 1.0
      this.$set(this, 'lineRatio', ratio)
      if (ratio >= 1.0 && !this.alreadyLineOut) {
        this.$set(this, 'alreadyLineOut', true)
      }
    },

    timerRefreshFadeOut () {
      let opacity = this.alreadyOpacity - 0.05
      if (opacity <= 0) opacity = 0
      this.$set(this, 'alreadyOpacity', opacity)
      if (opacity <= 0) {
        if (this.timer) {
          clearInterval(this.timer)
        }
        this.$emit('on-loading-ended')
      }
    },

    onFadeOut () {
      // this.$emit('on-loading-ended')
      this.$set(this, 'isSlideOut', false)
      this.timer = setInterval(() => this.timerRefreshFadeOut(), 100)
      this.onTrack(Constants.TRACK_EVENT.CLICK, `loading-enter`)
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/base.scss";
@import "../../styles/sections.scss";

.section-loading {
  height: 100%;
  background-color: black !important;
  opacity: 1.0;

  .section-wrapper {
    height: 100%;
    padding: 0;
    margin: 0;
  }

  .line {
    border-left: 0.1pt solid #fff !important;
    opacity: 0.5;
  }

  .enter-wrapper {
    position: absolute;
    // top: calc((100% - 150px) / 2 + 150px + 20px);
    left: 20%;
    // height: 40%;

    .enter-content {
      padding-left: 12px;
    }
  }

  .overflow-wrapper {
    overflow: hidden;
  }
}

</style>
