<template>
  <div id="section-director" ref="sectionMain" class="sections section-director page-wrapper pos-relative">

    <div class="hanging section-wrapper full secs label-wrapper">
      <div class="section-inner-wrapper flex flex-dir-row flex-dir-col-mobile">
        <h4 class="hanging label-director bordered uppercase cwhite font-roboto-black text-tag is-hidden-mobile"
          style="z-index:100;">
          WRITER & DIRECTOR
        </h4>
      </div>
    </div>

    <div class="section-wrapper full secs">
      <div class="section-inner-wrapper flex flex-dir-row flex-dir-col-mobile" style="width:100%;">
        <div class="sec-50" style="text-align:right;position:relative;">
          <img src="../../assets/director.jpg" class="img-responsive"
            data-aos="slide-up" data-aos-once="true" data-aos-offset="999999" data-aos-delay="300"
            :class="(isScrollMidPoint ? 'aos-animate' : '')" />
          <h4 class="hanging label-director bordered uppercase cwhite font-roboto-black text-tag is-hidden-tablet">
            WRITER & DIRECTOR
          </h4>
        </div>
        <div class="sec-50 pos-relative flex flex-center">
          <h4 class="hanging non-hanging-mobile about bordered"
            data-aos="slide-up" data-aos-once="true" data-aos-offset="999999" data-aos-delay="300"
            :class="(isScrollMidPoint ? 'aos-animate' : '')">
            <div class="font-roboto-mono gap-bottom" style="font-size:1.2rem;">
              DEE ALLEN-BARNES
            </div>
            “I like to create stories that are rooted in our experience as members of a community, It doesn’t matter what the make-up of that community looks like.  Our differences can be a source of inspiration. We celebrate our commonalities, equally our differences should be recognized, respected and spoken about. They are the main ingredient of our human experience.”<br />
            <span class="inline-middle gap-top-xl uppercase text-tag is-cursor" @click="onBio">
              Read Bio
            </span>
            <div class="inline-middle gap-top-xl control-arrow is-cursor" @click="onBio">
              →
            </div>
            <br class="is-hidden-tablet" />
            <span class="inline-middle gap-top-xl gap-left-xl uppercase text-tag is-cursor" @click="onInterview">
              Director's Notes
            </span>
            <div class="inline-middle gap-top-xl control-arrow is-cursor" @click="onInterview">
              →
            </div>
          </h4>

          <!-- <h4 class="hanging about bordered">
            I Mother focuses on the impact the unexpected loss of a loved one may have on a family. The act itself may be the trigger for overwhelming grief, but long term emotional suffering can prove to be just as painful for those left behind.<br />
            <span class="inline-middle uppercase">
              Watch Trailer
            </span>
            <div class="inline-middle control-arrow">
              >>
            </div>
          </h4> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import _ from 'lodash'
// import Constants from '@/constants'
// import EventBus from '@/controllers/EventBus'
// import { getToken } from '@/utils/auth'

// import DivContainer from '@/components/DivContainer.vue'

export default {
  name: 'section-director',
  props: ['last-position'],
  components: {
    // DivContainer,
  },
  data () {
    return {
      // loading: false,
      posY: 0,
      offsetY: 0,
      isScrollMidPoint: false,
    }
  },
  computed: {
  },
  watch: {
    lastPosition: function (value, oldValue) {
      let posY = this.$refs.sectionMain.getBoundingClientRect().y
      let height = this.$refs.sectionMain.getBoundingClientRect().height
      this.$set(this, 'posY', -posY)
      let dheight = document.documentElement.clientHeight
      let diff = (height - dheight) / 2
      diff = diff > 0 ? -diff : diff
      this.$set(this, 'offsetY', diff)
      this.timerRefresh()
    }
  },
  created () {
  },
  async mounted () {
    this.timer = setInterval(() => this.timerRefresh(), 100)
    this.timerRefresh()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    timerRefresh () {
      let refName = 'sectionMain'
      let ref = this.$refs[refName]
      if (!ref) return false
      let rect = ref.getBoundingClientRect()
      let top = rect && rect.top
      let targetHeight = window.innerHeight * 0.78
      let isMidPoint = (top < targetHeight)
      // console.log('-- isMidPoint', ref, isMidPoint, top, targetHeight)
      this.$set(this, 'isScrollMidPoint', isMidPoint)
    },

    onBio () {
      this.$emit('on-bio')
    },

    onInterview () {
      this.$emit('on-interview')
    },
  }
}
</script>

<style lang="scss">
@import "../../styles/base.scss";
@import "../../styles/sections.scss";

.section-director {
  .secs {
    display: flex;
    .section-inner-wrapper {
      overflow: hidden;
    }
  }
  .sec-50 {
    width: 50%;
    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }
  .pos-relative {
    position: relative;
  }

  .statement {
    position: relative;
    background-color: blue;
    width: 100%;
  }

  .hanging {
    position: absolute;
    @media screen and (max-width: $mobile) {
      position: relative;
    }

    // $about-top: 25%;

    &.about {
      position: absolute;
      @media screen and (max-width: $mobile) {
        position: relative;
        left: 0;
      }
      // top: $about-top;
      left: -20%;
      width: 100%;
      border: 0;
      background-color: black;
      margin-top: 50px;
      margin-bottom: 50px;
      padding: 50px 30px;

      @media screen and (max-width: $mobile) {
        margin-top: 0;
        margin-bottom: 0;
        padding: 50px 20px;
      }

      @media screen and (min-width: $tablet) and (max-width: $desktop - 1) {
        width: 100%;
      }
    }
  }

  .text-tag {
    @media screen and (max-width: $mobile) {
      margin-left: 0;
    }
  }

  .label-wrapper {
    width: 100%;
    height: 100%;
    // background-color: blue;
    // opacity: 0.5;
    top: 0;
    right: 0;
  }

  .label-director {
    right: -117px;
    background-color: white;
    color: black !important;
    font-family: 'Roboto Black' !important;
    font-size: 1.2rem !important;
    letter-spacing: 5px;
    width: auto;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    padding: 12px 28px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg) translate3d(0, 0, 0);

    @media screen and (max-width: $mobile) {
      // top: 25%;
      right: -105px;
      top: calc(50% - 20px);
      font-size: 1.0rem !important;
    }

    @media screen and (min-width: $tablet) and (max-width: $desktop - 1) {
      right: -117px;
      font-size: 1.2rem !important;
    }
  }
}

</style>
