const onboarding = {
  state: {
    // page: undefined,
    // fdaCategory: undefined,
    // isFdaComplianceDone: false,
    // isFdaRegulatoryDone: false,
    // fdaComplianceForm: {
    //   // default blank
    // },
    // fdaRegulatoryForm: {
    //   // default blank
    // },
    // itemSetupForm: {
    //   // default blank
    // },
    // itemSetupPlabelForm: {
    //   // default blank
    // }
    applicationForm: {
      // default blank
    }
  },
  mutations: {
    // SET_PAGE: (state, page) => {
    //   state.page = page
    // },
    // SET_FDA_CATEGORY: (state, fdaCategory) => {
    //   state.fdaCategory = fdaCategory
    // },
    // SET_FDA_COMPLIANCE_DONE: (state) => {
    //   state.isFdaComplianceDone = true
    // },
    // SET_FDA_REGULATORY_DONE: (state) => {
    //   state.isFdaRegulatoryDone = true
    // },
    // SET_FDA_COMPLIANCE_FORM: (state, fdaComplianceForm) => {
    //   state.fdaComplianceForm = fdaComplianceForm
    // },
    // SET_FDA_REGULATORY_FORM: (state, fdaRegulatoryForm) => {
    //   state.fdaRegulatoryForm = fdaRegulatoryForm
    // },
    // CLEAR_FDA_COMPLIANCE_FORM: (state) => {
    //   state.fdaComplianceForm = {}
    // },
    // CLEAR_FDA_REGULATORY_FORM: (state) => {
    //   state.fdaRegulatoryForm = {}
    // },
    // CLEAR_FDA_FORMS: (state) => {
    //   state.fdaComplianceForm = {}
    //   state.fdaRegulatoryForm = {}
    // },
    // SET_ITEM_SETUP_FORM: (state, itemSetupForm) => {
    //   state.itemSetupForm = itemSetupForm
    // },
    // SET_ITEM_SETUP_PLABEL_FORM: (state, itemSetupPlabelForm) => {
    //   state.itemSetupPlabelForm = itemSetupPlabelForm
    // }
    SET_APPLICATION_FORM: (state, applicationForm) => {
      state.applicationForm = applicationForm
    }
  },
  actions: {
    // setPage ({ commit }, page) {
    //   commit('SET_PAGE', page)
    // },
    // setFdaCategory ({ commit }, fdaCategory) {
    //   commit('SET_FDA_CATEGORY', fdaCategory)
    // },
    // setFdaComplianceDone ({ commit }) {
    //   commit('SET_FDA_COMPLIANCE_DONE')
    // },
    // setFdaRegulatoryDone ({ commit }) {
    //   commit('SET_FDA_REGULATORY_DONE')
    // },
    // setFdaComplianceForm ({ commit }, fdaComplianceForm) {
    //   commit('SET_FDA_COMPLIANCE_FORM', fdaComplianceForm)
    // },
    // setFdaRegulatoryForm ({ commit }, fdaRegulatoryForm) {
    //   commit('SET_FDA_REGULATORY_FORM', fdaRegulatoryForm)
    // },
    // clearFdaComplianceForm ({ commit }) {
    //   commit('CLEAR_FDA_COMPLIANCE_FORM')
    // },
    // clearFdaRegulatoryForm ({ commit }) {
    //   commit('CLEAR_FDA_REGULATORY_FORM')
    // },
    // clearFdaForms ({ commit }) {
    //   commit('CLEAR_FDA_FORMS')
    // },
    // setItemSetupForm ({ commit }, itemSetupForm) {
    //   commit('SET_ITEM_SETUP_FORM', itemSetupForm)
    // },
    // setItemSetupPlabelForm ({ commit }, itemSetupPlabelForm) {
    //   commit('SET_ITEM_SETUP_PLABEL_FORM', itemSetupPlabelForm)
    // }
    setApplicationForm ({ commit }, applicationForm) {
      commit('SET_APPLICATION_FORM', applicationForm)
    }
  }
}

export default onboarding
