// import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'

import store from './controllers/Store'
import router from '@/router'
Vue.use(router)

import AOS from 'aos'
Vue.use(AOS)
import 'aos/dist/aos.css'

import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll)

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

import vueVimeoPlayer from 'vue-vimeo-player'
Vue.use(vueVimeoPlayer)

import AudioVisual from 'vue-audio-visual'
Vue.use(AudioVisual)

// import { vfmPlugin } from 'vue-final-modal'
// Vue.use(vfmPlugin)

// Element-UI
// import ElementUI from 'element-ui'
import { Dialog, Button, Loading } from 'element-ui'
import en from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(Dialog)
Vue.use(Button)
Vue.use(Loading.directive)
Vue.prototype.$loading = Loading.service

import VueGtag from 'vue-gtag'
Vue.use(VueGtag, {
  config: { id: 'G-X3470Q8SSK' }
})

// import Drawer from './components/Drawer.vue';
// Vue.component('Drawer', Drawer);

// const app = createApp(App)
// app.use(store)
// app.use(router)
// app.mount('#app')

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App),
  created () {
    AOS.init({
      duration: 1600
    })
  },
}).$mount('#app')
