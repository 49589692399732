import _ from 'lodash'
import { getToken, setToken, removeToken, getUserId, setUserId, removeUserId, getUsername, setUsername, removeUsername, getRole, setRole, removeRole, getModules, setModules, removeModules, getModuleIds, setModuleIds, removeModuleIds, getSalesPerson, setSalesPerson, removeSalesPerson, getLoginFrom, setLoginFrom, removeLoginFrom, getLandingPath, setLandingPath, /* getIsPartnerApplicationSubmitted, setIsPartnerApplicationSubmitted, */getPartnerApplicationServices, setPartnerApplicationServices } from '@/utils/auth'
// import Login from '@/apis/login'
const Login = {}
// import LoginSupplier from '@/apis/loginsupplier'
// import PresalesPartner from '@/apis/presalespartner'
// import Supplier from '@/apis/supplier'
import Constants from '@/constants'
// import ObConstants from '@/pages/onboarding/obconstants'
// import PartnerConstants from '@/pages/partner/partnerconstants'

const user = {
  state: {
    id: getUserId(),
    email: '',
    level: '',
    token: getToken(),
    username: getUsername(),
    role: getRole(),
    modules: getModules(),
    moduleIds: getModuleIds(),
    salesperson: getSalesPerson(),
    loginFrom: getLoginFrom(),
    landingPath: getLandingPath(),
    // partner related
    partnerApplicationServices: getPartnerApplicationServices()
  },
  mutations: {
    SET_ID: (state, id) => {
      state.id = id
      setUserId(id)
    },
    SET_EMAIL: (state, email) => {
      state.email = email
    },
    SET_USERNAME: (state, username) => {
      state.username = username
      setUsername(username)
    },
    SET_LEVEL: (state, level) => {
      state.level = level
    },
    SET_ROLE: (state, role) => {
      state.role = role
      setRole(role)
    },
    SET_TOKEN: (state, token) => {
      state.token = token
      setToken(token)
    },
    SET_MODULES: (state, modules) => {
      state.modules = modules
      setModules(modules)
    },
    SET_MODULE_IDS: (state, moduleIds) => {
      state.moduleIds = moduleIds
      setModuleIds(moduleIds)
    },
    SET_SALES_PERSON: (state, salesperson) => {
      state.salesperson = salesperson
      setSalesPerson(salesperson)
    },
    SET_LOGIN_FROM: (state, loginFrom) => {
      state.loginFrom = loginFrom
      setLoginFrom(loginFrom)
    },
    SET_LANDING_PATH: (state, landingPath) => {
      state.landingPath = landingPath
      setLandingPath(landingPath)
    },
    // partners
    SET_PARTNER_APPLICATION_SERVICES: (state, partnerApplicationServices) => {
      state.partnerApplicationServices = partnerApplicationServices
      setPartnerApplicationServices(partnerApplicationServices)
    }
  },
  actions: {
    async Login ({ commit }, userInfo) {
      const email = userInfo.email.trim()
      let response = await Login.login(email, userInfo.password)
      // console.log('-- login response', response)
      if (!response) throw new Error('invalid login response')
      if (response.status !== 'success') {
        return response
      }
      if (response.data) {
        await commit('SET_TOKEN', response.data)
      }

      // let id
      // if (response.hasOwnProperty('id')) {
      //   id = response.id
      // }
      // await commit('SET_ID', id)
      //
      // let username
      // if (response.hasOwnProperty('name')) {
      //   username = response.name
      // }
      // await commit('SET_USERNAME', username)
      //
      // // role
      // let role = Constants.RoleType.UNKNOWN
      // if (response.hasOwnProperty('role')) {
      //   role = parseInt(response.role, 10)
      // }
      // await commit('SET_ROLE', role) // this is transient state, where cookie is storage state

      // modules
      let modules = [Constants.ModuleType.ADMIN]
      // if (response.hasOwnProperty('modules')) {
      //   modules = response.modules
      // }
      await commit('SET_MODULES', modules)

      // // module ids
      // let moduleIds = []
      // if (response.hasOwnProperty('moduleIds')) {
      //   moduleIds = response.moduleIds
      // }
      // await commit('SET_MODULE_IDS', moduleIds)
      //
      // // sales person
      // let salesperson
      // if (response.hasOwnProperty('salesperson')) {
      //   salesperson = response.salesperson
      // }
      // await commit('SET_SALES_PERSON', salesperson)

      await commit('SET_LOGIN_FROM', `${Constants.BASE_URL}login`)

      // // landing path
      // let landingPath = '/'
      // if (response.hasOwnProperty('redirectRoute') &&
      //   response.redirectRoute !== null &&
      //   response.redirectRoute.length > 0) {
      //   landingPath = response.redirectRoute
      // }
      // await commit('SET_LANDING_PATH', landingPath)

      return response
    },

    GetUserInfo ({ commit, state }) {
      return new Promise((resolve, reject) => {
        Login.getUserInfo(state.token
        ).then(response => {
          if (!response) {
            reject(Error('error'))
          }
          console.log(response.data)
          commit('SET_ID', response.data.id)
          commit('SET_EMAIL', response.data.email)
          commit('SET_USERNAME', response.data.username)
          commit('SET_LEVEL', response.data.type)
          commit('SET_ROLE', response.data.role)
          commit('SET_MODULES', response.data.modules)
          commit('SET_MODULE_IDS', response.data.moduleIds)
          commit('SET_SALES_PERSON', response.data.salesperson)
          // commit('SET_LOGIN_FROM', response.data.loginFrom)
          // commit('SET_LANDING_PATH', response.data.landingPath)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    LogOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        Login.logout(state.token
        ).then(res => {
          commit('SET_ID', '')
          commit('SET_TOKEN', '')
          commit('SET_EMAIL', '')
          commit('SET_USERNAME', '')
          commit('SET_LEVEL', '')
          commit('SET_ROLE', Constants.RoleType.UNKNOWN)
          commit('SET_MODULES', [])
          commit('SET_MODULE_IDS', [])
          commit('SET_SALES_PERSON', {})
          // commit('SET_LOGIN_FROM', '/')
          // commit('SET_LANDING_PATH', undefined)
          removeToken()
          removeUserId()
          removeUsername()
          removeRole()
          removeModules()
          removeModuleIds()
          removeSalesPerson()
          // removeLoginFrom()
          // removeLandingPath()
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // fedout
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_ID', '')
        commit('SET_TOKEN', '')
        commit('SET_EMAIL', '')
        commit('SET_USERNAME', '')
        commit('SET_LEVEL', '')
        commit('SET_ROLE', Constants.RoleType.UNKNOWN)
        commit('SET_MODULES', [])
        commit('SET_MODULE_IDS', [])
        commit('SET_SALES_PERSON', {})
        // commit('SET_LOGIN_FROM', undefined)
        // commit('SET_LANDING_PATH', undefined)
        removeToken()
        removeUserId()
        removeUsername()
        removeRole()
        removeModules()
        removeModuleIds()
        // removeLoginFrom()
        // removeLandingPath()
        resolve()
      })
    },
    // // set application submitted
    // async SetIsPartnerApplicationSubmitted ({ commit }, partnerApplicationServices) {
    //   await commit('SET_PARTNER_APPLICATION_SERVICES', partnerApplicationServices)
    // },
    async AddPartnerApplicationService ({ commit, state }, serviceType) {
      let services = state.partnerApplicationServices || []
      if (services && !services.includes(serviceType)) {
        services.push(serviceType)
        await commit('SET_PARTNER_APPLICATION_SERVICES', services)
      }
    },
    async RemovePartnerApplicationService ({ commit, state }, serviceType) {
      let services = state.partnerApplicationServices || []
      if (services) {
        services = _.filter(services, (s) => (s !== serviceType))
        await commit('SET_PARTNER_APPLICATION_SERVICES', services)
      }
    }
  }
}

export default user
