var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"sectionMain",staticClass:"sections section-loading page-wrapper",attrs:{"id":"section-loading"}},[_c('div',{staticClass:"section-wrapper flex flex-center",style:({
      opacity: _vm.alreadyOpacity
    })},[_c('div',{staticClass:"hanging line line-main",style:({
        top: _vm.lineTop,
        height: `${_vm.lineHeight}%`,
        transform: `scaleY(${_vm.lineRatio})`,
        transformOrigin: 'top center',
        transition: 'transform 0.5s ease',
      })}),_c('div',{staticClass:"overflow-wrapper enter-wrapper"},[_c('div',{staticClass:"enter-content",class:(_vm.isSlideOut ? 'aos-animate' : ''),attrs:{"data-aos":"slide-right","data-aos-once":"true","data-aos-offset":"99999","data-aos-delay":"500"}},[_c('span',{staticClass:"inline-middle uppercase gap-top-xl text-tag is-cursor cwhite",on:{"click":_vm.onFadeOut}},[_vm._v(" Enter Site ")]),_c('a',{staticClass:"inline-middle control-arrow gap-top-xl is-cursor",on:{"click":_vm.onFadeOut}},[_vm._v(" → ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }