// import { createApp } from 'vue'
// import ShowDown from 'showdown'
import _ from 'lodash'
import Constants from '@/constants'
import Util from '@/utils'

const ImageSales = `${Constants.BASE_ASSETS}dash_sales.png`
const ImageProducts = `${Constants.BASE_ASSETS}dash_products.png`

const EventBus = {
  data: {
    _isDesktop: true,
    _windowWidth: -1,
    _navMain: [
      {
        name: 'about',
        content: 'About',
        // pathUrl: '/about',
        section: 'section-about',
        isCustom: true,
        pathModules: [Constants.ModuleType.PROGRAM_DASHBOARD],
        modules: [Constants.ModuleType.ADMIN],
        offset: { left: -20 },
        subnavOpen: false,
        subnavMobileOpen: true,
        subnav: [
          {
            name: 'film',
            title: 'The Film',
            // pathUrl: '/film',
            section: 'section-film',
            isCustom: true,
            pathModules: [Constants.ModuleType.PROGRAM_DASHBOARD],
            modules: [Constants.ModuleType.ADMIN],
            style: { minWidth: '150px' },
            styleInner: { minWidth: '150px' }
          }, {
            name: 'director',
            title: 'The Director',
            // pathUrl: '/director',
            section: 'section-director',
            isCustom: true,
            pathModules: [Constants.ModuleType.PROGRAM_DASHBOARD],
            modules: [Constants.ModuleType.ADMIN],
            style: { minWidth: '150px' },
            styleInner: { minWidth: '150px' }
          }
        ]
      }, {
        name: 'trailer',
        content: 'Trailer',
        // pathUrl: '/trailer',
        section: 'section-trailer',
        isCustom: true,
        pathModules: [Constants.ModuleType.PROGRAM_DASHBOARD],
        modules: [Constants.ModuleType.ADMIN],
        offset: { left: -37 },
      }, {
        name: 'unity',
        content: 'Unity',
        // pathUrl: '/unity',
        section: 'section-unity',
        isCustom: true,
        pathModules: [Constants.ModuleType.PROGRAM_DASHBOARD],
        modules: [Constants.ModuleType.ADMIN],
        offset: { left: -37 },
      }, {
        name: 'contact',
        content: 'Contact',
        // pathUrl: '/contact',
        section: 'component-footer',
        isCustom: true,
        pathModules: [Constants.ModuleType.PROGRAM_DASHBOARD],
        modules: [Constants.ModuleType.ADMIN],
        offset: { left: -37 },
      },
      // {
      //   name: 'account',
      //   content: '<i class="fa-solid fa-user-circle" style="margin-top:3px"></i>',
      //   offset: { left: -64 },
      //   subnavOpen: false,
      //   subnavMobileOpen: true,
      //   subnav: [
      //     {
      //       name: 'logout',
      //       title: 'Logout',
      //       path: 'logout',
      //       style: { minWidth: '150px' },
      //       styleInner: { minWidth: '150px' }
      //     }
      //   ]
      // }
    ],
    _navDashboard: [
      {
        label: 'Clues', // 'Sales Opportunity',
        name: 'questions', // 'sales.opportunity.overview',
        image: ImageSales,
        icon: 'fa-solid fa-clipboard-list',
        modules: [Constants.ModuleType.ADMIN],
        class: 'color-iris-blue-bg'
      },
      {
        label: 'MasterKey', // 'Market Insights',
        name: 'masterkey', // 'report',
        image: ImageProducts,
        icon: 'fa-solid fa-building',
        modules: [Constants.ModuleType.ADMIN],
        class: 'color-iris-teal-bg'
      },
      {
        label: 'Users', // 'Market Insights',
        name: 'users',
        image: ImageProducts,
        icon: 'fa-solid fa-users',
        modules: [Constants.ModuleType.ADMIN],
        class: 'color-iris-darkgreen-bg'
      }
    ]
  },
  computed: {
    isDesktop: {
      get: function () {
        return EventBus.data._isDesktop
      },
      set: function (isDesktop) {
        if (EventBus.data._isDesktop !== isDesktop) {
          // do not update immediately, only change if necessary
          EventBus.data._isDesktop = isDesktop
          EventBus.data.$emit('isDesktopChanged', isDesktop)
        }
      }
    }
  },
  // watch: {
  // },
  // mounted () {
  //   // console.log('-- mounted')
  // },
  methods: {
    keepAlive: async function () {
      try {
        // console.info('-- keep alive check')
        // await Auth.keepAlive() // do nothing
      } catch (error) {
        console.error('keep alive error', error)
        let isError = false
        throw isError
      }
      return true
    },

    // getHtml: function (content) {
    //   var converter = new ShowDown.Converter()
    //   var html = converter.makeHtml(content)
    //   return html
    // },

    toggleSubnavOpen: function (name) {
      let navItem = _.find(EventBus.data._navMain, ['name', name])
      if (navItem && navItem.hasOwnProperty('subnavOpen')) {
        navItem.subnavOpen = !navItem.subnavOpen
      }
    },

    setHidden: function (name, hidden = true) {
      let navItem = _.find(EventBus.data._navMain, ['name', name])
      if (navItem) {
        navItem.hidden = hidden
      }
    },

    getNav: function ($route, $store) {
      // // inject super admin menu
      // if ($route.name && $route.name.startsWith('onboarding.')) {
      //   return EventBus.data._navSupplier
      // }
      // else if ($route.name && $route.name.startsWith('sales.')) {
      //   let subnav = EventBus.data._navAccount[0].subnav
      //   if (subnav && subnav.length > 0 && subnav[0].name === 'changepassword') {
      //     // remove change password only
      //     subnav.splice(0, 1)
      //   }
      //   // subnav.splice(0, 1)
      //   // this.$set(EventBus.data._navAccount[0], 'subnav', subnav)
      //   navMain = EventBus.data._navAccount
      // } else {
      //   navMain = EventBus.data._navMain.concat(EventBus.data._navAccount)
      // }
      //
      // if ($store.getters.user.role === Constants.RoleType.SUPERADMIN) {
      //   let index = _.findIndex(navMain, item => item.name === 'account')
      //   if (navMain[index] && navMain[index].subnav) {
      //     if (!_.find(navMain[index].subnav, (item) => item.name === 'users')) {
      //       let hiddenMenu = []
      //       hiddenMenu = hiddenMenu.concat(EventBus.data._subnavHidden)
      //       hiddenMenu = hiddenMenu.concat(navMain[index].subnav)
      //       navMain[index].subnav = hiddenMenu
      //     }
      //   }
      // }
      // let ServiceType = PartnerConstants.ServiceType
      let navMain = EventBus.data._navMain
      // navMain = Util.array.copy(navMain) // not working
      // console.log('-- navMain', navMain)
      // console.log('-- navMain copy', Util.array.copy(navMain))
      // let serviceTypes = $store.getters.user.partnerApplicationServices
      // console.log('-- get serviceTypes user', $store.getters.user)
      // let keys = []
      // for (let i in navMain) {
      //   let navItem = navMain[i]
      //   for (let k in keys) {
      //     let key = keys[k]
      //     if (!serviceTypes || typeof serviceTypes !== 'object' || !serviceTypes.includes(key)) continue
      //     if (navItem.services && navItem.services.includes(key)) {
      //       navItem.hidden = false
      //     }
      //   }
      //   if (navItem.subnav) {
      //     for (let i in navItem.subnav) {
      //       let subnavItem = navItem.subnav[i]
      //       for (let k in keys) {
      //         let key = keys[k]
      //         if (!serviceTypes || typeof serviceTypes !== 'object' || !serviceTypes.includes(key)) continue
      //         if (subnavItem.services && subnavItem.services.includes(key)) {
      //           subnavItem.hidden = false
      //         }
      //       }
      //     }
      //   }
      //   if (navItem.hasOwnProperty('pathModules') &&
      //     navItem.pathModules && navItem.pathModules.length > 0) {
      //     let pathModules = navItem.pathModules
      //     let modules = $store.getters.user && $store.getters.user.modules
      //     if (!Util.array.containsFromArray(modules, pathModules) && navItem.hasOwnProperty('path')) {
      //       delete navItem.path
      //     }
      //   }
      // }
      // for (let k in keys) {
      //   let key = keys[k]
      //   if (!serviceTypes || typeof serviceTypes !== 'object' || !serviceTypes.includes(key)) continue
      //   let navItem = _.find(navMain, (navItem) => (navItem.services && navItem.services.includes(key)))
      //   navItem.hidden = !navItem
      // }
      return navMain
    },

    detectIE: function () {
      /**
       * detect IE
       * returns version of IE or false, if browser is not Internet Explorer
       */
      var ua = window.navigator.userAgent

      // Test values; Uncomment to check result …

      // IE 10
      // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

      // IE 11
      // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

      // Edge 12 (Spartan)
      // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

      // Edge 13
      // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

      var msie = ua.indexOf('MSIE ')
      if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
      }

      var trident = ua.indexOf('Trident/')
      if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:')
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
      }

      var edge = ua.indexOf('Edge/')
      if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
      }

      // other browser
      return false
    }
  }
} // <-- in this case access using import EventBus

export default EventBus
