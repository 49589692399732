var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"page-main",staticClass:"page-landing"},[_c('audio',{ref:"audioPlayer",staticStyle:{"position":"absolute","top":"100px"},attrs:{"id":"audio","autoplay":"true","loop":"true","control":"false","webkit-playsinline":"true","playsinline":"true","muted":"false","preload":"auto"}},[_c('source',{attrs:{"src":require("../assets/audio_bg.mp3"),"type":"audio/mp3"}}),_vm._v(" Your browser does not support the audio element. ")]),_c('div',{ref:"section-clear",staticClass:"section-clear"}),_c('div',{ref:"section-clear-add",staticClass:"section-clear-add"},[_vm._m(0)]),_c('div',{staticClass:"hanging scroll-label is-hidden-mobile font-roboto",staticStyle:{"overflow":"hidden"}},[_c('div',{ref:"component-scroll",class:(_vm.isScrollMidPoint ? 'aos-animate' : ''),attrs:{"data-aos":"slide-up","data-aos-once":"true","data-aos-offset":"99999","data-aos-delay":"500","data-aos-duration":"1300","data-aos-easing":"easing-custom"}},[_vm._v(" SCROLL ")])]),_c('div',{staticClass:"hanging line line-main is-hidden-mobile",style:({
      top: _vm.lineTop,
      height: `${_vm.lineHeight}px`,
      transform: `scaleY(${_vm.lineRatio})`,
      transformOrigin: 'top center',
      transition: 'transform 0.5s ease',
    })}),_c('section-about',{ref:"section-about",attrs:{"last-position":_vm.lastPosition}}),_c('section-trailer',{ref:"section-trailer",attrs:{"last-position":_vm.lastPosition},on:{"on-trailer":_vm.onTrailer}}),_c('section-film',{ref:"section-film",attrs:{"last-position":_vm.lastPosition},on:{"on-cast":_vm.onCast}}),_c('section-photo',{ref:"section-photo",attrs:{"last-position":_vm.lastPosition}}),_c('section-director',{ref:"section-director",attrs:{"last-position":_vm.lastPosition},on:{"on-bio":_vm.onBio,"on-interview":_vm.onInterview}}),_c('section-support',{ref:"section-unity",attrs:{"last-position":_vm.lastPosition}}),_c('com-footer',{ref:"component-footer"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-overlay"},[_c('div',{staticClass:"item-overlay-section"},[_c('div',{staticClass:"item-overlay-section-inner"})]),_c('div',{staticClass:"item-overlay-section"},[_c('div',{staticClass:"item-overlay-section-inner"})]),_c('div',{staticClass:"item-overlay-section"},[_c('div',{staticClass:"item-overlay-section-inner"})]),_c('div',{staticClass:"item-overlay-section"},[_c('div',{staticClass:"item-overlay-section-inner"})]),_c('div',{staticClass:"item-overlay-section"},[_c('div',{staticClass:"item-overlay-section-inner"})])])
}]

export { render, staticRenderFns }