// import _ from 'lodash'
import Cookies from 'js-cookie'
import Constants from '../constants'

const TokenKey = 'IrisToken'
const UserIdKey = 'IrisUserId'
const UsernameKey = 'IrisUsername'
const RoleKey = 'IrisRole'
const ModulesKey = 'IrisModules'
const ModuleIdsKey = 'IrisModuleIds'
const SalesPersonKey = 'IrisSalesPerson'
const LoginFrom = 'IrisLoginFrom'
const LandingPath = 'IrisLandingPath'
// const IsPartnerApplicationSubmitted = 'IrisIsPartnerApplicationSubmitted'
const PartnerApplicationServicesKey = 'IrisPartnerApplicationServices'

const SET_OPT = { expires: 7 }

const getCookies = () => {
  var cookies = Cookies.withConverter({
    read: function (value, name) {
      return unescape(value)
      // if (name === 'escaped') {
      //   return unescape(value)
      // }
      // // Fall back to default for all other cookies
      // return Cookies.converter.read(value, name)
    }
  })
  return cookies
}

// token

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token, SET_OPT)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

// userId

export function getUserId () {
  return Cookies.get(UserIdKey)
}

export function setUserId (userId) {
  return Cookies.set(UserIdKey, userId, SET_OPT)
}

export function removeUserId () {
  return Cookies.remove(UserIdKey)
}

// username

export function getUsername () {
  return Cookies.get(UsernameKey)
}

export function setUsername (username) {
  return Cookies.set(UsernameKey, username, SET_OPT)
}

export function removeUsername () {
  return Cookies.remove(UsernameKey)
}

// role

export function getRole () {
  let role = Cookies.get(RoleKey)
  if (role === undefined) {
    return Constants.RoleType.UNKNOWN
  }
  return parseInt(role, 10)
}

export function setRole (role) {
  return Cookies.set(RoleKey, role, SET_OPT)
}

export function removeRole () {
  return Cookies.remove(RoleKey)
}

// modules

export function getModules () {
  let cookies = getCookies()
  let modules = cookies.get(ModulesKey) || '[]'
  // console.log('-- modules', modules)
  try {
    let info = JSON.parse(modules) || []
    return info
  } catch (error) {
    console.error('auth: parse modules err', error)
  }
  modules = modules || []
  return modules
}

export function setModules (modules) {
  try {
    modules = JSON.stringify(modules)
  } catch (error) {
    console.error('auth: stringify modules err', error)
  }
  return Cookies.set(ModulesKey, modules, SET_OPT)
}

export function removeModules () {
  return Cookies.remove(ModulesKey)
}

// module ids

export function getModuleIds () {
  let cookies = getCookies()
  let modules = cookies.get(ModuleIdsKey) || '[]'
  // console.log('-- moduleIds', modules)
  try {
    let info = JSON.parse(modules)
    return info
  } catch (error) {
    console.error('auth: parse moduleIds err', error)
  }
  modules = modules || []
  return modules
}

export function setModuleIds (modules) {
  try {
    modules = JSON.stringify(modules)
  } catch (error) {
    console.error('auth: stringify moduleIds err', error)
  }
  return Cookies.set(ModuleIdsKey, modules, SET_OPT)
}

export function removeModuleIds () {
  return Cookies.remove(ModuleIdsKey)
}

// module sales person

export function getSalesPerson () {
  let salesperson = Cookies.get(SalesPersonKey)
  // console.log('-- get', salesperson)
  if (!salesperson || salesperson === null) {
    return {}
  } else if (typeof salesperson === 'string') {
    try {
      let info = JSON.parse(salesperson)
      salesperson = info
      return salesperson
    } catch (error) {
      console.error('parse salesperson err', error)
    }
  }
  return {}
}

export function setSalesPerson (salesperson) {
  if (salesperson && typeof salesperson === 'object' && salesperson !== null) {
    salesperson = JSON.stringify(salesperson)
  } else {
    salesperson = '{}'
  }
  // console.log('-- set', salesperson)
  return Cookies.set(SalesPersonKey, salesperson, SET_OPT)
}

export function removeSalesPerson () {
  return Cookies.remove(SalesPersonKey)
}

// module login from

export function getLoginFrom () {
  return Cookies.get(LoginFrom)
}

export function setLoginFrom (loginFrom) {
  return Cookies.set(LoginFrom, loginFrom, SET_OPT)
}

export function removeLoginFrom () {
  return Cookies.remove(LoginFrom)
}

export function getLandingPath () {
  return Cookies.get(LandingPath)
}

export function setLandingPath (path) {
  return Cookies.set(LandingPath, path, SET_OPT)
}

// export function getIsPartnerApplicationSubmitted () {
//   let submitted = Cookies.get(IsPartnerApplicationSubmitted)
//   return !!submitted
// }
//
// export function setIsPartnerApplicationSubmitted (isPartnerApplicationSubmitted) {
//   return Cookies.set(IsPartnerApplicationSubmitted, (isPartnerApplicationSubmitted ? 1 : 0), SET_OPT)
// }

export function getPartnerApplicationServices () {
  let list = Cookies.get(PartnerApplicationServicesKey)
  // console.log('-- get pas', list)
  if (!list || list === null) {
    return []
  } else if (typeof list === 'string') {
    try {
      let info = JSON.parse(list)
      list = info
      return list
    } catch (error) {
      console.error('auth: parse partner services err', error)
    }
  }
  return []
}

export function setPartnerApplicationServices (list) {
  if (list && typeof list === 'object' && list !== null) {
    list = JSON.stringify(list)
  } else {
    list = '[]'
  }
  // console.log('-- set pas', list)
  return Cookies.set(PartnerApplicationServicesKey, list, SET_OPT)
}

export function removePartnerApplicationServices () {
  return Cookies.remove(PartnerApplicationServicesKey)
}
