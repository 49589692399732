import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
// import VuexPersist from 'vuex-persist'
// import Cookies from 'js-cookie'
// import axios from 'axios'

Vue.use(Vuex)

import app from './modules/app'
import page from './modules/page'
import user from './modules/user'
import products from './modules/products'
import poform from './modules/poform'
import onboarding from './modules/onboarding'

const store = new Vuex.Store({
  modules: {
    app,
    page,
    user,
    products,
    poform,
    onboarding
  },
  getters
})

export default store
