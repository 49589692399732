<template>
  <div id="app" ref="appWrapper" class="app-wrapper pos-relative">
    <!-- <embed src="./assets/audio_bg.mp3" loop="true" autostart="true" width="2" height="0" /> -->
    <!-- <audio
      id="audio" :ref="audioPlayer"
      :autoplay="true" :loop="true" :controls="true"
      :webkit-playsinline="true"
      :playsinline="true"
      :muted="true"
      preload="auto"
      style="position:absolute;top:100px;">
      <source src="./assets/audio_bg.mp3" type="audio/mp3">
      Your browser does not support the audio element.
    </audio> -->

    <slick-view
      v-if="!isLoading"
      class="slick-view"
      ref="slick-view"
      :is-desktop="isDesktop"
      :is-muted="isMuted"
      :last-position="lastPosition"
      @on-toggle-mute="onToggleMute">
    </slick-view>

    <!-- <audio
      ref="audioPlayer"
      controls autoplay muted
      :loop="true"
      :webkit-playsinline="true"
      :playsinline="true">
      <source :src="audioSrc" type="audio/mp4">
    </audio> -->

    <section-loading
      v-if="isLoading"
      @on-loading-ended="onLoadingEnded">
    </section-loading>

    <navbar-overlay :menu="true"
      v-if="!alreadySlideUp && !isLoading"
      class="navbar-overlay-main"
      :is-fixed="true"
      :is-shaded="false"
      @on-custom-scroll-to="onCustomScrollTo" />

    <div class="smooth" ref="smooth" v-if="!isLoading">
      <navbar-overlay :menu="true"
        class="navbar-overlay-main"
        v-if="alreadySlideUp"
        :is-fixed="false"
        :is-shaded="false"
        @on-custom-scroll-to="onCustomScrollTo" />

      <!-- <div>&nbsp;&nbsp;!-- workaround for dropdown fix --</div> -->
      <!-- <navbar></navbar> -->

      <router-view
        class="page-wrapper"
        ref="routerView"
        :last-position="lastPosition"
        :already-label-up="alreadyLabelUp"
        :already-label-down="alreadyLabelDown"
        @on-init="onRouterPageInit"
        @on-trailer="onTrailer"
        @on-cast="onCast"
        @on-bio="onBio"
        @on-interview="onInterview">
      </router-view>
    </div>

    <div class="go-top-wrapper">
      <div class="go-top is-cursor"
        @click="onGoTop"
        data-aos="slide-up"
        data-aos-once="true"
        data-aos-offset="99999"
        data-aos-delay="500"
        data-aos-duration="1300"
        data-aos-easing="easing-custom"
        :class="(isTopUp ? 'aos-animate' : '')">
        ↑
      </div>

      <div class="go-mute is-cursor aos-animate"
        v-if="!isLoading"
        @click="onToggleBgMute"
        data-aos="slide-up"
        data-aos-once="true"
        data-aos-offset="99999"
        data-aos-delay="500"
        data-aos-duration="1300"
        data-aos-easing="easing-custom">
        {{ isMutedBg ? '+' : '-' }}
      </div>
    </div>

    <!-- <div style="position:fixed;width:auto;top:0;right:0;background-color:blue;padding:20px;font-family:'Proxima Nova';color:white;">
      Last Pos: {{ lastPosition }}<br />
      Win Y Offset: {{ pageYOffset }}
    </div> -->

    <!-- <dialog-notice
      ref="dialogNotice"
      :title="'Dialog Notice'"
      @on-close="onNoticeClose">
    </dialog-notice> -->

    <dialog-vimeo
      ref="dialogVimeo"
      :title="'Dialog Vimeo'"
      @on-close="onVimeoClose">
    </dialog-vimeo>

    <dialog-cast
      ref="dialogCast"
      :title="'Dialog Cast'">
    </dialog-cast>

    <dialog-bio
      ref="dialogBio"
      :title="'Dialog Bio'">
    </dialog-bio>

    <dialog-interview
      ref="dialogInterview"
      :title="'Dialog Interview'"
      @on-vimeo="onVimeo">
    </dialog-interview>
  </div>
</template>

<script>
import _ from 'lodash'
import Constants from '@/constants'
import EventBus from '@/controllers/EventBus'
// import Navbar from '@/components/NavBar.vue'
import SectionLoading from '@/pages/sections/sectionloading'
import NavbarOverlay from '@/components/NavBarOverlay.vue'
import SlickView from '@/components/SlickView'
// import DialogNotice from '@/pages/sections/dialognotice'
import DialogVimeo from '@/pages/sections/dialogvimeo'
import DialogCast from '@/pages/sections/dialogcast'
import DialogBio from '@/pages/sections/dialogbio'
import DialogInterview from '@/pages/sections/dialoginterview'
// import Audio from '@/assets/audio_marcia.mp4'
export default {
  name: 'app-main',
  components: {
    SectionLoading,
    // Navbar,
    NavbarOverlay,
    SlickView,
    // DialogNotice,
    DialogVimeo,
    DialogCast,
    DialogBio,
    DialogInterview,
  },
  data () {
    return {
      audioPlayer: undefined,
      isSafari: false,
      isDesktop: true,
      isLoading: true,
      isMutedBg: false,
      isMuted: false,
      lastPosition: 0,
      totalHeight: 0,
      timer: undefined,
      alreadyLabelUp: false,
      alreadyLabelDown: false,
      alreadySlideUp: false,
      alreadySlideDown: false,
      alreadyTopUp: false,
      alreadyTopDown: false,
      isTopUp: false,
      isVimeo: false,
      // audioSrc: Audio,
    }
  },
  computed: {
    pageYOffset () {
      return window.pageYOffset
    },
  },
  watch: {
    lastPosition: function (value, oldValue) {
      this.updateSlickViewPositionFromSmoothScroll()
    },
  },
  created () {
    window.addEventListener('resize', this.reinit.bind(this));
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  destroyed () {
    window.removeEventListener('resize', this.reinit.bind(this));
  },
  async mounted () {
    // await this.startSmoothScroll()
    this.initResize()
    // this.updateSlickViewPositionFromSmoothScroll()

    this.timer = setInterval(() => this.timerRefresh(), 100)
    this.timerRefresh()

    this.onTrack(Constants.TRACK_EVENT.VIEW, 'loading')
  },
  methods: {
    onTrack (event, label, category = Constants.TRACK_EVENT_CATEGORY.GENERAL, value = undefined) {
      Constants.onTrack(this, event, label, category, value)
    },

    onLoadingEnded () {
      console.log('app: onLoadingEnded()')
      this.$set(this, 'isLoading', false)
    },

    async onRouterPageInit (info) {
      let totalHeight = (info && info.pageHeight) || this.$refs.smooth.getBoundingClientRect().height
      this.$set(this, 'totalHeight', totalHeight)
      console.log('app: init router page', { info, totalHeight })
      this.updateSlickViewPositionFromSmoothScroll()
      await this.startSmoothScroll()

      // if (this.$refs.audioPlayer) {
      //   this.$refs.audioPlayer.play()
      // }

      setTimeout(() => {
        this.onNotice()
      }, 1500)
    },

    timerRefresh () {
      if (this.isLoading) return
      this.updateSlickViewPositionFromSmoothScroll()
    },

    updateSlickViewPositionFromSmoothScroll () {
      let top = this.$refs.smooth.getBoundingClientRect().top
      let height = this.$refs.smooth.getBoundingClientRect().height
      // console.log('app: updateSlickViewPositionFromSmoothScroll', { top, height })

      let offset = 50
      if (-top < offset && !this.alreadyLabelUp) {
        this.alreadyLabelUp = true
        this.alreadyLabelDown = false
        this.$refs['slick-view'].onLabelUp()
      }

      if (-top > offset && !this.alreadyLabelDown) {
        this.alreadyLabelUp = false
        this.alreadyLabelDown = true
        this.$refs['slick-view'].onLabelDown()
      }

      if (-top < height && !this.alreadySlideUp) {
        this.alreadySlideUp = true
        this.alreadySlideDown = false
        this.$refs['slick-view'].onSlideDown()
      }

      if (-top > height && !this.alreadySlideDown) {
        this.alreadySlideUp = false
        this.alreadySlideDown = true
        this.$refs['slick-view'].onSlideUp()
      }

      if (-top < height && !this.alreadyTopUp) {
        this.alreadyTopUp = true
        this.alreadyTopDown = false
        this.isTopUp = false
      }

      if (-top > height && !this.alreadyTopDown) {
        this.alreadyTopUp = false
        this.alreadyTopDown = true
        this.isTopUp = true
      }
    },

    startSmoothScroll () {
      console.log('app: init smooth scroll')
      // https://codepen.io/ifen/pen/PovvPa

      // https://stackoverflow.com/questions/61319159/how-to-do-slow-scrolling-like-these-website-are-doing
      // https://loerarchitecten.com/en/projects/republica-short-guy/

      this.reinit()
      // window.addEventListener('resize', this.reinit.bind(this));

      this.loop()
    },

    initSmoothScroll () {
      // $('.wrapper').height($('.smooth').height());
      let wrapper = this.$refs.appWrapper
      let smooth = this.$refs.smooth
      if (!this.$refs.routerView) return
      let totalHeight = this.$refs.routerView.totalHeight
      if (wrapper && wrapper.style) {
        console.log('app: init smooth height', smooth, wrapper, totalHeight)
        // wrapper.height(totalHeight || smooth.height())
        wrapper.style.height = (totalHeight || smooth.clientHeight)
      }
    },

    initResize () {
      const isDesktop = (window.innerWidth >= 768)
      this.$set(this, 'isDesktop', isDesktop)
      EventBus.data._isDesktop = isDesktop
      EventBus.data._windowWidth = (window.innerWidth)

      if (this.$refs.dialogNotice) {
        this.$refs.dialogNotice.onResize(isDesktop)
      }

      let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      this.$set(this, 'isSafari', isSafari)
    },

    reinit () {
      this.initSmoothScroll()
      this.initResize()
    },

    loop () {
      if (!this.isDesktop || this.isSafari) {
        this.$set(this, 'lastPosition', window.pageYOffset)
      }

      var scroll = window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        // IE fallback, you can even fallback to onscroll
        function (callback) {
          window.setTimeout(callback, 1000 / 60)
        }
      // avoid calculations if not needed
      // note: this.lastPosition & window.pageYOffset is always half of actual px
      // eg: lastPosition = 100, px = 50
      // console.log('app: loop', { lastPosition: this.lastPosition, yOffset: window.pageYOffset })

      if (this.lastPosition == window.pageYOffset) {
        // console.log('app: no calculate', { lastPosition: this.lastPosition, pageYOffset: window.pageYOffset })
        scroll(this.loop)
        return false
      }

      // let totalHeight = this.totalHeight / 2
      // if (window.pageYOffset >= totalHeight) {
      //   window.pageYOffset = totalHeight
      // }

      // scroll
      // console.log('app: REcalculate')
      // this.lastPosition = window.pageYOffset
      this.$set(this, 'lastPosition', window.pageYOffset)
      var transform = 'translate3d(0px, -' + this.lastPosition + 'px, 0px)'
      var smoothScroll = this.$refs.smooth // $(".smooth")[0]
      if (!smoothScroll || smoothScroll.style == null) return
      smoothScroll.style.webkitTransform = transform
      smoothScroll.style.mozTransform = transform
      smoothScroll.style.transform = transform
      // smoothScroll.style.border = '10px solid white'
      scroll(this.loop)
    },

    onCustomScrollTo (info, isForceTop = false) {
      console.log('app: on scroll to', info)
      let section = info && info.section
      let name = info && info.name
      let router = this.$refs.routerView
      let sections = router && router.sections
      let offsetTop = 0
      if (!isForceTop) {
        let found = _.find(sections, (s) => s.name === section)
        if (!found || found.posY == null || found.posY <= 0) return
        offsetTop = found.posY
      }

      var smoothScroll = this.$refs.smooth // $(".smooth")[0]
      console.log('app: on smooth', smoothScroll)
      if (!smoothScroll || smoothScroll.style == null) return
      smoothScroll.style.transform = `translate3d(0px, -${offsetTop/2}px, 0px)`
      // window.pageYOffset = offsetTop
      // window.setTimeout(() => {
      //   console.log('app: on scroll to is scrolled?', window.pageYOffset)
      // }, 500)
      // smoothScroll.clearQueue().css({
      //   transform: `translate3d(0px, -${offsetTop}px, 0px)`
      // })
      window.scrollTo(0, offsetTop/2)
      // $('.flat-button').click(function() {
      //   $(".smooth").clearQueue().css({
      //       transform: 'translate3d(0px, -' + $('.toggle').offset().top + 'px, 0px)'
      //   });
      //   $(window).scrollTop($('.toggle').offset().top);
      //   return false;
      // })

      this.onTrack(Constants.TRACK_EVENT.SCROLL, section, undefined, name)
    },

    onGoTop () {
      this.onCustomScrollTo({}, true)
      this.onTrack()
    },

    onToggleBgMute () {
      let isMuted = !this.isMutedBg
      this.$set(this, 'isMutedBg', isMuted)
      console.log('app: set muted bg', isMuted)
      // console.log('app: router', this.$refs.routerView)
      if (!this.$refs.routerView) return
      this.$refs.routerView.setMuted(isMuted)
    },

    onToggleMute () {
      this.$set(this, 'isMuted', !this.isMuted)
      if (!this.$refs['slick-view']) return
      this.$refs['slick-view'].onToggleMute()
    },

    onTrailer (src) {
      this.$refs.dialogVimeo.show(src)
    },

    onNotice () {
      this.$refs.dialogNotice.show()
    },

    onNoticeClose () {
      // do nothing
    },

    onVimeo (src) {
      this.$set(this, 'isVimeo', true)
      this.$refs.dialogVimeo.show(src)
    },

    onVimeoClose () {
      if (this.isVimeo) {
        this.$set(this, 'isVimeo', false)
        this.$refs.dialogInterview.show()
      }
    },

    onCast () {
      this.$refs.dialogCast.show()
    },

    onBio () {
      this.$refs.dialogBio.show()
    },

    onInterview () {
      this.$refs.dialogInterview.show()
    },
  }
}
</script>

<style lang="scss">
// @import "./styles/base.scss";
@import "./styles/app.scss";
#app {
  font-family: "Roboto Mono", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // margin-top: 60px;
  // background-color: black;
}

.app-wrapper {
  .smooth {
    // z-index: 10000; // in front
    height: 100%;
    // background: #27A6AA;
    // color: white;
    // padding : 20px;
    // position: fixed;
    top: 0;
    right: 0;
    left: 0;
    transition: all .5s cubic-bezier(0.2, .84, .5, 1);
    transform-origin:0 0;
    transform: translate3d(0px, 0px, 0px);
    // -webkit-backface-visibility: hidden;
    // -moz-backface-visibility: hidden;
    // -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    // -webkit-perspective: 1000;
    // -moz-perspective: 1000;
    // -ms-perspective: 1000;
    perspective: 1000;

    // -webkit-box-sizing: border-box;
    // box-sizing: border-box;
  }

  .slick-view {
    // z-index: 9999; // behind smoothe layer
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
  }

  // .navbar-overlay-main {
  //   // z-index: 10001;
  // }

  .go-top-wrapper {
    position: fixed;
    right: 30px;
    bottom: 30px;
    overflow: hidden;

    .go-top {
      display: block;
      width: 50px;
      height: 50px;
      background-color: black;
      color: white;
      // border-radius: 25px;
      border: 0.1pt solid #666;
      padding: 12px;
    }

    .go-mute {
      display: block;
      width: 50px;
      height: 50px;
      background-color: black;
      color: white;
      // border-radius: 25px;
      border: 0.1pt solid #666;
      padding: 12px;
    }
  }
}

.app-nav {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: blue;
  opacity: 0.5;
}

</style>
