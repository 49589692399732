<template>
  <div class="navbar-wrapper" id="tsection"
    :class="[{ 'is-toggle': toggle }, { 'is-fixed': isFixed }, { 'is-shaded': isShaded }]">
    <div class="active-nav" v-if="toggle">
      <div class="link shorthand">
        <div v-for="(navItem, navIndex) in navMain" :key="`${navIndex}`" class="small-div">
          <span>{{ navItem.title }}</span>
          <a v-if="navItem.external === true" class="div-link" :href="navItem.path" target="_blank">
            <span v-if="navItem.content" class="link" style="display:inline-block;" v-html="navItem.content">
            </span>
            <span v-else class="link" style="display:inline-block;">
              {{ navItem.title }}
            </span>
          </a>
          <!-- master item -->
          <a
            v-else-if="!navItem.modules || (navItem.modules && Utils.array.containsFromArray(navItem.modules, modules))"
            class="div-link"
            :href="[navItem.section ? '/?anchor=' + navItem.section : navItem.path]"
            @click="[ navItem.section ? onScrollTo($event, 'landing', navItem) : (navItem.path ? '' : navItem.subnav ? onPressSubnav($event, navItem) : $event.preventDefault())]"
            :class="{ active : $route.name === navItem.name }">
            <span v-if="navItem.content" class="link" style="display:inline-block;" v-html="navItem.content">
            </span>
            <span v-else class="link" style="display:inline-block;">
              {{ navItem.title }}
            </span>
            <i
              v-if="navItem.subnav && navItem.subnav.length > 0"
              class="fa"
              :class="{ 'fa-chevron-down': !navItem.subnavMobileOpen, 'fa-chevron-up': navItem.subnavMobileOpen }" style="color:white;float:right;margin-left:15px;"></i>
          </a>
          <div
            v-for="(navSubItem, subindex) in navItem.subnav" :key="`${subindex}`"
            v-if="navItem.subnav && navItem.subnavMobileOpen === true"
            class="small-div-sub" style="margin-top:0;margin-bottom:0;">
            <a
              class="div-link"
              v-if="(!navItem.modules || (navItem.modules && Utils.array.containsFromArray(navItem.modules, modules))) && (!navSubItem.modules || (navSubItem.modules && Utils.array.containsFromArray(navSubItem.modules, modules))) && !navItem.hidden"
              :href="[navSubItem.section ? '/?anchor=' + navSubItem.section : navSubItem.path]"
              @click="[ navSubItem.section ? onScrollTo($event, 'landing', navSubItem) : (navSubItem.path ? '' : navSubItem.subnav ? onPressSubnav($event, navSubItem) : $event.preventDefault())]"
              :class="{ active : $route.name === navSubItem.name }">
              <span class="link" style="font-size:0.9em;padding-left:30px;display:inline-block;">
                {{ navSubItem.title }}
              </span>
              <i
                v-if="navSubItem.subnav && navSubItem.subnav.length > 0"
                class="fa"
                :class="{ 'fa-chevron-down': !navSubItem.subnavMobileOpen, 'fa-chevron-up': navSubItem.subnavMobileOpen }" style="color:white;float:right;margin-left:15px;margin-top:2px;position:inline-block;"></i>
            </a>

            <div
              v-if="navSubItem.subnav && navSubItem.subnavMobileOpen === true"
              v-for="(navSub2Item, subindex) in navSubItem.subnav" :key="`${subindex}`"
              class="small-div-sub" style="margin-top:0;margin-bottom:0;">
              <a
                class="div-link"
                v-if="(!navSubItem.modules || (navSubItem.modules && Utils.array.containsFromArray(navSubItem.modules, modules))) && (!navSub2Item.modules || (navSub2Item.modules && Utils.array.containsFromArray(navSub2Item.modules, modules)))"
                :href="[navSub2Item.section ? '/?anchor=' + navSub2Item.section : navSub2Item.path]"
                @click="[ navSub2Item.section ? onScrollTo($event, 'landing', navSub2Item) : (navSub2Item.path ? '' : $event.preventDefault())]"
                :class="{ active : $route.name === navSub2Item.name }">
                <span class="link" style="font-size:0.9em;padding-left:60px;">
                  {{ navSub2Item.title }}
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="small-div" v-if="false">
          <div>
            <a class="elink" :href="['mailto:' + navEmail]">{{ navEmail }}</a>
          </div>
        </div>
        <div class="small-div">
          <div class="eicon">
            <a v-for="(navItem, navIndex) in navSocial" :key="`${navIndex}`" class="fa" :href="navItem.path" target="_blank">
              <i class="fa" :class="['fa-' + navItem.fa]" aria-hidden="true"></i>
            </a>
          </div>
        </div>

      </div>
    </div>
    <div class="full-layer">
      <navbar-full
        :toggle="toggle"
        @onToggle="toggle = $event"
        @on-custom-scroll-to="onCustomScrollTo"
        :menu="menu">
      </navbar-full>
    </div>
  </div>
</template>

<script>
import Constants from '@/constants'
import Utils from '@/utils'
import EventBus from '@/controllers/EventBus'
import NavbarFull from './NavBarFull.vue'

export default {
  name: 'topsection',
  components: {
    'NavbarFull': NavbarFull
  },
  props: ['menu', 'is-fixed', 'is-shaded'],
  data () {
    return {
      // isDesktop: true,
      toggle: false,
      navMain: EventBus.methods.getNav(),
      navSocial: [], // EventBus._data._navSocial,
      navEmail: [], // EventBus._data._navEmail,
      menus: []
    }
  },
  computed: {
    Utils: function () {
      return Utils
    },
    modules: function () {
      return [Constants.ModuleType.ADMIN] // this.$store.getters.user.modules
    },
  },
  watch: {
    toggle: function (val) {
      // console.log('toggle changed to: ' + val)
    }
  },
  mounted () {
    // on event resize
    // this.isDesktop = EventBus._isDesktop
    // EventBus.$on('isDesktopChanged', function () {
    //   this.isDesktop = EventBus._isDesktop
    // }.bind(this))
  },
  methods: {
    onScrollTo ($event, name, navItem) {
      let section = navItem && navItem.section
      let isCustom = navItem && navItem.isCustom
      $event.preventDefault()
      var root = document.documentElement
      if (isCustom) {
        // toggle
        this.toggle = !this.toggle
        root.className = ''

        // scroll
        this.onCustomScrollTo(navItem)
      } else if (this.$route.name !== name) {
        this.$router.replace({ name: name, query: { anchor: section } })
      } else {
        this.toggle = !this.toggle
        root.className = ''

        // var screenHeight = window.innerHeight
        // this.$scrollTo(el)
        // this.$scrollTo('#' + section, { offset: (70 - (screenHeight)) })
        this.$scrollTo('#' + section, { offset: 50 })
      }
    },

    // smooth scrolling
    onCustomScrollTo (info) {
      this.$emit('on-custom-scroll-to', info)
    },

    onPressSubnav ($event, navItem) {
      $event.preventDefault()
      navItem.subnavMobileOpen = !navItem.subnavMobileOpen
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/app_setting.scss";

hr {
  width: 100%;
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #C82506;
}

.topsection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.topsection {
  min-height: 250px;
  width: 100%;
  height: auto;
  position: relative;
  background: transparent;
  margin-top: 50px;

  @media screen and (min-width: $desktop) {
    margin-top: 0;
  }
  & .ccontainer {
    max-width: 1152px !important;
    z-index: 1;
  }
}

$widescreen: 1200px;

#tsection {
  // this to workaround top bar positioning
  z-index: 20;
  width: 100%;
  // position: relative;
  top: 0px;
  // position: fixed;
  position: absolute;
  //right: 0;
  // this to workaround top bar positioning

  &.is-fixed {
    position: fixed;
  }

  // change this to make single page
  height: auto; // 100vh // has issue, could not touch screen
  @media screen and (min-width: $tablet) {
    height: 52px;
  }
  // height: 62px;
  // @media screen and (min-width: $tablet) {
  //   height: 52px;
  // }
  // max-height: 70px; // middle ground to 1) make top bar fixed, 2) allow subnav expand
  overflow-y: visible;
  &.is-toggle {
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;

    .full-layer {
      .navbar-wrapper {
        background-color: black;
      }
    }
  }
  // change this to make single page

  // min-height: 100vh;

  & .active-nav {
    position: absolute;
    width: 100%;
    min-height: 100%; // change this to make single page
    padding-top: 8em; // change this to make single page
    padding-bottom: 5em;
    padding-left: 8%;
    padding-right: 8%;
    background-color: $color-dark-gray;
    z-index: 10;
    line-height: 1;
    //vertical-align: middle; // change this to make single page
    //justify-content: center; // change this to make single page
    //align-items: center; // change this to make single page
    display: flex;

    // change this to make single page
    // landscape mobile
    // @media (min-width: 480px) and (max-height: 420px) {
    //   padding-top: 5rem;
    // }
    // change this to make single page

    & .link a {
      color: $color-black;
      font-size: 1.1em;
      display: block;
      // margin-bottom: 1rem;

      // @media screen and (min-width: $tablet) {
      //   margin-bottom: 1rem;
      // }
      //
      // @media screen and (min-width: $desktop) {
      //   margin-bottom: 1rem;
      // }
      // // potrait
      // @media (max-width: 420px) and (min-height: 480px) {
      //   margin-bottom: 1rem;
      //
      //   // extra mini
      //   @media (max-width: 320px) {
      //     margin-bottom: 0rem;
      //   }
      // }
      // // landscape mobile
      // @media (min-width: 480px) and (max-height: 420px) {
      //   margin-bottom: 1rem;
      // }
      @media screen and (max-width: $mobile) {
        color: $color-white;

        span {
          text-transform: uppercase;
          font-size: 1.05rem;
          font-family: $font-family;
        }
        &:active, &:focus {
          span {
            color: $color-white;
          }
        }
      }

      &:before {
        background-color: $color-white;
      }
      // &:hover {
      //   font-weight: 600;
      // }
      // &.active {
      //   color: $color-cyan;
      // }
    }
    & .link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      min-height: 100%;

      &.shorthand {
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-flex-direction: row;
        flex-direction: row;
        height: 50%;
        width: 100%;

        & .small-div {
          width: 100%;
          text-align: left;

          // change this to make single page
          // // potrait
          // @media (max-width: 420px) and (min-height: 480px) {
          //   width: 100%;
          // }
          // // landscape mobile
          // @media (min-width: 480px) and (max-height: 420px) {
          //   width: 50%;
          // }
          // change this to make single page

          // & div,
          & .div-link {
            display: inline-block;
            font-family: $font-family-title;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            padding: 0.5rem;
            line-height: 1.5;

            .fa {
              color: $color-primary-red;
            }
          }
          // change this to make single page
          // &:first-child {
          //   // @media screen and (min-height: 601px) {
          //   margin-top: 2rem;
          //
          //   @media screen and (min-width: $tablet) {
          //     margin-top: 2rem;
          //   }
          //   // potrait
          //   @media (max-width: 420px) and (min-height: 480px) {
          //     margin-top: 2rem;
          //   }
          //   // landscape mobile
          //   @media (min-width: 480px) and (max-height: 420px) {
          //     margin-top: 0rem;
          //   }
          // }
          // change this to make single page
        }

        .small-div-sub {
          // margin-left: 5em;

          &:nth-child(2) {
            margin-top: 1em;
          }
          &:last-child {
            margin-bottom: 1em;
          }
        }
      }
    }
    & .link .elink {
      &:before {
        background-color: $color-red;
      }
      &:hover {
        color: $color-gray-light;
      }
    }
    & .link .eicon {
      // custom for iphone 4 and 5
      & a {
        display: inline-block;
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-left: 0rem;
        margin-right: 2.2rem;
        &:before {
          height: 0;
        }
      }
      & i {
        font-size: 1.5rem;
        // width: 50px;
        // height: 50px;
        vertical-align: middle;
      }

      &.link-ticket-wrapper {
        position: relative;

        a {
          margin: auto;
          padding: 5px 15px;

          &:hover {
            color: $color-primary-red;
          }
        }
      }

    }
  }

  & .logo {
    padding: 0 30px;
    @media screen and (min-width: $desktop) {
      padding: 0;
    }
  }
  & .sub {
    padding: 30px 30px;
    @media screen and (min-width: $tablet) {
      padding-top: 50px;
      padding-left: 60px;
      padding-right: 60px;
    }
    @media screen and (min-width: $desktop) {
      padding-top: 50px;
      padding-left: 30px;
      padding-right: 30px;
    }
    @media screen and (min-width: $widescreen) {
      padding-top: 70px;
    }
  }
} // #end tsection

@media print {
  #tsection {
    display: none;
  }
}

.full-layer {
  // min-height: 100vh;
  /*height: 100px;*/
  display: flex;
  flex-direction: column;

  // html.modal-open & {
  //   // this to remove scrollability
  //   pointer-events: auto;
  // }
}
@media screen and (min-width: 1008px) {
  .topsection {
    min-height: calc(100vh - 135px);
  }
}
</style>
