<template>
  <el-dialog
    class="dialog-interview"
    custom-class="dialog-base wrapper dialog-interview"
    v-if="visible"
    :title="undefined"
    :visible="visible"
    :width="'90%'"
    :before-close="onClose"
    :close-on-click-modal="closeOnClickModal != null ? closeOnClickModal : true">
    <!-- <div slot="title" class="title-content">
    </div> -->
    <div class="el-dialog__content"
      style="margin-left:auto;margin-right:auto;">
      <div id="section-film" class="sections section-film page-wrapper">
        <div class="section-wrapper full" style="padding-top:30px;padding-bottom:30px;">
          <h2 class="statement title roboto uppercase cwhite"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="300" data-aos-delay="100"
            style="margin-bottom:12px;">
            Director's Notes
          </h2>

          <h4 class="statement"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="200"
            style="margin-top:24px;">
            The motivation behind I Mother pre-dates the public outcry that followed the deaths of George Floyd and Breonna Taylor.  It was a very personal desire of mine to address the resulting circumstances that family’s go through when a life they love is suddenly taken away by an act of violence. In 2019 I had a call from my son who told me that a lifelong friend of his had been the victim of a violent knife attack, which thankfully he survived. This however was just a year or so after his elder brother was murdered under similar circumstances. Whilst this young man clung on to life, my thoughts immediately went to what his mother must have been going through. To have not one but two children taken from you in such a short space of time must have seen her on a journey through her own private hell.  Members of my own family have had to live through the pain of losing someone in such horrific circumstances, and this project has been an emotional experience for everyone involved. I hope we’ve managed to make a film that brings to light the hurt these families live with. I also hope we have honored the stories that have been shared by the mothers who made this film possible.
          </h4>

          <!-- <h4 class="statement"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="300">
            The point I am trying to make here is this; the taking of a life through a needles act of violence is something that has never been exclusive to communities of colour. Knife crime on Britain’s urban streets has been a thing for many a century, one that even the great Charles Dickens found time to romanticize about (if you’ve got to pick a pocket or two it would be prudent to have a switchblade nearby, should you be met with some kind of resistance). And one does not need to be well read or a scholar of any note to understand that mass shootings and death at the hands of someone with a loaded firearm has never been isolated to those who live on the south side of Chicago, Compton, Newark NJ or the economically challenged parts of New York’s five boroughs in this millennium.
          </h4>

          <h4 class="statement"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="400">
            Whilst the loss of a loved one through a random act of violence has a lasting impact on many families. The focus all too often is on the act itself, the moment in time when one individual took it upon themselves to take on the role of executioner. I wanted to address this subject with a slightly different perspective, the perspective of a mother. No matter the circumstances surrounding the taking of the life they gave birth to, a mother rarely overcomes the grief she must endure day after day. I know this because I have seen it first-hand. I see how the loss of a young life has an effect not just on the family in that moment, but how the lives of the immediate generation that follow still have to bear the emotional cloud that continues to hang heavy over them.
          </h4>

          <h4 class="statement"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="500">
            I wrote I Mother one year prior to the very public death of George Floyd. Before the senseless shooting of Brianna Taylor. Without knowledge of the apparent ‘suicide’ of Sandra Bland, or the racially motivated hunt and subsequent murder of Amaud Arberry. We began shooting this film during the social uprising’s that dominated the news across the globe, as the force of the Black Lives Matter movement gathered pace and conversations around diversity, equity and inclusion became standard in many cooperate workspaces.
          </h4>

          <h4 class="statement"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="600">
            But there is one place where the spotlight fails to shine a light, to capture where the pain and loss felt is everlasting. Long after the media attention is diverted and the bodies have been laid to rest, the mothers of these victims are still attempting to reconcile their loss. It is a journey through a private hell to which there is no end but they must continue to walk day after day. We made this film with these mothers at the forefront of my mind.
          </h4> -->

          <h4 class="statement"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="100" data-aos-delay="200"
            style="margin-top:24px;">
            Watch Behind The Scene Interview with The Director, Dee Allen-Barnes:
          </h4>

          <h4 class="statement"
            v-for="(vim, vindex) in vimeos" :key="`${vindex}`"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="100"
            :data-aos-delay="300 + (100 * vindex)">
            <!-- {{ vim.description }}
            <br />
            <a :href="vim.src"
              @click="onVimeo($event, vim)">
              {{ vim.src }}
            </a> -->
            <span class="inline-middle uppercase gap-top-xl text-tag is-cursor" @click="onVimeo($event, vim)">
              {{ vim.description }}
            </span>
            <a class="inline-middle gap-top-xl is-cursor" @click="onVimeo($event, vim)">
              <span class="control-arrow is-hidden-mobile">
                →
              </span>
            </a>
          </h4>
        </div>
      </div>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <div class="footer-content has-text-right">
        <el-button
          type="info"
          @click="onClose">
          OK
        </el-button>
      </div>
    </span> -->
  </el-dialog>
</template>

<script>
// import _ from 'lodash'
// import Constants from '@/constants'
// import EventBus from '@/controllers/EventBus'
// import { getToken } from '@/utils/auth'

export default {
  name: 'dialog-interview',
  props: ['close-on-click-modal'],
  components: {
  },
  data () {
    return {
      visible: false,
      vimeos: [
        {
          description: 'WHY TELL THIS STORY',
          src: 'https://player.vimeo.com/video/773953475?h=cb746d1cd4',
          src2: 'https://vimeo.com/773953475/cb746d1cd4'
        }, {
          description: 'MOTIVATION',
          src: 'https://player.vimeo.com/video/773952348?h=109e8e30d8',
          src2: 'https://vimeo.com/773952348/109e8e30d8'
        }, {
          description: 'AUDIENCE FILMED IS AIMED AT',
          src: 'https://player.vimeo.com/video/773985268?h=f07039a53f',
          src2: 'https://vimeo.com/773985268/f07039a53f'
        }, {
          description: 'WHEN DID YOU REALIZE',
          src: 'https://player.vimeo.com/video/773952935?h=70fa741d4e',
          src2: 'https://vimeo.com/773952935/70fa741d4e'
        }, {
          description: 'INSPIRATION FROM AFRICA',
          src: 'https://player.vimeo.com/video/773986773?h=1509cd7e6b',
          src2: 'https://vimeo.com/773986773/1509cd7e6b'
        }, {
          description: 'PLANS FOR FUTURE PROJECTS',
          src: 'https://player.vimeo.com/video/773986163?h=b05238bc57',
          src2: 'https://vimeo.com/773986163/b05238bc57'
        },
      ]
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  async mounted () {
  },
  methods: {
    show () {
      this.$set(this, 'visible', true)
    },

    // dialog
    onClose () {
      this.$set(this, 'visible', false)
      this.$emit('on-close')
    },

    // onSubmit () {
    //   this.$emit('on-submit')
    // },

    async onVimeo ($event, info) {
      $event.preventDefault()
      this.$set(this, 'visible', false)
      await this.$nextTick()

      // on vimeo
      let src = info.src
      // console.log(`-- info: ${info.description}`, src)
      this.$emit('on-vimeo', src)
    },
  }
}
</script>

<style lang="scss">
@import "../../styles/base.scss";
@import "../../styles/dialog.scss";
@import "../../styles/sections.scss";

.dialog-interview {
  padding-bottom: 50px;

  .statement {
    margin-top: 12px;
    margin-bottom: 12px;
    word-break: break-word;
    &.title {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
}

</style>
