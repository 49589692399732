// import _ from 'lodash'

class BooleanHelper {
  static isBoolean (value) {
    if (value == null) return false
    if (typeof value === 'boolean') return true
    if (typeof value === 'number') return (value === 0 || value === 1)
    return false
  }

  static isTrue (value) {
    if (value == null) return false
    if (typeof value === 'boolean') return value
    if (typeof value === 'number') return (value === 1)
    return false
  }
}

export default BooleanHelper
