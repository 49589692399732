// import Cookies from 'js-cookie'

const app = {
  state: {
    // setting: Cookies.get('setting') || 'en'
    pages: {}
  },
  mutations: {
    // SET_SETTING: (state, setting) => {
    //   state.setting = setting
    //   Cookies.set('setting', setting)
    // }
    SET_PAGE_PARAMS: (state, pageParams) => {
      if (state.pages == null) state.pages = {}
      if (!pageParams || !pageParams.name) throw new Error('invalid page param')
      if (state.pages[pageParams.name] == null) state.pages[pageParams.name] = {}
      // combining
      state.pages[pageParams.name] = {
        ...state.pages[pageParams.name],
        ...pageParams,
      }
    }
  },
  actions: {
    // setSetting ({ commit }, setting) {
    //   commit('SET_SETTING', setting)
    // }
    setPageParams ({ commit }, pageParams) {
      commit('SET_PAGE_PARAMS', pageParams)
    }
  }
}

export default app
