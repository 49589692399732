<template>
  <div id="component-footer" class="sections footer page-wrapper" style="text-align:center;">
    <div class="section-wrapper full no-pad" style="padding:0;">
      <div class="section-inner-wrapper flex flex-dir-col flex-dir-row-mobile">
        <div class="flex flex-dir-row flex-dir-col-mobile">
          <div class="statement statement-1">
            <img src="../assets/logo.png" class="img-responsive logo" />
            <h3 class="clause-0 cwhite uppercase font-roboto-mono gap-top-xl spacing-wide" style="padding:0;">
              A Dee Allen-Barnes Film
            </h3>
          </div>
          <div class="statement statement-2">
            <div>
              <span class="clause-1 cwhite font-roboto uppercase inline-middle top">
                Presented By
              </span>
              <a href="http://www.nochiefs.co/" target="_blank">
                <h3 class="clause-2 cwhite font-dosis uppercase inline-middle gap-left spacing-wide"
                  style="line-height:1em;">
                  No Chiefs
                </h3>
              </a>
              <div class="gap-top-xxl">
                <div class="font-roboto" style="font-weight:normal;">
                  <a href="mailto:info@imotherthefilm.com" target="_blank">info@imotherthefilm.com</a>
                </div>
                <div class="link-icon-group gap-top">
                  <a href="https://www.facebook.com/profile.php?id=61556886610380" class="link-icon" target="_blank">
                    <i class="fa fa-facebook"></i>
                  </a>
                  <a href="https://www.instagram.com/imotherthefilm" class="link-icon" target="_blank">
                    <i class="fa fa-instagram"></i>
                  </a>
                  <!-- <a href="https://www.twitter.com/imother" class="link-icon" target="_blank">
                    <i class="fa fa-twitter"></i>
                  </a>
                  <a href="https://www.youtube.com/imother" class="link-icon" target="_blank">
                    <i class="fa fa-youtube"></i>
                  </a> -->
                </div>
              </div>
            </div>
            <div class="font-roboto gap-top-xl">
              <span class="cwhite">All right reserved &copy;{{year}}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <span class="cwhite">
                Website by
                <a href="https://adaptconcepts.com" target="_blank">Adapt Concepts</a>
              </span>
            </div>
          </div>
        </div>

        <!-- <div class="flex flex-dir-row">
          <div class="statement statement-1">
          </div>
          <div class="statement statement-2">
          </div>
        </div> -->

      </div>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash'
// import Constants from '@/constants'
// import EventBus from '@/controllers/EventBus'
// import { getToken } from '@/utils/auth'

// import DivContainer from '@/components/DivContainer.vue'

export default {
  name: 'component-footer',
  components: {
    // DivContainer,
  },
  data () {
    return {
      // loading: false,
    }
  },
  computed: {
    year: function () {
      return (new Date()).getFullYear()
    },
  },
  watch: {
  },
  created () {
  },
  async mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import "../styles/base.scss";
@import "../styles/sections.scss";

.footer {
  .section-inner-wrapper {
    border-top: 0.1pt solid #333;
    padding: 60px 0 20px;
    @media screen and (max-width: $mobile) {
      border-top: 0;
    }
  }

  .statement {
    padding: 12px;
    text-align: left;
    @media screen and (max-width: $mobile) {
      padding: 20px;
      text-align: center;
    }
    &-1 {
      width: 62%;
      margin-bottom: 0;
      @media screen and (max-width: $mobile) {
        width: 100%;
        margin-bottom: 30px;
      }
    }

    &-2 {
      width: 38%;
      @media screen and (max-width: $mobile) {
        width: 100%;
      }
    }

    .logo {
      width: 30%;
      @media screen and (max-width: $mobile) {
        width: 50%;
      }
    }

    .clause-0 {
      margin-top: 12px;
      @media screen and (max-width: $mobile) {
        margin-top: 12px;
      }
    }

    .clause-1 {
      letter-spacing: 2px;
      font-size: 1.1em;
    }

    .clause-2 {
      letter-spacing: 4px;
      font-size: 1.3em;
      // @media screen and (max-width: $mobile) {
      //   font-size: 1.2em;
      // }
    }
  }

  .spacing-wide {
    letter-spacing: 6px;
    @media screen and (max-width: $mobile) {
      letter-spacing: 4px;
    }
  }

  .link-icon-group {
    margin-top: 12px;
    @media screen and (max-width: $mobile) {
      margin-top: 32px;
    }
  }

  .link-icon {
    font-size: 2em;
    padding: 12px;
    &:first-child {
      padding-left: 0;
    }
  }
}

</style>
