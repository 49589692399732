import _ from 'lodash'

class StringHelper {
  static test () {
    // test
  }

  static random (length = 8) {
    var text = ''
    var possible = 'ABCDEF0123456789' // GHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
  }

  static isBlank (value) {
    if (value == null) return true
    if (typeof value === 'boolean') return value == null
    if (typeof value === 'string') return value.length <= 0
    if (typeof value === 'number') return `${value}`.length <= 0
    console.error(`invalid isBlank check: ${value}`)
    return true
  }

  static truncateMiddle (fullstr, max, separator = '...') {
    if (fullstr.length <= max) return fullstr
    let sepLen = separator.length
    let charsToShow = max - sepLen
    let frontChars = Math.ceil(charsToShow / 2)
    let backChars = Math.floor(charsToShow / 2)
    return fullstr.substr(0, frontChars) +
      separator +
      fullstr.substr(fullstr.length - backChars)
  }

  static pad (total, def = ' ') {
    let string = []
    for (let t = 0; t < total; t++) {
      string += def
    }
    return string
  }

  static padFill (numOrText, len, def = '0', padLeft = true) {
    let s = numOrText + ''
    if (padLeft) {
      while (s.length < len) s = def + s
    } else {
      while (s.length < len) s = s + def
    }
    return s
  }

  static camelToSnakeCase (field) {
    return field
      .split(/(?=[A-Z])/)
      .map(x => x.toLowerCase())
      .join('_')
  }

  static snakeToCamel (field) {
    return field.replace(
      /([-_][a-z])/g,
      (group) => group
        .toUpperCase()
        .replace('-', '')
        .replace('_', ''))
  }

  static formatAddress (data, keys = ['address1', 'address2', 'city', 'zip', 'state', 'country']) {
    let address = ''
    if (!_.isEmpty(data)) {
      for (let k in keys) {
        let key = keys[k]
        if (data[key] && data[key].length > 0) {
          address += data[key]
          if (k < keys.length - 1) {
            address += ', '
          }
        }
      }
    }
    return address
  }

  static sanitizeString (value) {
    if (typeof value === 'string') {
      let val = value.trim()
      return val
    }
    return value
  }

  static getValue (value) {
    if (typeof value === 'string') {
      if (value.length <= 0) {
        return undefined
      }
      if (isNaN(parseFloat(value))) {
        return undefined
      }
      return parseFloat(value)
    } else if (typeof value === 'number') {
      return value
    }
    // undefined, null
    return undefined
  }

  static sanitizeValue (value, def = undefined) {
    if (typeof value === 'string') {
      value = value.trim().replace(/[$,]/g, '')
      if (!isNaN(parseFloat(value))) {
        value = parseFloat(value)
      }
    }
    if (typeof value === 'number' &&
      (isNaN(value) || !isFinite(value))) {
      return def
    }
    if (value == null) {
      return def
    }
    return value
  }

  static sanitizeValuePassthrough (value) {
    if (value === undefined || value === null || isNaN(value)) {
      return 0
    }
    return StringHelper.sanitizeValue(value)
  }
}

export default StringHelper
