import _ from 'lodash'

class FileHelper {
  static formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  static parseSingle (file) {
    let parse = {
      ...file
    }
    if (file && file.url) {
      parse.name = file.url
    }
    if (file && file.fullUrl) {
      parse.url = file.fullUrl
    }
    return parse
  }

  static parse (files) {
    if (!files) {
      return []
    }

    if (typeof files === 'object' && !Array.isArray(files)) {
      // in case object is single file
      files = [files]
    }

    // format
    // 0: {uploadId: 235,…}
    //   created_at: "2020-06-24 07:26:08"
    //   description: null
    //   fullUrl: "https://iris-acc360-docs.s3.us-east-2.amazonaws.com/presales/item/private/images/366836/3Az1DpYhpPMXPjHOQN9MWJXwDDgWuzzeZhtyQqUf.jpeg"
    //   status: null
    //   type: "item-setup"
    //   updated_at: "2020-06-24 07:26:08"
    //   uploadId: 235
    //   uploadable_id: 18
    //   uploadable_type: "App\Model\Presales\presalesItems"
    //   url: "presales/item/private/images/366836/3Az1DpYhpPMXPjHOQN9MWJXwDDgWuzzeZhtyQqUf.jpeg"

    let parsed = _.map(files, (file) => {
      let parse = {
        ...file
      }
      if (file && file.url) {
        parse.name = file.url
      }
      if (file && file.fullUrl) {
        parse.url = file.fullUrl
      }
      return parse
    })
    return parsed
  }

  static flatlist (keyinfos, form) {
    if (!keyinfos || !form) return {}
    keyinfos = _.filter(keyinfos, (item) => item.type === 'file')
    let fkeys = _.map(keyinfos, (item) => item.key)
    let fparams = {}
    for (let fk in fkeys) {
      let key = fkeys[fk]
      if (form[key] && form[key] !== null && form[key].length > 0) {
        fparams[key] = _.map(form[key], (finfo) => finfo.name)
      } else {
        fparams[key] = []
      }
    }
    return fparams
  }

  static downloadCsv (content, filename) {
    let csvFile
    let downloadLink
    csvFile = new Blob([content], { type: 'text/csv' })
    downloadLink = document.createElement('a')
    downloadLink.download = filename
    downloadLink.href = window.URL.createObjectURL(csvFile)
    downloadLink.style.display = 'none'
    document.body.appendChild(downloadLink)
    // download
    downloadLink.click()
  }
}

export default FileHelper
