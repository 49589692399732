<template>
  <div class="page-landing" ref="page-main">
    <!-- <embed src="../assets/audio_bg.mp3" loop="true" autostart="true" width="2" height="0" /> -->
    <audio
      id="audio" ref="audioPlayer"
      autoplay="true" loop="true" control="false"
      webkit-playsinline="true"
      playsinline="true"
      muted="false"
      preload="auto"
      style="position:absolute;top:100px;">
      <source src="../assets/audio_bg.mp3" type="audio/mp3">
      Your browser does not support the audio element.
    </audio>

    <!-- <slick-view
      class="slick-view"
      ref="slick-view"
      :last-position="lastPosition">
    </slick-view> -->

    <div ref="section-clear" class="section-clear"></div>
    <div ref="section-clear-add" class="section-clear-add">
      <div class="item-overlay">
        <div class="item-overlay-section">
          <div class="item-overlay-section-inner">
          </div>
        </div>
        <div class="item-overlay-section">
          <div class="item-overlay-section-inner">
          </div>
        </div>
        <div class="item-overlay-section">
          <div class="item-overlay-section-inner">
          </div>
        </div>
        <div class="item-overlay-section">
          <div class="item-overlay-section-inner">
          </div>
        </div>
        <div class="item-overlay-section">
          <div class="item-overlay-section-inner">
          </div>
        </div>
      </div><!-- end item-overlay -->
    </div>

    <div class="hanging scroll-label is-hidden-mobile font-roboto"
      style="overflow:hidden;">
      <div
        ref="component-scroll"
        data-aos="slide-up"
        data-aos-once="true"
        data-aos-offset="99999"
        data-aos-delay="500"
        data-aos-duration="1300"
        data-aos-easing="easing-custom"
        :class="(isScrollMidPoint ? 'aos-animate' : '')">
        SCROLL
      </div>
    </div>

    <div class="hanging line line-main is-hidden-mobile"
      :style="{
        top: lineTop,
        height: `${lineHeight}px`,
        transform: `scaleY(${lineRatio})`,
        transformOrigin: 'top center',
        transition: 'transform 0.5s ease',
      }">
    </div>

    <section-about
      ref="section-about"
      :last-position="lastPosition">
    </section-about>

    <section-trailer
      ref="section-trailer"
      :last-position="lastPosition"
      @on-trailer="onTrailer">
    </section-trailer>

    <section-film
      ref="section-film"
      :last-position="lastPosition"
      @on-cast="onCast">
    </section-film>

    <section-photo
      ref="section-photo"
      :last-position="lastPosition">
    </section-photo>

    <section-director
      ref="section-director"
      :last-position="lastPosition"
      @on-bio="onBio"
      @on-interview="onInterview">
    </section-director>

    <section-support
      ref="section-unity"
      :last-position="lastPosition">
    </section-support>

    <com-footer ref="component-footer"></com-footer>
  </div>
</template>

<script>
import _ from 'lodash'
import Constants from '@/constants'
// import EventBus from '@/controllers/EventBus'
// import { getToken } from '@/utils/auth'

// import DivContainer from '@/components/DivContainer.vue'
// import SlickView from '@/components/SlickView'
import SectionAbout from '@/pages/sections/sectionabout'
import SectionTrailer from '@/pages/sections/sectiontrailer'
import SectionFilm from '@/pages/sections/sectionfilm'
import SectionPhoto from '@/pages/sections/sectionphoto'
import SectionDirector from '@/pages/sections/sectiondirector'
import SectionSupport from '@/pages/sections/sectionsupport'
// import DialogVimeo from '@/pages/sections/dialogvimeo'
// import DialogCast from '@/pages/sections/dialogcast'
import ComFooter from '@/components/Footer'

export default {
  name: 'page-landing',
  props: ['last-position', 'already-label-up', 'already-label-down'],
  components: {
    // DivContainer,
    // SlickView,
    SectionAbout,
    SectionTrailer,
    SectionFilm,
    SectionPhoto,
    SectionDirector,
    SectionSupport,
    // DialogVimeo,
    // DialogCast,
    ComFooter,
  },
  data () {
    return {
      // loading: false,
      // dialogVimeoVisible: true,
      // dialogCastVisible: true,
      audioPlayer: undefined,
      totalHeight: 0,
      lineHeight: 0,
      lineRatio: 0,
      lineTop: undefined,
      timer: undefined,
      isScrollMidPoint: false,
      sections: [
        // { name: 'slick-view', height: 0, posY: 0, lineRatio: 0.0 },
        { name: 'section-clear', height: 0, posY: 0, lineRatio: 0.4 },
        { name: 'section-clear-add', height: 0, posY: 0, lineRatio: 1.0 },
        { name: 'section-about', height: 0, posY: 0, lineRatio: 1.0 },
        { name: 'section-trailer', height: 0, posY: 0, lineRatio: 1.0 },
        { name: 'section-film', height: 0, posY: 0, lineRatio: 1.0 },
        { name: 'section-photo', height: 0, posY: 0, lineRatio: 0 },
        { name: 'section-director', height: 0, posY: 0, lineRatio: 0 },
        { name: 'section-unity', height: 0, posY: 0, lineRatio: 0 },
        { name: 'component-footer', height: 0, posY: 0, lineRatio: 0 },
      ]
    }
  },
  computed: {
    // Util: function () {
    //   return Util
    // },
  },
  watch: {
    lastPosition: function (value, oldValue) {
      this.refresh(value)
      this.timerRefresh()
    }
  },
  created () {
  },
  async mounted () {
    // let token = getToken()
    // if (token && token.length > 0) {
    //   this.$router.push({ path: '/dashboard' })
    // }
    // console.log('landing: init landing')
    await this.refresh(this.lastPosition)
    this.$emit('on-init', { pageHeight: this.totalHeight })

    this.timer = setInterval(() => this.timerRefresh(), 100)
    this.timerRefresh()

    if (this.$refs.audioPlayer) {
      console.log('landing: ref audio', this.$refs.audioPlayer)
      this.$refs.audioPlayer.volume = 0.1
      this.$refs.audioPlayer.play()
    }

    this.onTrack(Constants.TRACK_EVENT.VIEW, 'landing')
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    onTrack (event, label, category = Constants.TRACK_EVENT_CATEGORY.GENERAL, value = undefined) {
      Constants.onTrack(this, event, label, category, value)
    },

    setMuted (isMuted) {
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.muted = isMuted
      }
    },

    getHeight (section) {
      let found = _.find(this.sections, (s) => s === section)
      if (found) return found && found.height
      return -1
    },

    getPosY (section) {
      let found = _.find(this.sections, (s) => s === section)
      if (found) return found && found.posY
      return -1
    },

    onClick (name) {
      this.$router.push({ name })
      this.onTrack(Constants.TRACK_EVENT.CLICK, `router-${name}`)
    },

    onTrailer (src) {
      // this.$refs.dialogVimeo.show()
      this.$emit('on-trailer', src)
      this.onTrack(Constants.TRACK_EVENT.CLICK, `trailer`, undefined, 'trailer')
    },

    onCast () {
      // this.$refs.dialogCast.show()
      this.$emit('on-cast')
      this.onTrack(Constants.TRACK_EVENT.CLICK, `cast`, undefined, 'cast')
    },

    onBio () {
      this.$emit('on-bio')
      this.onTrack(Constants.TRACK_EVENT.CLICK, `bio`, undefined, 'bio')
    },

    onInterview () {
      this.$emit('on-interview')
      this.onTrack(Constants.TRACK_EVENT.CLICK, `interview`, undefined, 'interview')
    },

    timerRefresh () {
      // let refName = 'component-scroll'
      // let ref = this.$refs[refName]
      // if (!ref) return false
      // let rect = ref.getBoundingClientRect()
      // let top = rect && rect.top
      // let targetHeight = window.innerHeight * 0.78
      // let isMidPoint = (top < targetHeight)
      // // console.log('landing: isMidPoint', ref, isMidPoint, top, targetHeight)
      // this.$set(this, 'isScrollMidPoint', isMidPoint)
      this.refresh(this.lastPosition)
    },

    async refresh (lastPosition) {
      try {
        this.loading = true

        let sectionHeight = this.$refs['page-main'].getBoundingClientRect().height
        let labelHeight = 200 * 1.2
        let defaultLineRatio = ((sectionHeight - labelHeight) / 2) / sectionHeight

        // if (this.$route.query && this.$route.query.anchor) {
        //   console.log('automatic scroll to...: ' + this.$route.query.anchor)
        //   setTimeout(function () {
        //     this.$scrollTo('#' + this.$route.query.anchor)
        //   }.bind(this), 1000)
        // }

        // tabulate generic section information
        let allTotal = 0
        let lineSectionTotal = 0
        let lineHeight = 0
        for (let l in this.sections) {
          let info = this.sections[l]
          let name = info.name
          let ratio = info.lineRatio
          let ref = this.$refs[name]
          let el = (ref && ref.$el) || (ref)
          // console.log(`${name}`, ref, el)
          if (!el) continue
          let height = el && el.getBoundingClientRect().height
          this.$set(info, 'posY', allTotal)
          this.$set(info, 'height', height)
          // console.log(`${name}=${height}`)
          allTotal += height
          lineHeight += height * ratio
          lineSectionTotal += (ratio > 0 ? height : 0)
        }

        // tabulate first section line top & ratio
        this.$set(this.sections[0], 'lineRatio', defaultLineRatio)
        let lineMoveDownRatio = 0 // ((this.lastPosition * 2) / sectionHeight)
        let lineTop = (1.0 - defaultLineRatio + lineMoveDownRatio)
        // if (lineTop > 1.2) lineTop = 1.2
        // console.log('landing: move down ratio', lineMoveDownRatio)
        // let lineTop = (1.0 - defaultLineRatio + lineMoveDownRatio) * 100
        this.$set(this, 'lineTop', `${lineTop * 100}%`)

        // tabulate line information
        this.$set(this, 'totalHeight', allTotal)
        this.$set(this, 'lineHeight', lineHeight)
        let prevLineRatio = this.lineRatio
        let baseLineRatio = (defaultLineRatio / (defaultLineRatio + 4.0)) * 1.2
        let lineRatio = (lastPosition * 2) / lineSectionTotal
        // lineRatio *= 1.2
        lineRatio = lineRatio > 1.0 ? 1.0 : lineRatio
        lineRatio = lineRatio < baseLineRatio ? baseLineRatio : lineRatio

        if (prevLineRatio < baseLineRatio) {
          lineRatio = prevLineRatio + baseLineRatio / 15
        } else {
          lineRatio *= 1.2
          lineRatio = lineRatio > 1.0 ? 1.0 : lineRatio
        }

        this.$set(this, 'isScrollMidPoint', this.alreadyLabelDown ? false : (lineRatio > baseLineRatio * 0.9))

        // let firstSectionRatio = (0.4 / 3.4)
        // let prevLineRatio = this.lineRatio
        // if (prevLineRatio < firstSectionRatio) {
        //   lineRatio += (0.4 / 3.4)
        // }
        // console.log('landing: line ratio', lineRatio)
        this.$set(this, 'lineRatio', lineRatio)
        // console.log('landing: current position', { lastPosition, lineHeight, lineSectionTotal, lineRatio: this.lineRatio })

        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss">
@import "../styles/base.scss";
@import "../styles/sections.scss";

.page-landing {
  // scrollbar hidden
  height: calc(100%/* - 70px */);
  // overflow: hidden;
  // background-color: black;

  .scroll-label {
    bottom: 30px;
    left: calc(20% - 5px);
    color: white;
    transform: rotate(90deg);
    border: 0;
    // background-color: blue;
    font-size: 0.9em;
    opacity: 0.8;
  }

  // .slick-view {
  //   position: fixed;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  // }

  .section-clear,
  .section-clear-add {
    height: 100%;
    // background-color: blue;
    // opacity: 0.3;
    // border-bottom: 1px solid white;
  }

  .item-overlay {
    // top: 0;
    // width: 100%;
    // height: 100%;
    // background-color: black;
    width: 100%;
    height: 100%;
    opacity: 1.0;
    display: flex;
    flex-direction: row;

    .item-overlay-section {
      // height: 100%;
      width: 20%;
      border-left: 2px solid black;
      // color: white;

      // &:first-child {
      // }

      // &:last-child {
      //   // border-right: 1px solid white;
      // }

      .item-overlay-section-inner {
        height: 100%;
        // background-color: black;
      }
    }
  }
}

</style>
