import Common from './common'
import ArrayHelper from './array'
import StringHelper from './string'
import BooleanHelper from './boolean'
// import Calculate from './calculate'
import Countries from './countries'
// import Date from './date'
import FileHelper from './file'
// import Notify from './notify'
import ObjectHelper from './object'
// import Pdf from './pdf'
// import PdfHelper from './pdfhelper'
import Url from './url'
import Json from './json'
// import Validators from './validators'
// import RequestHelper from './request'
// import RequestArray from './requestarray'
// import Parser from './parser'

export default {
  common: Common,
  array: ArrayHelper,
  string: StringHelper,
  boolean: BooleanHelper,
  // calculate: Calculate,
  countries: Countries,
  // date: Date,
  file: FileHelper,
  // notify: Notify,
  object: ObjectHelper,
  // Pdf, // a class
  // pdfHelper: PdfHelper,
  url: Url,
  json: Json,
  // validators: Validators,
  // request: RequestHelper,
  // requestarray: RequestArray,
  // parser: Parser,
}
