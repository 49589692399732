<template>
  <div id="section-trailer" ref="sectionMain" class="sections section-trailer page-wrapper">
    <div class="section-wrapper no-pad-side-mobile">
      <div class="statement flex flex-center flex-dir-col-mobile">

        <div class="margin-left-20">
          <div class="pos-relative">
            <img src="../../assets/about.png"
              data-aos="fade-down" data-aos-once="true" data-aos-offset="99999"
              :class="(isScrollMidPoint ? 'aos-animate' : '')"
              class="img-responsive"
              style="display:block;" />
            <div class="hanging overflow-wrapper" style="right:0;bottom:0;">
              <img src="../../assets/logo.png"
                data-aos="fade-up" data-aos-once="true" data-aos-offset="99999" data-aos-duration="2000" data-aos-delay="800"
                :class="(isScrollMidPoint ? 'aos-animate' : '')"
                class="img-responsive"
                style="display:block;" />
            </div>
          </div>

          <div class="about-footer overflow-wrapper">
            <div data-aos="slide-up" data-aos-once="true" data-aos-offset="99999" data-aos-delay="800"
              :class="(isScrollMidPoint ? 'aos-animate' : '')">
              <span class="inline-middle top uppercase font-roboto cwhite clause-1">
                Presented By
              </span>
              <a href="http://www.nochiefs.co/" target="_blank">
                <span class="inline-middle top gap-left uppercase cwhite clause-2">
                  No Chiefs
                </span>
              </a>
            </div>
          </div>
        </div>

        <h4 class="hanging non-hanging-mobile about bordered"
          data-aos="fade-right" data-aos-once="true" data-aos-offset="99999"
          :class="(isScrollMidPoint ? 'aos-animate' : '')">
          <div class="overflow-wrapper">
            <div data-aos="slide-up" data-aos-once="true" data-aos-offset="99999" data-aos-delay="300"
              :class="(isScrollMidPoint ? 'aos-animate' : '')">
              I Mother focuses on the impact the unexpected loss of a loved one may have on a family. The act itself may be the trigger for overwhelming grief, but long term emotional suffering can prove to be just as painful for those left behind.
            </div>
          </div>
          <div class="overflow-wrapper">
            <div data-aos="slide-up" data-aos-once="true" data-aos-offset="99999" data-aos-delay="500"
              :class="(isScrollMidPoint ? 'aos-animate' : '')">
              <span class="inline-middle uppercase gap-top-xl text-tag is-cursor" @click="onTrailer">
                Watch Trailer
              </span>
              <a class="inline-middle control-arrow gap-top-xl is-cursor" @click="onTrailer">
                →
              </a>
            </div>
            <div data-aos="slide-up" data-aos-once="true" data-aos-offset="99999" data-aos-delay="500"
              :class="(isScrollMidPoint ? 'aos-animate' : '')">
              <span class="inline-middle uppercase gap-top-xl text-tag is-cursor" @click="onTeaser">
                Watch Teaser
              </span>
              <a class="inline-middle control-arrow gap-top-xl is-cursor" @click="onTeaser">
                →
              </a>
            </div>
          </div>
        </h4>

        <h4 class="hanging about label-trailer bordered uppercase font-roboto-black">
          Trailer
        </h4>
      </div>
    </div>

    <!-- <div class="hanging line line-cross is-hidden-mobile"></div> -->

    <!-- <Drawer :direction="'right'" :exist="true" ref="drawer"
      class="bcred">
      <div class="bcmag" style="position:relative;width:100%;height:100%;background-color:white;">
        <img src="../../assets/about.png" class="img-responsive" style="width:100%;" />
        <div class="rotate-deg-90 uppercase is-cursor"
          style="position:absolute;left:-74px;top:40%;padding:10px 25px;background-color:white;"
          @click="openMenu">
          Trailer
        </div>
      </div>
    </Drawer> -->
  </div>
</template>

<script>
// import _ from 'lodash'
// import Constants from '@/constants'
// import EventBus from '@/controllers/EventBus'
// import { getToken } from '@/utils/auth'

// import DivContainer from '@/components/DivContainer.vue'

export default {
  name: 'section-trailer',
  props: ['last-position'],
  components: {
    // DivContainer,
  },
  data () {
    return {
      // loading: false,
      posY: 0,
      offsetY: 0,
      isScrollMidPoint: false,
    }
  },
  computed: {
  },
  watch: {
    lastPosition: function (value, oldValue) {
      let posY = this.$refs.sectionMain.getBoundingClientRect().y
      let height = this.$refs.sectionMain.getBoundingClientRect().height
      this.$set(this, 'posY', -posY)
      let dheight = document.documentElement.clientHeight
      let diff = (height - dheight) / 2
      diff = diff > 0 ? -diff : diff
      this.$set(this, 'offsetY', diff)
      this.timerRefresh()
    }
  },
  created () {
  },
  async mounted () {
    this.timer = setInterval(() => this.timerRefresh(), 100)
    this.timerRefresh()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    onTrailer () {
      // let src = 'https://player.vimeo.com/video/685896627?h=ba70c01db9'
      let src = 'https://player.vimeo.com/video/942561608?h=e97a3f3010'
      this.$emit('on-trailer', src)
    },

    onTeaser () {
      let src = 'https://player.vimeo.com/video/931580308?h=f38c051224'
      this.$emit('on-trailer', src)
    },

    openMenu () {
      if(this.$refs.drawer.active) {
				this.$refs.drawer.close()
			} else {
				this.$refs.drawer.open()
			}
    },

    timerRefresh () {
      let refName = 'sectionMain'
      let ref = this.$refs[refName]
      if (!ref) return false
      let rect = ref.getBoundingClientRect()
      let top = rect && rect.top
      let targetHeight = window.innerHeight * 0.4
      let isMidPoint = (top < targetHeight)
      // console.log('-- isMidPoint', ref, isMidPoint, top, targetHeight)
      this.$set(this, 'isScrollMidPoint', isMidPoint)
    },
  }
}
</script>

<style lang="scss">
@import "../../styles/base.scss";
@import "../../styles/sections.scss";

.section-trailer {

  // .hanging {
  //   clear: both;
  // }

  // $about-top: 25%;

  .margin-left-20 {
    margin-left: 20%;

    @media screen and (max-width: $mobile) {
      margin-left: 0;
    }
  }

  .overflow-wrapper {
    overflow: hidden;
  }

  .about {
    // top: $about-top;
    width: 30%;
    border: 0;
    background-color: black;
    padding: 50px 30px;
    margin-bottom: 3%;

    @media screen and (max-width: $mobile) {
      width: 100%;
      padding: 50px 20px;
    }

    @media screen and (min-width: $tablet) and (max-width: ($desktop - 1)) {
      padding: 30px 15px;
      width: 50%;
    }
  }

  .label-trailer {
    left: -56px;
    background-color: white;
    color: black !important;
    font-family: 'Roboto Black' !important;
    font-size: 1.2rem !important;
    letter-spacing: 5px;
    width: auto;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    padding: 12px 28px;
    transform: rotate(-90deg);

    @media screen and (max-width: $mobile) {
      top: 25%;
      left: -50px;
      font-size: 1.0rem !important;
    }

    @media screen and (min-width: $tablet) and (max-width: ($desktop - 1)) {
      left: -54px;
      font-size: 1.2rem !important;
    }
  }

  .about-footer {
    width: 100%;
    text-align: right;
    @media screen and (max-width: $mobile) {
      text-align: center;
    }
    margin-top: 18px;

    .clause-1 {
      letter-spacing: 2px;
      font-size: 1rem;
      font-family: $font-roboto;

      @media screen and (min-width: $tablet) and (max-width: ($desktop - 1)) {
        font-size: 0.8rem;
      }
    }

    .clause-2 {
      font-size: 1.4em;
      line-height: 1em;
      letter-spacing: 4px;
      font-family: $font-dosis;

      @media screen and (min-width: $tablet) and (max-width: ($desktop - 1)) {
        font-size: 1.2rem;
      }
    }
  }
}

</style>
