<template>
  <div class="slick-view">

    <div class="container-wrapper">
      <div v-if="loading === false" data-aos="fade-in" data-aos-once="true" style="height:100%;">
        <div class="slick-wrapper" data-aos="fade-in" data-aos-once="true" style="height:100%;">
          <!-- layer slick -->
          <slick ref="slick"
            :options="slickOptions"
            @beforeChange="onBeforeChange"
            @afterChange="onAfterChange">
            <div class="item-wrapper" :class="`type-${getItemType(item.image.path)}-wrapper`"
              v-for="(item, itemindex) in items" v-bind:key="`${itemindex}`"
              :caption="item.title"
              :href="item.image.path"
              :src="item.image.path">

              <!-- image -->
              <div v-if="getIsItemType(item.image.path, 'image')"
                class="type-image" style="overflow:hidden;"
                :style="{
                  backgroundColor: 'black',
                }">
                <div class="item-image item-image-mobile"
                  v-if="!isDesktop && item.image_mobile"
                  nopin="nopin"
                  :style="{
                    backgroundImage: `url(${item.image_mobile.path})`,
                    backgroundColor: 'black',
                    transform: `${item.isZoomIn ? 'scale(1.1)' : 'scale(1.0)'}`,
                    transformOrigin: 'center',
                    transition: `transform ${item.isZoomInTiming} ease-in-out, opacity ${item.isZoomInPreFadeTiming} ease-in-out`,
                    opacity: `${item.isZoomInPreFade ? 0.0 : 1.0}`,
                  }" />
                <div class="item-image item-image-desktop"
                  v-else
                  nopin="nopin"
                  :style="{
                    backgroundImage: `url(${item.image.path})`,
                    backgroundColor: 'black',
                    transform: `${item.isZoomIn ? 'scale(1.1)' : 'scale(1.0)'}`,
                    transformOrigin: 'center',
                    transition: `transform ${item.isZoomInTiming} ease-in-out, opacity ${item.isZoomInPreFadeTiming} ease-in-out`,
                    opacity: `${item.isZoomInPreFade ? 0.0 : 1.0}`,
                  }" />

                <!-- <audio v-if="item && item.autoplayAudio && currentSlide === parseInt(itemindex, 10)"
                  id="audio" ref="slickAudio"
                  :autoplay="true" :loop="false" :controls="false"
                  webkit-playsinline="true"
                  playsinline="true"
                  :muted="isMuted">
                  <source :src="item.autoplayAudio" type="audio/mp4">
                  Your browser does not support the audio element.
                </audio> -->
              </div>

              <!-- video -->
              <div v-else-if="getIsItemType(item.image.path, 'video')"
                class="type-video centered" :style="{ backgroundColor: item.bgColor || 'transparent' }">
                <video id="video" ref="slickVideo" class="item-video"
                  :width="item.width"
                  :height="item.height"
                  :autoplay="true" :loop="true" :controls="false"
                  webkit-playsinline="true"
                  playsinline="true"
                  :muted="true">
                  <source :src="item.image.path" type="video/mp4">
                  Your browser does not support the video tag.
                </video>
              </div>

              <div v-else class="type-unsupported">
                Unsupported file format.
              </div>

              <!-- <div class="item-slogan">
                <div class="slogan"
                  data-aos="slide-up"
                  data-aos-once="true"
                  data-aos-mirror="true"
                  data-aos-delay="0"
                  data-aos-offset="999999"
                  data-aos-duration="2000"
                  data-aos-easing="easing-custom"
                  :class="(isLabelUp ? 'aos-animate' : '')">
                  <div class="statement-wrapper">
                    <h4 v-if="item.slogan_pre"
                      class="text-slogan-pre"
                      data-aos="fade-in"
                      data-aos-once="true"
                      data-aos-mirror="true"
                      data-aos-delay="0"
                      data-aos-offset="999999"
                      data-aos-duration="1000"
                      data-aos-easing="easing-custom"
                      :class="(item.isZoomInPreFade ? '' : 'aos-animate')">
                      {{ item.slogan_pre.value }}
                    </h4>
                  </div>
                  <div class="statement-wrapper text-slogan-wrapper">
                    <h1 v-if="item.slogan"
                      class="text-slogan"
                      data-aos="fade-in"
                      data-aos-once="true"
                      data-aos-mirror="true"
                      data-aos-delay="0"
                      data-aos-offset="999999"
                      data-aos-duration="1800"
                      data-aos-easing="easing-custom"
                      :class="(item.isZoomInPreFade ? '' : 'aos-animate')">
                      {{ item.slogan.value }}
                    </h1>
                  </div>
                  <div class="statement-wrapper">
                    <h4 v-if="item.slogan_post"
                      class="text-slogan-post"
                      data-aos="fade-in"
                      data-aos-once="true"
                      data-aos-mirror="true"
                      data-aos-delay="0"
                      data-aos-offset="999999"
                      data-aos-duration="1200"
                      data-aos-easing="easing-custom"
                      :class="(item.isZoomInPreFade ? '' : 'aos-animate')">
                      {{ item.slogan_post.value }}
                    </h4>
                  </div>
                </div>
              </div> -->

            </div>
          </slick>

          <!-- layer slogan -->
          <div class="overlay-slogan">
            <div
              v-for="(slogan, sindex) in slogans"
              :key="`${sindex}`">
              <div
                v-if="(sindex === sloganIndex)"
                class="item-slogan slogan"
                data-aos="slide-up"
                data-aos-once="true"
                data-aos-mirror="true"
                data-aos-delay="0"
                data-aos-offset="999999"
                data-aos-duration="2000"
                data-aos-easing="easing-custom"
                :class="(isLabelUp ? 'aos-animate' : '')">
                <div class="statement-wrapper">
                  <img v-if="slogan.withLogo"
                    src="../assets/logo.png"
                    class="image-slogan-pre"
                    data-aos="fade-in"
                    data-aos-once="true"
                    data-aos-mirror="true"
                    data-aos-delay="0"
                    data-aos-offset="999999"
                    data-aos-duration="1000"
                    data-aos-easing="easing-custom"
                    :class="(slogan.isFade ? '' : 'aos-animate')"
                  />
                </div>
                <div class="statement-wrapper">
                  <h4 v-if="slogan.slogan_pre"
                    class="text-slogan-pre"
                    data-aos="fade-in"
                    data-aos-once="true"
                    data-aos-mirror="true"
                    data-aos-delay="0"
                    data-aos-offset="999999"
                    data-aos-duration="1000"
                    data-aos-easing="easing-custom"
                    :class="(slogan.isFade ? '' : 'aos-animate')">
                    {{ slogan.slogan_pre.value }}
                  </h4>
                </div>
                <div class="statement-wrapper text-slogan-wrapper">
                  <h1 v-if="slogan.slogan"
                    class="text-slogan"
                    data-aos="fade-in"
                    data-aos-once="true"
                    data-aos-mirror="true"
                    data-aos-delay="0"
                    data-aos-offset="999999"
                    data-aos-duration="1800"
                    data-aos-easing="easing-custom"
                    :class="(slogan.isFade ? '' : 'aos-animate')">
                    {{ slogan.slogan.value }}
                  </h1>
                </div>
                <div class="statement-wrapper">
                  <h4 v-if="slogan.slogan_post"
                    class="text-slogan-post"
                    data-aos="fade-in"
                    data-aos-once="true"
                    data-aos-mirror="true"
                    data-aos-delay="0"
                    data-aos-offset="999999"
                    data-aos-duration="1200"
                    data-aos-easing="easing-custom"
                    :class="(slogan.isFade ? '' : 'aos-animate')">
                    {{ slogan.slogan_post.value }}
                  </h4>
                </div>
              </div>
            </div>
          </div><!-- end of item-slogan -->

          <!-- <slick
            ref="slick"
            :options="slickOptions">
            <a href="http://placehold.it/2000x1000"><img src="http://placehold.it/2000x1000" alt=""></a>
            <a href="http://placehold.it/2000x1000"><img src="http://placehold.it/2000x1000" alt=""></a>
            <a href="http://placehold.it/2000x1000"><img src="http://placehold.it/2000x1000" alt=""></a>
            <a href="http://placehold.it/2000x1000"><img src="http://placehold.it/2000x1000" alt=""></a>
            <a href="http://placehold.it/2000x1000"><img src="http://placehold.it/2000x1000" alt=""></a>
          </slick> -->

          <!-- <VueSlickCarousel :arrows="true" :dots="true">
            <div>1</div>
            <div>2</div>
            <div>3</div>
            <div>4</div>
          </VueSlickCarousel> -->
        </div><!-- end slick-wrapper -->
      </div>

      <div class="item-overlay-wrapper">
        <div class="item-overlay">
          <div class="item-overlay-section">
            <div class="item-overlay-section-inner"
              :data-aos="isScrollType" data-aos-once="true" data-aos-mirror="true"
              data-aos-offset="999999" data-aos-delay="0" :data-aos-duration="isScrollDuration"
              data-aos-easing="easing-custom"
              :class="(isScrollMidPoint1 ? 'aos-animate' : '')">
            </div>
          </div>
          <div class="item-overlay-section">
            <div class="item-overlay-section-inner"
              :data-aos="isScrollType" data-aos-once="true" data-aos-mirror="true"
              data-aos-offset="999999" data-aos-delay="0" :data-aos-duration="isScrollDuration"
              data-aos-easing="easing-custom"
              :class="(isScrollMidPoint2 ? 'aos-animate' : '')">
            </div>
          </div>
          <div class="item-overlay-section">
            <div class="item-overlay-section-inner"
              :data-aos="isScrollType" data-aos-once="true" data-aos-mirror="true"
              data-aos-offset="999999" data-aos-delay="0" :data-aos-duration="isScrollDuration"
              data-aos-easing="easing-custom"
              :class="(isScrollMidPoint3 ? 'aos-animate' : '')">
            </div>
          </div>
          <div class="item-overlay-section">
            <div class="item-overlay-section-inner"
              :data-aos="isScrollType" data-aos-once="true" data-aos-mirror="true"
              data-aos-offset="999999" data-aos-delay="0" :data-aos-duration="isScrollDuration"
              data-aos-easing="easing-custom"
              :class="(isScrollMidPoint4 ? 'aos-animate' : '')">
            </div>
          </div>
          <div class="item-overlay-section">
            <div class="item-overlay-section-inner"
              :data-aos="isScrollType" data-aos-once="true" data-aos-mirror="true"
              data-aos-offset="999999" data-aos-delay="0" :data-aos-duration="isScrollDuration"
              data-aos-easing="easing-custom"
              :class="(isScrollMidPoint5 ? 'aos-animate' : '')">
            </div>
          </div>
        </div><!-- end item-overlay -->
      </div><!-- end item-overlay-wrapper -->
    </div>

    <!-- <div class="audio-mute-wrapper">
      <div class="audio-mute is-cursor" @click="onToggleMute">
        {{ isMute ? '🔈' : '🔊' }}
      </div>
    </div> -->

    <slot></slot>
  </div>
</template>

<script>
// import EventBus from '@/controllers/EventBus'
// import Site from '@/apis/Site'
import Util from '@/utils'

// import DivContainer from '@/components/DivContainer'
import Slick from 'vue-slick'
// import Lightbox from 'vue-lightbox'
// import VueSlickCarousel from 'vue-slick-carousel'
// import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import Image1 from '@/assets/main_bg1.jpg'
import Image2 from '@/assets/main_bg2.jpg'
import Image3 from '@/assets/main_bg3.jpg'
import Image1m from '@/assets/main_bg1m.jpg'
import Image2m from '@/assets/main_bg2m.jpg'
import Image3m from '@/assets/main_bg3m.jpg'
// import Video1 from '@/assets/sample.mp4'
import Audio1 from '@/assets/audio_marcia.mp4'
import Audio2 from '@/assets/audio_neicey.mp4'
import Audio3 from '@/assets/audio_mrsallen.mp4'

export default {
  name: 'slick-view',
  components: {
    // DivContainer,
    Slick,
    // VueSlickCarousel,
    // Lightbox
  },
  props: ['is-desktop', 'is-muted'],
  data () {
    const AUTOPLAY_INTERVAL = 8
    const PRE_FADE_INTERVAL = 2
    const slogan = {
      withLogo: false,
      slogan_pre: {
        value: 'Partus Sequitur Ventrem',
      },
      slogan: {
        value: 'THAT WHICH IS BROUGHT FORTH FOLLOWS THE WOMB',
      },
      slogan_post: {
        value: '- Virginia\'s House of Brugesses, 1662',
      },
    }
    const sloganNext = {
      withLogo: true,
      slogan_pre: {
        value: 'How a senseless moment can lead to a lifetime of pain',
      },
      slogan: {
        value: undefined,
      },
      slogan_post: {
        value: undefined,
      },
    }
    return {
      // isDesktop: true,
      // slickOptions: {
      //   slidesToShow: 3,
      // },
      isAnimate: false,
      currentSlide: 0,
      slickOptions: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        fade: false,
        dots: false, // true
        speed: 0,
        swipe: false,
        swipeToSlide: false,
        touchMove: false,
        autoplay: true,
        autoplaySpeed: AUTOPLAY_INTERVAL * 1000,
        pauseOnHover: false,
        pauseOnFocus: false,
        pauseOnDotsHover: false,
        focusOnSelect: false,
        responsive: [
          {
            breakpoint: 1008,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          }, {
            // 768 = tablet (not break yet)
            breakpoint: 769,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
              dots: false
            }
          }, {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
              dots: false
            }
          }
        ]
      },
      defaultAutoplayInterval: AUTOPLAY_INTERVAL,
      defaultPreFadeInterval: PRE_FADE_INTERVAL,
      items: [{
        title: 'Image01',
        bgColor: '#000',
        image: {
          path: Image1
        },
        image_mobile: {
          path: Image1m
        },
        isZoomIn: false,
        isZoomInTiming: `${AUTOPLAY_INTERVAL}s`,
        isZoomInPreFade: true,
        isZoomInPreFadeTiming: `${PRE_FADE_INTERVAL}s`,
        autoplayInterval: AUTOPLAY_INTERVAL,
        autoplayAudio: Audio1,
        // ...slogan,
      }, {
        title: 'Image02',
        bgColor: '#000',
        image: {
          path: Image2
        },
        image_mobile: {
          path: Image2m
        },
        isZoomIn: false,
        isZoomInTiming: '0s',
        isZoomInPreFade: true,
        isZoomInPreFadeTiming: `${PRE_FADE_INTERVAL}s`,
        autoplayInterval: AUTOPLAY_INTERVAL,
        autoplayAudio: Audio2,
        // ...sloganNext,
      }, {
        title: 'Image03',
        bgColor: '#000',
        image: {
          path: Image3
        },
        image_mobile: {
          path: Image3m
        },
        isZoomIn: false,
        isZoomInTiming: '0s',
        isZoomInPreFade: true,
        isZoomInPreFadeTiming: `${PRE_FADE_INTERVAL}s`,
        autoplayInterval: AUTOPLAY_INTERVAL,
        autoplayAudio: Audio3,
        // ...sloganNext,
      }],
      slogans: [
        {
          ...slogan,
          isFade: true,
          isFadeTiming: `${PRE_FADE_INTERVAL}s`,
          autoplayInterval: AUTOPLAY_INTERVAL * 3 / 2,
        },
        {
          ...sloganNext,
          isFade: true,
          isFadeTiming: `${PRE_FADE_INTERVAL}s`,
          autoplayInterval: AUTOPLAY_INTERVAL * 3 / 2,
        },
      ],
      sloganIndex: 0,
      sloganFadeTimer: undefined,
      sloganTimer: undefined,
      // sloganIsFirst: true,
      itemsEnded: false,
      error: undefined,
      loading: false,
      scrollPos: 0,
      isLabelUp: false,
      isScrollMidPoint: false,
      isScrollMidPoint1: false,
      isScrollMidPoint2: false,
      isScrollMidPoint3: false,
      isScrollMidPoint4: false,
      isScrollMidPoint5: false,
      isScrollType: 'slide-up',
      isScrollDuration: 100,
      scrollLock: false,
      scrollQueue: [],
    }
  },
  computed: {
    // isDesktop: function () {
    //   return EventBus.isDesktop
    // }

    Util: function () {
      return Util
    },
  },
  watch: {
  },
  created () {
    // console.log('created TopSection.vue')
  },
  mounted () {
    // console.log('mounted TopSection.vue')
    // window.GA('send', 'pageview')

    // on event resize
    // this.isDesktop = EventBus._isDesktop
    // EventBus.$on('isDesktopChanged', function () {
    //   this.isDesktop = EventBus._isDesktop
    // }.bind(this))

    this.refresh()
  },
  methods: {
    onScrollTo: function (name) {
      this.$scrollTo('#' + name)
    }.bind(this),

    // introductory

    onLabelUp () {
      // console.log('slick: on label up')
      setTimeout(() => this.$set(this, 'isLabelUp', true), 0)
    },

    onLabelDown () {
      // console.log('slick: on label down')
      setTimeout(() => this.$set(this, 'isLabelUp', false), 0)
    },

    onSlideUp () {
      // console.log('slick: on slide up')
      if (this.scrollLock) {
        this.scrollQueue.push(true)
        return
      }
      this.$set(this, 'scrollLock', true)
      this.$set(this, 'isScrollDuration', 1200)
      setTimeout(() => this.$set(this, 'isScrollMidPoint1', true), 0)
      setTimeout(() => this.$set(this, 'isScrollMidPoint2', true), 200)
      setTimeout(() => this.$set(this, 'isScrollMidPoint3', true), 400)
      setTimeout(() => this.$set(this, 'isScrollMidPoint4', true), 600)
      setTimeout(() => {
        this.$set(this, 'isScrollMidPoint5', true)
        this.$nextTick(() => {
          this.$set(this, 'scrollLock', false)
          if (this.scrollQueue.length > 0) {
            let isSlideUpDown = this.scrollQueue.pop()
            this.$set(this, 'scrollQueue', [])
            isSlideUpDown ? this.onSlideUp() : this.onSlideDown()
          }
        })
        this.onAudioMute(true)
      }, 800)
    },

    onSlideDown () {
      // console.log('slick: on slide down')
      if (this.scrollLock) {
        this.scrollQueue.push(false)
        return
      }
      this.$set(this, 'scrollLock', true)
      this.$set(this, 'isScrollDuration', 1200)
      setTimeout(() => this.$set(this, 'isScrollMidPoint5', false), 0)
      setTimeout(() => this.$set(this, 'isScrollMidPoint4', false), 200)
      setTimeout(() => this.$set(this, 'isScrollMidPoint3', false), 400)
      setTimeout(() => this.$set(this, 'isScrollMidPoint2', false), 600)
      setTimeout(() => {
        this.$set(this, 'isScrollMidPoint1', false)
        this.$nextTick(() => {
          this.$set(this, 'scrollLock', false)
          if (this.scrollQueue.length > 0) {
            let isSlideUpDown = this.scrollQueue.pop()
            this.$set(this, 'scrollQueue', [])
            isSlideUpDown ? this.onSlideUp() : this.onSlideDown()
          }
          this.onAudioMute(this.isMuted)
        })
      }, 800)
    },

    onAudioMute (isMuted) {
      // for (let i in this.items) {
      //   if (!this.$refs.slickAudio) continue
      //   let ref = this.$refs.slickAudio[i]
      //   if (ref) {
      //     ref.muted = isMuted
      //   }
      // }
    },

    onToggleMute () {
      let isMuted = this.isMuted
      console.log('slick: set mute', isMuted)
      // this.$emit(this, 'on-toggle-mute')
      this.onAudioMute(isMuted)
    },

    // slides

    onPrepareAudio (currentSlide) {
      // if (this.$refs.slickAudio && this.$refs.slickAudio[currentSlide]) {
      //   let audio = this.$refs.slickAudio[currentSlide]
      //   audio.play()
      // }
    },

    onPrepareNext (currentSlide) {
      // let TOTAL_ITEMS = this.items.length
      // let nextSlide = (currentSlide + 1)
      // nextSlide = nextSlide >= TOTAL_ITEMS ? 0 : nextSlide
      let item = this.items[currentSlide]
      let autoplayInterval = (item && item.autoplayInterval) || this.defaultAutoplayInterval
      // console.log(`slick: onPrepareNext after: ${autoplayInterval}s`)
      // setTimeout(() => {
      //   this.next()
      // }, autoplayInterval * 1000)
      return autoplayInterval
    },

    onBeforeChange(event, slick, currentSlide) {
      // console.log('slick: onBeforeChange', currentSlide)
      if (this.$refs.slickVideo && this.$refs.slickVideo[currentSlide]) {
        let video = this.$refs.slickVideo[currentSlide]
        video.pause()
      }
      // for (let i in this.items) {
      //   let item = this.items[i]
      //   let isZoomIn = (parseInt(i, 10) === currentSlide)
      //   this.$set(item, 'isZoomIn', false)
      //   this.$set(item, 'isZoomInTiming', '0s')
      // }
      // preFade true = dim, false = show
      let TOTAL_ITEMS = this.items.length
      for (let i in this.items) {
        let item = this.items[i]
        let isCurrent = (parseInt(i, 10) === currentSlide)
        let nextSlide = (currentSlide + 1)
        nextSlide = nextSlide >= TOTAL_ITEMS ? 0 : nextSlide
        let isNext = (parseInt(i, 10) === nextSlide)
        // console.log(`slick: before change pre fade: ${i}`, { isCurrent, isNext, currentSlide, nextSlide, fade: false })
        // if (isCurrent) continue
        // this.$set(item, 'isZoomInPreFadeTiming', '0.1s')
        // let isFade = !isNext
        let preFadeInterval = this.defaultPreFadeInterval
        this.$set(item, 'isZoomInPreFadeTiming', `${preFadeInterval}s`)
        this.$set(item, 'isZoomInPreFade', !isNext)
      }
    },

    onAfterChange(event, slick, currentSlide) {
      // console.log('slick: onAfterChange', currentSlide)
      this.$set(this, 'currentSlide', currentSlide)

      if (this.$refs.slickVideo && this.$refs.slickVideo[currentSlide]) {
        let video = this.$refs.slickVideo[currentSlide]
        video.play()
      }

      this.onPrepareAudio(currentSlide)
      let autoplayInterval = this.onPrepareNext(currentSlide)
      let preFadeInterval = this.defaultPreFadeInterval
      let preFadeStart = autoplayInterval - preFadeInterval
      // console.log('===')
      for (let i in this.items) {
        let item = this.items[i]
        let isCurrent = (parseInt(i, 10) === currentSlide)
        // console.log('zoom in ' + i, isCurrent)
        this.$set(item, 'isZoomIn', isCurrent)
        this.$set(item, 'isZoomInTiming', isCurrent ? `${autoplayInterval}s` : '1s')
      }
      // console.log('slick: after change pre fade wait:', preFadeStart)
      setTimeout(() => {
        for (let i in this.items) {
          let item = this.items[i]
          let isCurrent = (parseInt(i, 10) === currentSlide)
          // console.log(`slick: after change pre fade: ${i}`, { fade: isCurrent, currentSlide })
          // console.log('zoom in ' + i, isCurrent)
          this.$set(item, 'isZoomInPreFadeTiming', `${preFadeInterval}s`)
          this.$set(item, 'isZoomInPreFade', true) // all prefade
          // this.$set(item, 'isZoomInPreFadeTiming', isCurrent ? '2s' : '2s')
        }
      }, preFadeStart * 1000)
    },

    onSloganFade () {
      const defaultInterval = this.slogans[0].autoplayInterval
      const fadeInterval = this.defaultPreFadeInterval
      let fadeStart = defaultInterval - fadeInterval
      setTimeout(() => {
        for (let s in this.slogans) {
          let slogan = this.slogans[s]
          this.$set(slogan, 'isFade', true)
        }
      }, fadeStart * 1000)
    },

    // slicks

    next () {
      if (this.$refs.slick) {
        this.$refs.slick.next()
      }
    },

    prev () {
      if (this.$refs.slick) {
        this.$refs.slick.prev()
      }
    },

    reInit () {
      console.log('slick: reInit')
      if (this.$refs.slick) {
        console.log('slick: reInit yes')
        this.$refs.slick.reSlick()
      }
    },

    // init/internals

    refresh: function () {
      this.$set(this, 'isLabelUp', true)

      // initial zoom
      this.$set(this.items[0], 'isZoomIn', true)

      // initial pre fade
      this.$set(this.items[0], 'isZoomInPreFade', false)

      // slick
      let currentSlide = 0
      this.onPrepareAudio(currentSlide)
      let autoplayInterval = this.onPrepareNext(currentSlide)
      let preFadeInterval = this.defaultPreFadeInterval
      let preFadeStart = autoplayInterval - preFadeInterval
      setTimeout(() => {
        this.$set(this.items[0], 'isZoomInPreFade', true)
      }, preFadeStart * 1000)

      // slogan (curr)
      this.$set(this.slogans[0], 'isFade', false)
      this.onSloganFade()

      // [0]   { 0 = fade false, 1 = fade true  } = show 0
      // [0:b] { 0 = fade true , 1 = fade true  } = fade 0
      // [1]   { 0 = fade true , 1 = fade false } = show 1
      // [1:b] { 0 = fade true , 1 = fade true  } = fade 1
      // repeat

      const defaultInterval = this.slogans[0].autoplayInterval
      const fadeInterval = this.defaultPreFadeInterval
      setInterval(() => {
        let curr = this.sloganIndex
        let next = (curr + 1)
        next = (next >= this.slogans.length) ? 0 : next
        // console.log('slick: slogan interval', { curr, next })

        this.onSloganFade()

        this.$set(this, 'sloganIndex', next)
        this.$nextTick(() => {
          for (let s in this.slogans) {
            let slogan = this.slogans[s]
            let isNext = (parseInt(s, 10) === next)
            this.$set(slogan, 'isFade', !isNext)
          }
        })

      }, defaultInterval * 1000)
    },

    getItemType: function (path) {
      let ext = Util.url.getPathExt(path)
      if (!ext) return

      let mime = Util.url.getMime(ext)
      if (!mime) return

      mime = mime.split('/')
      return mime && mime.length > 0 && mime[0]
    },

    getIsItemType: function (path, type) {
      let ext = Util.url.getPathExt(path)
      if (!ext) return

      let mime = Util.url.getMime(ext)
      if (!mime) return
      return mime.includes(type)
    },

    renderYtSubscribeButton: function (channel) {
      /* global gapi */
      var container = document.getElementById('yt-button-container-render')
      var options = {
        'channel': channel,
        'layout': 'full'
      }
      gapi.ytsubscribe.render(container, options)
    }
  }
}
</script>

<style lang="scss">
@import "../styles/app_setting.scss";
@import "../../node_modules/slick-carousel/slick/slick.css";
@import "../styles/slick.scss";

.slick-view {
  $top-section-height-wrap: 100%;
  $top-section-height: 100%;

  height: $top-section-height-wrap; // noc: full height
  position: relative;

  &.not-landing {
    height: auto; // noc: zero height
  }

  .container-wrapper {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    max-height: $top-section-height; // noc: full height
    height: $top-section-height; // noc: full height
    background-color: black;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .section-indicator {
    position: fixed;
    left: 0px;
    top: 50%;

    span {
      display: block;
      clear: both;
      overflow: hidden;
      width: 9px;
      height: 9px;
      border-radius: 5px;
      background-color: $color-white;
      margin: 10px;
      border: 1px solid $color-gray;

      &.is-active {
        background-color: $color-gray;
      }
    }
  }

  // slick

  .slick-wrapper-outer {
    height: $top-section-height;
    overflow-x: visible;
    overflow-y: visible;
    position: relative;

    img {
      height: $top-section-height;
      width: auto;
      max-height: 100%;
      max-width: 500%;
      position: absolute;
      // z-index: 1;
      bottom: 10%;
      left: -2%;

      // landscape mobile
      @media screen and (max-width: $mobile) and (orientation: landscape) {
        height: auto;
        width: 100%;
        max-height: 400%;
        max-width: 100%;
        left: 20%;
        bottom: 0%;
      }

      @media screen and (min-width: $tablet) {
        left: 8%;
        bottom: 0%;
      }
    }
  }

  .slick-wrapper {
    height: $top-section-height !important; // noc: full height

    .slick {
      height: $top-section-height; // noc: full height
    }

    .slick-slider {
      width: 100%;
      height: $top-section-height;

      .slick-track, .slick-list {
        width: 100%;
        height: $top-section-height;
      }
    }

    .slick-initialized {
      .slick-slide {
        width: 100%;
        height: $top-section-height !important;

        & > div {
          // width: 100%;
          height: $top-section-height !important;

          div.item-wrapper {
            width: 100%;
            height: $top-section-height !important;
            position: relative;
            background-color: $color-gray;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            overflow-y: hidden;

            .type-image,
            .type-video {
              height: $top-section-height !important;
            }

            img, .item-image {
              margin: auto;

              &.item-image-desktop,
              &.item-image-mobile {
                width: 100%; // noc: full width
                height: 100%; // noc: full height
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
              }
            }

            &.type-video-wrapper {
              overflow: hidden;
              position: relative;
            }

            .type-video {
              // overflow: hidden;
              // height: 100%;
              // width: 280vh; /* 100 * 16 / 9 */
              // min-width: 100%;
              // min-height: 62.5vw; /* 100 * 9 / 16 */

              // &.centered {
              //   position: absolute;
              //   left: 50%; /* % of surrounding element */
              //   top: 0;
              //   transform: translate(-50%, 0); /* % of current element */
              // }

              // method 2
              // position: absolute;
              // height: 100%;
              // width: auto;
              // overflow: hidden;

              // video {
              //   min-height: 100%;
              //   object-fit: contain;
              //   min-width: 177%;
              //   transform: translate(-25%, -17%);
              // }

              // method 3
              display: flex;
              align-items: stretch;
              justify-content: center;
              max-height: 100%;
              height: 100% !important;
              width: 100%;
              overflow: hidden;
              margin: 0 auto;

              video {
                display: block;
                object-fit: cover;
                object-position: center;
                width: 100%;
              }
            }

            // .item-video {
            // }
          } // end item wrapper
        }
      }
    }

    div.overlay-slogan {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      // background-color: rgba(255,255,255,0.2);
      // display: flex;
      // justify-content: flex-start;
      // align-items: center;
    }

    div.item-slogan {
      width: 60%;
      position: absolute;
      background-color: transparent;
      // background-color: rgba(255,255,255,0.2);
      top: 38%;
      left: 5%;
      text-align: left;

      @media screen and (min-width: $desktop) {
        left: 10%;
        width: 50%;
      }

      &.slogan {
        margin: 0;
        padding: 0;
        padding-left: 5px;
        padding-right: 5px;

        @media screen and (min-width: $desktop) {
          padding-left: 50px;
          padding-right: 50px;
        }

        .statement-wrapper {
          overflow: hidden;
        }

        h1 {
          color: white;
          font-family: "Proxima Nova", arial, sans-serif;
          font-weight: bold;
          // line-height: 1.3em;
          // border-bottom: 4px solid white;
          // text-decoration: underline;
          font-size: 2.0em;

          @media screen and (min-width: $desktop) {
            font-size: 2.5em;
          }
        }

        h4 {
          color: white;
          font-family: "Roboto Mono", arial, sans-serif;
          font-weight: normal;
          font-size: 0.8em;

          @media screen and (min-width: $desktop) {
            font-size: 1.2em;
          }
        }

        .image-slogan-pre {
          width: 80%;
          max-width: 280px;
          height: auto;

          @media screen and (min-width: $desktop) {
            width: 40%;
            max-width: 280px;
          }
        }

        // .text-slogan-pre {

        // }

        .text-slogan-wrapper {
          text-transform: uppercase;
          margin-top: 12px;
          margin-bottom: 12px;
        }

        // .text-slogan-post {

        // }
      }

      // img {
      //   margin-left: 0;
      //   width: 50%;
      //
      //   @media screen and (min-width: $desktop) {
      //     width: 50%;
      //   }
      // }
    }
  } // end slick-wrapper

  .item-overlay-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    .item-overlay {
      // top: 0;
      // width: 100%;
      // height: 100%;
      // background-color: black;
      height: 100%;
      opacity: 1.0;
      display: flex;
      flex-direction: row;

      .item-overlay-section {
        // height: 100%;
        width: 20%;
        // border-left: 2px solid black;
        // color: white;

        // &:first-child {

        // }

        // &:last-child {
        //   // border-right: 1px solid white;
        // }

        .item-overlay-section-inner {
          height: 100%;
          background-color: black;
        }
      }
    }
  }

  .audio-mute-wrapper {
    position: fixed;
    left: 30px;
    bottom: 30px;
    overflow: hidden;
    .audio-mute {
      z-index: 99999;
      width: 50px;
      height: 50px;
      background-color: transparent;
      color: black;
      border-radius: 25px;
      padding: 12px;
    }
  }
}

</style>
