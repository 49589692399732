<template>
  <div id="section-film" ref="sectionMain" class="sections section-film page-wrapper">
    <div class="section-wrapper">
      <div class="statement margin-left-20 pad-30 div-80"
        ref="statement1">
        <h1 class="uppercase cwhite scroll-label-clause-0 is-hidden-tablet"
          data-aos="fade-down" data-aos-once="true" data-aos-offset="999999" data-aos-delay="100"
          :class="(getRefInfo('statement1').isMidPoint ? 'aos-animate' : '')">
          No Words She Can Use To Say Goodbye
        </h1>
      </div>
      <div class="statement margin-left-20 pad-30 div-80"
        ref="statement2">
        <h2 class="roboto uppercase cwhite"
          data-aos="fade-down" data-aos-once="true" data-aos-offset="999999" data-aos-delay="100"
          :class="(getRefInfo('statement2').isMidPoint ? 'aos-animate' : '')"
          style="margin-bottom:12px;">
          The Film
        </h2>
      </div>
      <div class="statement statement-content margin-left-20 pad-30"
        ref="statement3">
        <h4 class=""
           data-aos="fade-down" data-aos-once="true" data-aos-offset="999999" data-aos-delay="300"
           :class="(getRefInfo('statement3').isMidPoint ? 'aos-animate' : '')">
           I Mother is a film that blends both scripted narrative and documentary styles to form what is termed an essay piece. Mother, the single character throughout, is an omnipresent figure who exists as if outside of the material world. She embarks on a journey walking the earth charged with a spiritual task, that being to collect the souls of young men who have fallen victim to a premature death at the hands of another. Her search is guided by a series of polaroid pictures she encounters, images of victims who occupy a middle world in between life and what follows, as they attempt to bid farewell to their loved ones.
          <br /><br />
          This fictional story path is punctuated by interviews with mothers who wrestle with the pain of losing a child through a sudden act of violence. They are joined by younger mothers, who speak of their fears for their own sons, in a world where young black men all too often fall victim to negative real-world consequences.
        </h4>
      </div>
      <div class="statement margin-left-20 pad-30">
        <div>
          <div class="section-footer"
            data-aos="fade-down" data-aos-once="true" data-aos-offset="999999" data-aos-delay="500"
            :class="(getRefInfo('statement3').isMidPoint ? 'aos-animate' : '')"
            style="text-align:left;margin-top:15px;">
            <span class="inline-middle cwhite uppercase text-tag is-cursor" @click="onCast">Cast &amp; Crew</span>
            <div class="inline-middle control-arrow is-cursor" @click="onCast">
              →
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="hanging line line-cross is-hidden-mobile"></div> -->
    <div class="hanging uppercase wauto cwhite scroll-label-clause-1 is-hidden-mobile">
      <div style="overflow:hidden">
        <h1
          data-aos="slide-up" data-aos-once="true" data-aos-offset="999999" data-aos-delay="100"
          :class="(getRefInfo('statement3').isMidPoint ? 'aos-animate' : '')">
        No Words She Can
      </h1>
      </div>
    </div>
    <div class="hanging uppercase wauto cwhite scroll-label-clause-2 is-hidden-mobile">
      <div style="overflow:hidden">
        <h1
          data-aos="slide-down" data-aos-once="true" data-aos-offset="999999" data-aos-delay="500"
          :class="(getRefInfo('statement3').isMidPoint ? 'aos-animate' : '')">
          Use To Say Goodbye
      </h1>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
// import Constants from '@/constants'
// import EventBus from '@/controllers/EventBus'
// import { getToken } from '@/utils/auth'

// import DivContainer from '@/components/DivContainer.vue'

export default {
  name: 'section-film',
  props: ['last-position'],
  components: {
    // DivContainer,
  },
  data () {
    return {
      // loading: false,
      posY: 0,
      offsetY: 0,
      timer: undefined,
      refs: [
        { name: 'statement1', isMidPoint: false, },
        { name: 'statement2', isMidPoint: false, },
        { name: 'statement3', isMidPoint: false, },
      ],
    }
  },
  computed: {
  },
  watch: {
    // lastPosition: function (value, oldValue) {
    //   let posY = this.$refs.sectionMain.getBoundingClientRect().y
    //   let height = this.$refs.sectionMain.getBoundingClientRect().height
    //   this.$set(this, 'posY', -posY)
    //   let dheight = document.documentElement.clientHeight
    //   let diff = (height - dheight) / 2
    //   diff = diff > 0 ? -diff : diff
    //   this.$set(this, 'offsetY', diff)
    // }
  },
  created () {
  },
  async mounted () {
    this.timer = setInterval(() => this.timerRefresh(), 100)
    this.timerRefresh()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    onCast () {
      this.$emit('on-cast')
    },

    timerRefresh () {
      for (let r in this.refs) {
        let refInfo = this.refs[r]
        let refName = refInfo.name
        this.setIsMidPoint(refName)
      }
    },

    isMidPoint (refName) {
      let ref = this.$refs[refName]
      if (!ref) return false
      let rect = ref.getBoundingClientRect()
      let top = rect && rect.top
      let targetHeight = window.innerHeight * 0.78
      let isMidPoint = (top < targetHeight)
      return isMidPoint
    },

    setIsMidPoint (refName) {
      let refInfo = this.getRefInfo(refName)
      let isMidPoint = this.isMidPoint(refName)
      this.$set(refInfo, 'isMidPoint', isMidPoint)
    },

    getRefInfo (refName) {
      let found = _.find(this.refs, (r) => r.name === refName)
      return found || {}
    },
  }
}
</script>

<style lang="scss">
@import "../../styles/base.scss";
@import "../../styles/sections.scss";

.section-film {
  .margin-left-20 {
    margin-left: 20%;
    @media screen and (max-width: $mobile) {
      margin-left: 0;
    }
  }

  .div-80 {
    width: 80%;
    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }

  .pad-30 {
    padding-left: 30px;
    padding-right: 30px;
    @media screen and (max-width: $mobile) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .statement-content {
    width: 60%;
    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }

  .scroll-label-clause-1 {
    font-family: 'Proxima Nova', arial, sans-serif;
  }

  .scroll-label-clause-1 {
    left: calc(20% - 146px);
    top: 40%;
    transform: rotate(90deg);
    border: 0;
    width: auto;
    h1 {
      font-family: 'Proxima Nova', arial, sans-serif;
      font-weight: bold;
      font-size: 2.1rem;
    }
  }

  .scroll-label-clause-2 {
    left: calc(20% - 204px);
    top: 60%;
    transform: rotate(90deg);
    border: 0;
    width: auto;
    h1 {
      font-family: 'Proxima Nova', arial, sans-serif;
      font-weight: bold;
      font-size: 2.1rem;
    }
  }
}

</style>
