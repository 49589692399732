import _ from 'lodash'

class ObjectHelper {
  static isValidateError (error) {
    if (error === false) return true // is validation error
    if (error instanceof Error) return false // not thrown error
    if (typeof error === 'object' && // is new format of validation error
      !Array.isArray(error) &&
      !_.isEmpty(error)) {
      return true
    }
    return false
  }

  static keys (dotkey) {
    let keys = dotkey.split('.')
    let keyFirst = dotkey.split('.')
    let keyLast = keyFirst.pop()
    keyFirst = keyFirst.join('.')
    return {
      keys,
      keyFirst,
      keyLast
    }
  }

  static get (object, dotkey) {
    const getProp = (obj, prop) => {
      return prop.split('.').reduce((r, e) => {
        if (r === undefined || r === null) {
          return undefined
        }
        return r[e]
      }, obj)
    }
    return getProp(object, dotkey)
  }

  static excludeKeys (object, excludeKeys) {
    for (let k in excludeKeys) {
      let key = excludeKeys[k]
      if (object.hasOwnProperty(key)) {
        delete object[key]
      }
    }
    return object
  }
}

export default ObjectHelper
