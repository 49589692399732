var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"sectionMain",staticClass:"sections section-photo page-wrapper pos-relative",attrs:{"id":"section-photo"}},[_c('div',{staticClass:"section-wrapper full"},[_c('div',{staticClass:"section-inner-wrapper",staticStyle:{"width":"100%"}},_vm._l(([0, 1]),function(row,rindex){return _c('div',{key:`row${rindex}`,ref:`sec-row-${rindex}`,refInFor:true,staticClass:"sec-wrapper flex",staticStyle:{"overflow":"hidden"}},_vm._l(([0, 1]),function(col,cindex){return _c('div',{key:`row${rindex}col${cindex}`,class:_vm.audios[(row * 2) + col].classes,on:{"click":function($event){_vm.onAudioPlay((row * 2) + col)}}},[_c('div',{ref:`sec-${(row * 2) + col}`,refInFor:true,staticClass:"photo-wrapper pos-relative",class:(_vm.getRefInfo(`sec-row-${rindex}`, true).isMidPoint ? 'aos-animate' : ''),attrs:{"data-aos":"slide-up","data-aos-once":"true","data-aos-mirror":"true","data-aos-delay":200 * ((row * 2) + col),"data-aos-offset":"999999","data-aos-duration":"1200","data-aos-easing":"easing-custom"}},[_c('img',{staticClass:"img-responsive",style:({
                transform: _vm.safeTransform(`sec-row-${rindex}`, _vm.posY, 0, _vm.audios[(row * 2) + col].speedY, (_vm.posY / _vm.height) + 0.2, _vm.audios[(row * 2) + col].speedScale),
                transformOrigin: 'center',
                transition: 'transform .8s ease-in-out',
              }),attrs:{"src":_vm.audios[(row * 2) + col].img}}),_c('div',{staticClass:"audio-wave-wrapper",style:({
                opacity: (_vm.audios[(row * 2) + col].isPlaying ? 0.4 : 0.0)
              })},[(_vm.audios[(row * 2) + col].isPlaying)?_c('my-av-line',{ref:`audioLine${(row * 2) + col}`,refInFor:true,staticStyle:{"margin":"auto"},attrs:{"audio-controls":false,"ref-link":`audioPlayer${(row * 2) + col}`,"caps-color":"transparent","canv-width":600,"canv-height":160,"line-color":'#fff',"line-width":0.5,"canv-fill-color":"transparent"},on:{"playing":function($event){_vm.onAudioPlaying((row * 2) + col)},"paused":function($event){_vm.onAudioPaused((row * 2) + col)},"ended":function($event){_vm.onAudioEnded((row * 2) + col)}}}):_vm._e()],1),_c('div',{staticClass:"audio-wave-wrapper"},[(_vm.audios[(row * 2) + col].isPlaying)?_c('audio',{ref:`audioPlayer${(row * 2) + col}`,refInFor:true,attrs:{"id":"audio","autoplay":false,"loop":false,"controls":false,"webkit-playsinline":"true","playsinline":"true","muted":false,"preload":"auto"}},[_c('source',{attrs:{"src":_vm.audios[(row * 2) + col].mp4,"type":"audio/mp4"}}),_c('source',{attrs:{"src":_vm.audios[(row * 2) + col].mp3,"type":"audio/mp3"}}),_c('source',{attrs:{"src":_vm.audios[(row * 2) + col].ogg,"type":"audio/ogg"}}),_vm._v(" Your browser does not support the audio element. ")]):_vm._e()]),_c('div',{staticClass:"audio-hover"},[_c('a',{staticClass:"fa",class:_vm.audios[(row * 2) + col].isPaused ? 'fa-play' :
                        _vm.audios[(row * 2) + col].isPlaying ? 'fa' : 'fa-play',staticStyle:{"margin":"auto"},attrs:{"id":"play-pause-button"}})])])])}),0)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }