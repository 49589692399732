let BASE_API = 'https://irisdev-azure.adaptconcepts.com/iris-server/public/api/'
if (window.location && window.location.hostname && window.location.hostname.includes('adaptconcepts')) {
  // development
  BASE_API = 'https://irisdev-azure.adaptconcepts.com/iris-server/public/api/'
  // BASE_API = 'https://iris-api.accelerate360.com/api/' // dev (force live)
}
if (window.location && window.location.hostname && window.location.hostname.includes('irisdevd365')) {
  // staging
  BASE_API = 'https://irisdevd365-azure.adaptconcepts.com/iris-server/public/api/'
}
if (window.location && window.location.hostname && window.location.hostname.includes('accelerate360')) {
  // live
  BASE_API = 'https://iris-api.accelerate360.com/api/'
}
let isLocalhost = false
if (window.location && window.location.hostname && window.location.hostname.includes('localhost')) {
  // localhost
  BASE_API = 'https://irisdev-azure.adaptconcepts.com/iris-server/public/api/' // azure dev
  // BASE_API = 'https://irisdevd365-azure.adaptconcepts.com/iris-server/public/api/' // azure staging
  // BASE_API = 'https://iris-api.accelerate360.com/api/' // azure prod (actual)
  isLocalhost = true
}

let isStaging = false
let isLive = false
if (BASE_API && BASE_API.includes('accelerate360')) isLive = true
else if (BASE_API && BASE_API.includes('irisdevd365')) isStaging = true

// browser related
const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0 // Opera 8.0+
const isFirefox = typeof InstallTrigger !== 'undefined' // Firefox 1.0+
const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]' })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)) // Safari 3.0+ "[object HTMLElementConstructor]"
const isIE = /* @cc_on!@ */false || !!document.documentMode // Internet Explorer 6-11
const isEdge = !isIE && !!window.StyleMedia // Edge 20+
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime) // Chrome 1 - 79
const isEdgeChromium = isChrome && (navigator.userAgent.indexOf('Edg') !== -1) // Edge (based on chromium) detection
const isBlink = (isChrome || isOpera) && !!window.CSS // Blink engine detection

const Constants = Object.freeze({
  BASE_API,
  // BASE_API: 'https://iris-api.accelerate360.com/api/', // iris prod
  // BASE_API: 'https://irisdev.adaptconcepts.com/iris-server/public/api/', // iris dev
  // BASE_API: 'https://irisdev-azure.adaptconcepts.com/iris-server/public/api/', // azure dev
  // BASE_API: 'https://iris-api.accelerate360.com/api/', // azure prod (actual)
  // BASE_API: 'https://iris.accelerate360.com/iris-server/public/api/', // azure prod (actual same domain)
  isLive,
  isStaging,
  isLocalhost,

  BASE_STORE: 'https://sta360piris.blob.core.windows.net/',
  BASE_ASSETS: 'https://sta360piris.blob.core.windows.net/asset/',

  BrowserInfo: {
    isOpera,
    isFirefox,
    isSafari,
    isIE,
    isEdge,
    isEdgeChromium,
    isBlink
  },

  Defaults: {
    PAGING: { page: 1, size: 20 },
  },

  TITLE: 'IRIS360',

  RoleType: {
    UNKNOWN: -1,
    SUPERADMIN: 99,
    ADMIN: 1,
    USER: 2,
    SALES: 3,
    SUPPLIER: 4,
    APPROVED_SUPPLIER: 5,
    LEGAL: 6,
    FINANCE: 7,
    CM: 8,
    SUPPLIER_ADMIN: 9,
    REGULATORY: 10
  },

  RoleTypeString: {
    SUPER_ADMIN: 'superadmin',
    ADMIN: 'admin',
    USER: 'user',
    SALES: 'sales',
    SUPPLIER: 'supplier',
    APPROVED_SUPPLIER: 'approved supplier',
    LEGAL: 'legal',
    FINANCE: 'finance',
    CM: 'cm',
    SUPPLIER_ADMIN: 'supplier-admin',
    UNKNOWN: 'unknown',
  },

  roleToString: (role) => {
    switch (role) {
      case 99:
        return 'superadmin'
      case 1:
        return 'admin'
      case 2:
        return 'user'
      case 3:
        return 'sales'
      case 4:
        return 'supplier'
      case 5:
        return 'approved supplier'
      case 6:
        return 'legal'
      case 7:
        return 'finance'
      case 8:
        return 'cm'
      case 9:
        return 'supplier-admin'
      case -1:
      default:
        return 'unknown'
    }
  },

  ModuleType: {
    UNKNOWN: 'unknown',
    USER: 'user',
    DEBUG: 'debug',
    // market intelligence & IRI
    NIELSEN: 'nielsen',
    IRI: 'iri',
    IRI_ADMIN: 'iri.admin',
    IRI_TREND: 'iri.trend',
    IRI_MACRO: 'iri.macro',
    // program
    PROGRAM: 'program',
    PROGRAM_FORM: 'program.form',
    PROGRAM_DASHBOARD: 'program.dashboard',
    PROGRAM_DASHBOARD_OVERVIEW: 'program.dashboard.overview',
    PROGRAM_DASHBOARD_TASKLIST: 'program.dashboard.tasklist',
    PROGRAM_ADMIN: 'program.admin',
    PROGRAM_ADMIN_TASK: 'program.admin.task',
    PROGRAM_ADMIN_USER: 'program.admin.user',
    PROGRAM_USER: 'program.user', // (dynamic assign)
    // task
    TASK_BUILDER: 'task.builder',
    TASK: 'task',
    TASK_LIST: 'task.list',
    // pricing, presales
    PRICING_ADMIN: 'pricing-admin',
    PRICING_USER: 'pricing-user',
    PRESALES_UNAPPROVED_SUPPLIER: 'presales-unapproved-supplier', // new? // 2021.04.21 removed
    PRESALES_APPROVED_SUPPLIER: 'presales-approved-supplier', // 2021.04.21 removed
    // admin
    LEGAL_ADMIN: 'legal.admin',
    // REGULATORY_ADMIN: 'regulatory.admin',
    COI_ADMIN: 'coi.admin', // coi admin - menu
    COI_ADMIN_ROLE: 'coi.admin.role', // coi admin (dynamic assign - affixed role)
    CM_ADMIN: 'cm.admin',
    LISTING_ADMIN: 'listing.admin',
    BROKERAGE_ADMIN: 'brokerage.admin',
    TPL: 'tpl', // new for 3pl
    TPL_FORM: 'tpl.form', // new for 3pl
    TPL_DASHBOARD: 'tpl.dashboard', // new for 3pl
    TPL_ADMIN: 'tpl.admin',
    FINANCE: 'finance',
    FINANCE_ADMIN: 'finance.admin',
    OPERATION: 'operation',
    OPERATION_ADMIN: 'operation.admin',
    EXECUTIVE_VIEWER: 'executive-viewer',
    // partner
    PARTNER: 'partner',
    PARTNER_ADMIN: 'partner.admin', // create partner only
    PARTNER_APPROVAL: 'partner.approval', // approve partner only
    PARTNER_BROKERAGE: 'partner.brokerage', // partner brokerage (dynamic assign)
    PARTNER_LISTING: 'partner.listing', // partner listing (dynamic assign)
    PARTNER_SBT: 'partner.sbt', // partner sbt (dynamic assign)
    PARTNER_TPL: 'partner.tpl', // partner 3PL (dynamic assign)
    PRESALES_SUPPLIER: 'partner.supplier', // partner supplier (dynamic assign)
    // sales
    SALES: 'sales', // base module
    SALES_DASHBOARD: 'sales.dashboard',
    // form.opportunity
    OPPORTUNITY: 'opportunity',
    OPPORTUNITY_FORM: 'opportunity.form', // new
    OPPORTUNITY_DASHBOARD: 'opportunity.dashboard', // new
    // form.feedback
    FEEDBACK: 'feedback',
    FEEDBACK_FORM: 'feedback.form', // new
    FEEDBACK_DASHBOARD: 'feedback.dashboard', // new
    // form.bizdev
    BIZDEV: 'bizdev',
    BIZDEV_FORM: 'bizdev.form', // new
    BIZDEV_DASHBOARD: 'bizdev.dashboard', // new
    BIZDEV_REPORTING: 'bizdev.reporting', // new
    // item.sample
    ITEM_SAMPLE: 'item.sample',
    ITEM_SAMPLE_FORM: 'item.sample.form', // new
    ITEM_SAMPLE_DASHBOARD: 'item.sample.dashboard', // new
    ITEM_SAMPLE_ADMIN: 'item.sample.admin',
    // item
    INITIAL: 'initial',
    INITIAL_FORM: 'initial.form', // new
    INITIAL_DASHBOARD: 'initial.dashboard', // new
    INITIAL_ADMIN: 'initial.admin', // new
    FORECAST: 'forecast',
    FORECAST_DASHBOARD: 'forecast.dashboard', // new
    FORECAST_ADMIN: 'forecast.admin', // new
    // new
    PRODUCT: 'product',
    PRODUCT_DASHBOARD: 'product.dashboard',
    PRODUCT_PERFORMANCE: 'product.performance',
    PRODUCT_PIPELINE: 'product.pipeline',
    PRODUCT_INVENTORY: 'product.inventory',
    // item.pricing
    ITEM_PRICING: 'item.pricing',
    ITEM_PRICING_GLOBAL: 'item.pricing.global',
    ITEM_PRICING_FORM: 'item.pricing.form',
    ITEM_PRICING_DASHBOARD: 'item.pricing.dashboard',
    // item.calculator
    ITEM_CALCULATOR: 'item.calculator',
    ITEM_CALCULATOR_FORM: 'item.calculator.form',
    ITEM_CALCULATOR_DASHBOARD: 'item.calculator.dashboard',
    // item.supplier
    ITEM_SETUP: 'item.setup',
    ITEM_SETUP_FORM: 'item.setup.form', // new
    ITEM_SETUP_DASHBOARD: 'item.setup.dashboard', // new
    ITEM_SETUP_BRAND: 'item.setup.brand', // new
    ITEM_SETUP_APPROVAL: 'item.setup.approval',
    ITEM_SETUP_APPROVAL_VIEWER: 'item.setup.approval.viewer', // viewer only
    // item.listing
    ITEM_LISTING: 'item.listing',
    ITEM_LISTING_FORM: 'item.listing.form', // new
    ITEM_LISTING_DASHBOARD: 'item.listing.dashboard', // new
    ITEM_LISTING_BRAND: 'item.listing.brand', // new
    ITEM_LISTING_APPROVAL: 'item.listing.approval',
    ITEM_LISTING_APPROVAL_VIEWER: 'item.listing.approval.viewer', // viewer only
    // item.admin
    ITEM_ADMIN: 'item.admin',
    PRESALES_PROXY_SUPPLIER: 'partner.supplier.admin',
    PARTNER_LISTING_ADMIN: 'partner.listing.admin',
    // item.brokerage
    ITEM_BROKERAGE: 'item.brokerage',
    ITEM_BROKERAGE_FORM: 'item.brokerage.form',
    ITEM_BROKERAGE_DASHBOARD: 'item.brokerage.dashboard',
    ITEM_BROKERAGE_ADMIN: 'item.brokerage.admin',
    // admin
    ADMIN: 'admin',
    ADMIN_USER: 'admin.user',
    ADMIN_SALESREP: 'admin.salesrep',
    ADMIN_RETAILER: 'admin.retailer',
    ADMIN_BROKER: 'admin.broker',
    ADMIN_CLASSIFICATION: 'admin.classification',
    ADMIN_ASSORTMENT: 'admin.assortment',
    ADMIN_SKU: 'admin.sku',
    // system
    SYSTEM: 'system',
    SYSTEM_LOG: 'system.log',
    SYSTEM_LOG_ERROR: 'system.log.error',
  },

  ModuleStype: {
    VIEW_ITEM: 'view.item', // primary
    VIEW_MODULE: 'view.group', // green
    VIEW_ADMIN: 'view.admin', // warning
    SERVER: 'server', // server/dynamic assign // red
    OBSOLETE: 'obsolete', // info
  },

  MODULE_STYPES: [{
    id: 'view.item',
    label: 'view.item',
    eltype: 'primary'
  }, {
    id: 'view.group',
    label: 'view.group',
    eltype: 'success'
  }, {
    id: 'view.admin',
    label: 'view.admin',
    eltype: 'warning'
  }, {
    id: 'server',
    label: 'server/role-use',
    eltype: 'danger'
  }, {
    id: 'obsolete',
    label: 'obsolete/other',
    eltype: 'info'
  }],

  UserStatus: {
    DISABLE: 0,
    ENABLE: 1
  },

  ActiveStatus: {
    INACTIVE: false,
    ACTIVE: true
  },

  activeStatusToString: (activeStatus) => {
    switch (activeStatus) {
      case 0:
      case false: // using this
        return 'inactive'
      case 1:
      case true: // using this
        return 'active'
      case -1:
      default:
        return 'unknown'
    }
  },

  PLACEHOLDER_URL: 'https://via.placeholder.com/300.png?text=placeholder',
  UI: {
    FONT: 'LatoRegular'
  },

  TRACK_EVENT: {
    CLICK: 'click',
    VIEW: 'view',
    SCROLL: 'scroll',
  },

  TRACK_EVENT_CATEGORY: {
    GENERAL: 'general',
  },

  onTrack: (instance, event, label, category = 'general', value) => {
    if (event === 'view') {
      instance.$gtag.pageview(label)
      return
    }
    instance.$gtag.event(event, {
      'event_category': category,
      'event_label': label,
      ...(
        value != null ?
        { 'value': value } : {}
      ),
    })
  },
})

export default Constants
