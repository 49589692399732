class UtilUrl {
  static encodeUri (uri) {
    if (uri && uri.length > 0) {
      uri = uri.replace(/\//g, '%2F')
    }
    return uri
  }

  static decodeUri (uri) {
    if (uri && uri.length > 0) {
      uri = uri.replace(/(%2F)/g, '/')
    }
    return uri
  }

  static encodeUrii (uri) {
    if (uri && uri.length > 0) {
      uri = encodeURIComponent(uri)
    }
    return uri
  }

  static decodeUrii (uri) {
    if (uri && uri.length > 0) {
      uri = decodeURIComponent(uri)
    }
    return uri
  }

  static getFileTypes () {
    return [
      'jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'tif', 'svg', 'webp',
      'midi', 'mid', 'aac', 'mp3', 'wav', 'oga', 'weba',
      'avi', 'mpeg', 'mp4', '3gp', '3g2', 'ogv', 'webm',
      'txt', 'rtf', 'csv', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'xlsm', 'ppt', 'pptx',
      'zip'
    ]
  }

  static getMimeFileTypes () {
    let types = UtilUrl.getFileTypes()
    let info = {}
    for (let t in types) {
      let type = types[t]
      let mime = UtilUrl.getMime(type)
      if (!info.hasOwnProperty(mime)) {
        info[mime] = []
      }
      info[mime].push(type)
    }
    return info
  }

  static getMime (extension) {
    switch (extension && extension.toLowerCase()) {
      // image
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg'
      case 'png':
        return 'image/png'
      case 'gif':
        return 'image/gif'
      case 'bmp':
        return 'image/bmp'
      case 'tiff':
      case 'tif':
        return 'image/tiff'
      case 'svg':
        return 'image/svg+xml'
      case 'webp':
        return 'image/webp'
      // audio
      case 'midi':
      case 'mid':
        return 'audio/midi'
      case 'aac':
        return 'audio/aac'
      case 'mp3':
        return 'audio/mpeg'
      case 'wav':
        return 'audio/wav'
      case 'oga':
        return 'audio/ogg'
      case 'weba':
        return 'audio/webm'
      // video
      case 'avi':
        return 'video/x-msvideo'
      case 'mpeg':
        return 'video/mpeg'
      case 'mp4':
        return 'video/mp4'
      case '3gp':
        return 'video/3gpp'
      case '3g2':
        return 'video/3gpp2'
      case 'ogv':
        return 'video/ogg'
      case 'webm':
        return 'video/webm'
      // doc
      case 'txt':
        return 'text/plain'
      case 'rtf':
        return 'application/rtf'
      case 'csv':
        return 'text/csv'
      case 'pdf':
        return 'application/pdf'
      case 'doc':
        return 'application/msword'
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      case 'xls':
        return 'application/vnd.ms-excel'
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      case 'xlsm':
        return 'application/vnd.ms-excel.sheet.macroEnabled.12'
      case 'ppt':
        return 'application/vnd.ms-powerpoint'
      case 'pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      // archive
      case 'zip':
        return 'application/zip'
      case 'rar':
        return 'application/vnd.rar'
      case '7z':
        return 'application/x-7z-compressed'
      default:
        throw new Error(`invalid handled mime extension: ${extension}`)
    }
  }

  static getPathExt (url) {
    // eslint-disable-next-line no-useless-escape
    return url.split('.').pop().split(/\#|\?/)[0]
  }

  static getPathFilename (url) {
    // eslint-disable-next-line no-useless-escape
    return url.split('/').pop().split(/\#|\?/)[0]
  }

  static openUrl (url, isTargetBlank = true) {
    window.open(url, isTargetBlank ? '_blank' : undefined)
  }

  static getParamQuery (params = {}) {
    return Object.keys(params).map((k) => {
      return encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
    }).join('&')
  }

  static getRouterQuery (store, name, extraParams = {}) {
    let params = store.getters.page && store.getters.page.pages[name]
    if (!params) return { name, query: { ...extraParams } }
    if (params.hasOwnProperty('name')) delete params.name
    return { name, query: { ...params, ...extraParams } }
  }
}

export default UtilUrl
