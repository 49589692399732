<template>
  <div id="section-about" ref="sectionMain" class="sections section-about page-wrapper">
    <div class="section-wrapper">
      <div class="statement-wrapper fast">
        <h4 class="statement div-80"
           ref="statement1"
           data-aos="slide-up"
           data-aos-once="true"
           data-aos-offset="99999"
           data-aos-delay="300"
           data-aos-duration="1300"
           data-aos-easing="easing-custom"
           :class="(getRefInfo('statement1').isMidPoint ? 'aos-animate' : '')">
           <!-- It would be hard to dispute that the series of events that led to the civil unrest of 2020 in many parts of the United States were underpinned by one common factor, that being the systematic devaluing of young black and brown lives. In societies where those born into challenging socio-economic circumstances find themselves at a disadvantage before they even enter the education system; cultural integration continues to present itself as a social challenge. -->
           One senseless act of violence can trigger a sequence of events that may impact a family forever. The loss of a child at any age represents an unthinkable nightmare for any parent. But to have the life of someone you have cherished, nurtured loved and raised suddenly ripped from you without warning, somehow stands apart. It heralds a lifetime of pain and anguish that does not ease with the passing of time.
        </h4>
      </div>
      <div class="statement-wrapper fast">
        <h4 class="statement div-80"
           ref="statement2"
           data-aos="slide-up"
           data-aos-once="true"
           data-aos-offset="99999"
           data-aos-delay="500"
           data-aos-duration="1300"
           data-aos-easing="easing-custom"
           :class="(getRefInfo('statement1').isMidPoint ? 'aos-animate' : '')">
           At the heart of this collective family grief is a mother, hers is a pain unlike any other. A mother in grief is left alone to imagine the life her child might have lived were it not for an unwarranted moment of hate. Every birthday every anniversary that passes by she is left to relive the trauma anew. It is only when loss is viewed through the eyes of a mother that we really understand the permanence of grief.
        </h4>
      </div>
      <div class="statement-wrapper content">
        <h1 class="statement"
          ref="statement3"
          data-aos="slide-up"
          data-aos-once="true"
          data-aos-offset="99999"
          data-aos-delay="800"
          data-aos-duration="1300"
          data-aos-easing="easing-custom"
          :class="(getRefInfo('statement1').isMidPoint ? 'aos-animate' : '')">
          When the life of a child is lost through an act of violence motivated by hate, the public’s gaze is drawn to the anguish of the mothers we see on our screens. The pain, the distress, the mental suffering is laid bare for the world to witness.
        </h1>
      </div>
      <!-- <div class="statement-wrapper content">
        <h1 class="statement"
          ref="statement2"
          data-aos="slide-up"
          data-aos-once="true"
          data-aos-offset="99999"
          data-aos-delay="500"
          data-aos-duration="1300"
          data-aos-easing="easing-custom"
          :class="(getRefInfo('statement1').isMidPoint ? 'aos-animate' : '')">
          Only those brought up to view life through the lens of ignorance and privilege, would deny that the political and economic landscape in western society does not serve as a system that elevates and advances the lives of black and brown people the world over. 
        </h1>
      </div> -->
      <!-- <div class="statement-wrapper fast">
        <h4 class="statement div-80"
          ref="statement3"
          data-aos="slide-up"
          data-aos-once="true"
          data-aos-offset="99999"
          data-aos-delay="800"
          data-aos-duration="1300"
          data-aos-easing="easing-custom"
          :class="(getRefInfo('statement1').isMidPoint ? 'aos-animate' : '')">
          The mass media’s serial depiction of young men and women of colour as aggressors, criminals or individual’s lacking in various traits of acceptable social behaviour, serves as the fuel for the damaging misrepresentation of people from non-white communities. As a result, the callous taking of black and brown lives also elicits the functional myth that these families somehow are immune to the painful impact the loss of a loved one has on its members.
        </h4>
      </div>
      <div class="statement-wrapper fast">
        <h4 class="statement div-80"
          ref="statement4"
          data-aos="slide-up"
          data-aos-once="true"
          data-aos-offset="99999"
          data-aos-delay="1100"
          data-aos-duration="1300"
          data-aos-easing="easing-custom"
          :class="(getRefInfo('statement1').isMidPoint ? 'aos-animate' : '')">
          For centuries, black bodies have been hunted, captured, beaten, abused, disabled, lynched and publicly stripped of their humanity by white societies. This historical trauma has resulted in a resolve and unwavering faith in one’s own ability to stand strong and survive. Within these cultural networks, no one resolve is tested more than that of the mother’s to the victims of violent crime. Black mothers the world over speak of the fear they carry for the lives of their children, particular their male children.
        </h4>
      </div>
      <div class="statement-wrapper content">
        <h1 class="statement"
          ref="statement5"
          data-aos="slide-up"
          data-aos-once="true"
          data-aos-offset="99999"
          data-aos-delay="1400"
          data-aos-duration="1300"
          data-aos-easing="easing-custom"
          :class="(getRefInfo('statement1').isMidPoint ? 'aos-animate' : '')">
          When the life of a child is lost and subjected to public scrutiny, it is the anguish of the mothers we see on our screens. The pain, the distress, the mental suffering is laid bare for the world to witness.
        </h1>
      </div>
      <div class="statement-wrapper fast">
        <h4 class="statement div-80"
          ref="statement6"
          data-aos="slide-up"
          data-aos-once="true"
          data-aos-offset="99999"
          data-aos-delay="1700"
          data-aos-duration="1300"
          data-aos-easing="easing-custom"
          :class="(getRefInfo('statement1').isMidPoint ? 'aos-animate' : '')">
          One senseless act of violence can trigger a sequence of events that may impact a family forever. At the heart of it is a mother who will have to live with the fact that the life she gave birth to is no longer hers to hold.
        </h4>
      </div> -->
    </div>

    <!-- <div class="hanging line line-cross is-hidden-mobile"></div> -->
  </div>
</template>

<script>
import _ from 'lodash'
// import Constants from '@/constants'
// import EventBus from '@/controllers/EventBus'
// import { getToken } from '@/utils/auth'

// import DivContainer from '@/components/DivContainer.vue'

export default {
  name: 'section-about',
  props: ['last-position'],
  components: {
    // DivContainer,
  },
  data () {
    return {
      // loading: false,
      posY: 0,
      offsetY: 0,
      timer: undefined,
      refs: [
        { name: 'statement1', isMidPoint: false, },
        // { name: 'statement2', isMidPoint: false, },
        // { name: 'statement3', isMidPoint: false, },
        // { name: 'statement4', isMidPoint: false, },
        // { name: 'statement5', isMidPoint: false, },
        // { name: 'statement6', isMidPoint: false, },
      ],
    }
  },
  computed: {
  },
  watch: {
    lastPosition: function (value, oldValue) {
      let posY = this.$refs.sectionMain.getBoundingClientRect().y
      let height = this.$refs.sectionMain.getBoundingClientRect().height
      this.$set(this, 'posY', -posY)
      let dheight = document.documentElement.clientHeight
      let diff = (height - dheight) / 2
      diff = diff > 0 ? -diff : diff
      this.$set(this, 'offsetY', diff)
      this.timerRefresh()
    }
  },
  created () {
  },
  async mounted () {
    this.timer = setInterval(() => this.timerRefresh(), 100)
    this.timerRefresh()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    timerRefresh () {
      for (let r in this.refs) {
        let refInfo = this.refs[r]
        let refName = refInfo.name
        this.setIsMidPoint(refName)
      }
    },

    isMidPoint (refName) {
      let ref = this.$refs[refName]
      if (!ref) return false
      let rect = ref.getBoundingClientRect()
      let top = rect && rect.top
      let targetHeight = window.innerHeight * 0.98
      let isMidPoint = (top < targetHeight)
      return isMidPoint
    },

    setIsMidPoint (refName) {
      let refInfo = this.getRefInfo(refName)
      let isMidPoint = this.isMidPoint(refName)
      this.$set(refInfo, 'isMidPoint', isMidPoint)
    },

    getRefInfo (refName) {
      let found = _.find(this.refs, (r) => r.name === refName)
      return found || {}
    },
  }
}
</script>

<style lang="scss">
@import "../../styles/base.scss";
@import "../../styles/sections.scss";

.section-about {
  .div-80 {
    width: 80%;
    @media screen and (max-width: $mobile) {
      width: 100%;
    }
  }

  .section-wrapper {
    padding: 15% 12% 15% 22% !important;
    @media screen and (max-width: $mobile) {
      margin: 0;
      padding: 120px 20px !important;
    }
  }

  .statement-wrapper {
    &.content {
      margin-top: 18px;
      margin-left: 50px;
      // margin-bottom: 18px;
      h1 {
        // color: white;
        // font-family: "Proxima Nova", arial, sans-serif;
        // font-weight: bold;
        font-size: 1.4em;

        @media screen and (min-width: $desktop) {
          font-size: 1.8em;
        }
      }
    }
    .statement {
      padding-bottom: 18px;
    }
  }
}

</style>
