import Constants from '@/constants'
// import { createWebHistory, createRouter } from 'vue-router'
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Landing from '@/pages/landing.vue'
import NotFound from '@/pages/notfound.vue'

const routes = [
  {
    path: '/',
    name: 'landing',
    component: Landing,
    meta: { title: 'Landing' }
  },
  ...[{
    // path: '*',
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    name: 'notfound',
    id: 404,
    component: NotFound,
    meta: { title: 'Not Found' }
  }],
]

// const router = createRouter({
//   history: createWebHistory(),
//   routes,
// })

var router = new VueRouter({
  mode: 'history',
  base: Constants.BASE_URL,
  routes,
})

export default router
