const poform = {
  state: {
    salesReps: [],
    retailers: [],
    upcs: [],
    distributionExpectations: [],
    // sales
    closingCertainty: [],
    termTypes: [],
    paymentTermTypes: [],
    contractDuration: [],
    programs: [],
    // opportunity
    opportunityList: [],
    opportunityCertainty: [],
    opportunityType: [],
    opportunityTermType: [],
    opportunityPaymentTermType: [],
    opportunityDuration: [],
    form: {
      // default blank
    }
  },
  mutations: {
    SET_SALES_REPS: (state, salesReps) => {
      state.salesReps = salesReps
    },
    SET_RETAILERS: (state, retailers) => {
      state.retailers = retailers
    },
    SET_UPCS: (state, upcs) => {
      state.upcs = upcs
    },
    SET_DISTRIBUTION_EXPECTATIONS: (state, distributionExpectations) => {
      state.distributionExpectations = distributionExpectations
    },
    // sales
    SET_CLOSING_CERTAINTY: (state, closingCertainty) => {
      state.closingCertainty = closingCertainty
    },
    SET_TERM_TYPES: (state, termTypes) => {
      state.termTypes = termTypes
    },
    SET_PAYMENT_TERM_TYPES: (state, paymentTermTypes) => {
      state.paymentTermTypes = paymentTermTypes
    },
    SET_CONTRACT_DURATION: (state, contractDuration) => {
      state.contractDuration = contractDuration
    },
    SET_PROGRAMS: (state, programs) => {
      state.programs = programs
    },
    // opportunity
    SET_OPPORTUNITY_LIST: (state, opportunityList) => {
      state.opportunityList = opportunityList
    },
    SET_OPPORTUNITY_CERTAINTY: (state, opportunityCertainty) => {
      state.opportunityCertainty = opportunityCertainty
    },
    SET_OPPORTUNITY_TYPE: (state, opportunityType) => {
      state.opportunityType = opportunityType
    },
    SET_OPPORTUNITY_TERM_TYPE: (state, opportunityTermType) => {
      state.opportunityTermType = opportunityTermType
    },
    SET_OPPORTUNITY_PAYMENT_TERM_TYPE: (state, opportunityPaymentTermType) => {
      state.opportunityPaymentTermType = opportunityPaymentTermType
    },
    SET_OPPORTUNITY_DURATION: (state, opportunityDuration) => {
      state.opportunityDuration = opportunityDuration
    },
    SET_FORM: (state, form) => {
      state.form = form
    }
  },
  actions: {
    setSalesReps ({ commit }, salesReps) {
      commit('SET_SALES_REPS', salesReps)
    },
    setRetailers ({ commit }, retailers) {
      commit('SET_RETAILERS', retailers)
    },
    setUpcs ({ commit }, upcs) {
      commit('SET_UPCS', upcs)
    },
    setDistributionExpectations ({ commit }, distributionExpectations) {
      commit('SET_DISTRIBUTION_EXPECTATIONS', distributionExpectations)
    },
    // sales
    setClosingCertainty ({ commit }, closingCertainty) {
      commit('SET_CLOSING_CERTAINTY', closingCertainty)
    },
    setTermTypes ({ commit }, termTypes) {
      commit('SET_TERM_TYPES', termTypes)
    },
    setPaymentTermTypes ({ commit }, paymentTermTypes) {
      commit('SET_PAYMENT_TERM_TYPES', paymentTermTypes)
    },
    setContractDuration ({ commit }, contractDuration) {
      commit('SET_CONTRACT_DURATION', contractDuration)
    },
    setPrograms ({ commit }, programs) {
      commit('SET_PROGRAMS', programs)
    },
    // opportunity
    setOpportunityList ({ commit }, opportunityList) {
      commit('SET_OPPORTUNITY_LIST', opportunityList)
    },
    setOpportunityCertainty ({ commit }, opportunityCertainty) {
      commit('SET_OPPORTUNITY_CERTAINTY', opportunityCertainty)
    },
    setOpportunityType ({ commit }, opportunityType) {
      commit('SET_OPPORTUNITY_TYPE', opportunityType)
    },
    setOpportunityTermType ({ commit }, opportunityTermType) {
      commit('SET_OPPORTUNITY_TERM_TYPE', opportunityTermType)
    },
    setOpportunityPaymentTermType ({ commit }, opportunityPaymentTermType) {
      commit('SET_OPPORTUNITY_PAYMENT_TERM_TYPE', opportunityPaymentTermType)
    },
    setOpportunityDuration ({ commit }, opportunityDuration) {
      commit('SET_OPPORTUNITY_DURATION', opportunityDuration)
    },
    setForm ({ commit }, form) {
      commit('SET_FORM', form)
    }
  }
}

export default poform
