<template>
  <div id="navse" class="navbar-wrapper" ref="navbar-full"
    data-aos="fade-in" data-aos-once="true"
    data-aos-offset="-999999" data-aos-delay="0"
    :class="[
      ($route.name !== 'landing' ? 'not-landing' : '')
    ]">

    <div-container full="true" class="navbar-full-wrapper" column-num="is-12">
      <div class="columns navbar-full is-gapless is-mobile">

        <div class="column navbar-menu" @click="onToggle()" data-aos="zoom-in" data-aos-once="true">
          <div class="control-menu" :class="{ active : showNav }">
            <span></span>
          </div>
        </div>

        <div class="column is-full is-hidden-tablet">
          <div class="navbar-logo has-text-right">
            <div class="title">
              A DEE ALLEN-BARNES FILM
            </div>
          </div>
        </div>

        <div class="column column-link is-hidden-mobile">

          <div class="columns is-gapless" style="margin-top:0;margin-bottom:0;">
            <div class="column has-text-left link-wrapper" v-if="menu">
              <div class="navbar-logo has-text-right">
                <div class="title">
                  A DEE ALLEN-BARNES FILM
                </div>
              </div>

              <div v-for="(navItem, navIndex) in navMain" :key="`${navIndex}`" style="display:inline-block;">
                <dropdown
                  v-if="!navItem.modules || (navItem.modules &&
                    Utils.array.containsFromArray(navItem.modules, modules)) &&
                    !navItem.hidden"
                  :visible="navItem.subnavOpen || false"
                  :animation="'ani-slide-y'" :position="dropdownPositions"
                  @positioned="onDropdownPosition($event, navItem.offset, navIndex)">

                  <a v-if="navItem.external" :href="navItem.pathUrl" target="_blank">
                    <dropdown :visible="navItem.subnavOpen || false">
                      <span class="link" v-if="navItem.content" v-html="navItem.content">
                      </span>
                      <span class="link" v-else>
                        {{ navItem.title }}
                      </span>
                    </dropdown>
                  </a>

                  <a v-else
                    :href="[navItem.section ? '/?anchor=' + navItem.section : (navItem.pathUrl ? navItem.pathUrl : '#')]"
                    @click="[ navItem.section ? onScrollTo($event, 'landing', navItem) : (navItem.pathUrl ? onClick($event, navItem.pathUrl, navItem, navItem) : $event.preventDefault())]"
                    :class="{
                      'nav-item-link': true,
                      'is-parent': true,
                      active: $route.name === navItem.name, [navItem.pathUrl]: true,
                      selected: navItem.subnavOpen,
                    }"
                    @mouseover="onMouseOverSubnav($event, navItem.subnav, navItem)"
                    @mouseout="onMouseOutSubnav($event, navItem.subnav, navItem)">

                    <dropdown :visible="navItem.subnavOpen || false"  :position="dropdownPositions">
                      <!-- <a class="link" v-if="navItem.content" v-html="navItem.content" :href="navItem.pathUrl" target="_blank" style="margin:0px;padding:0px;font-size: 19px;line-height:0px;">
                      </a> -->
                      <span class="link nav-item type-content" v-if="navItem.content" v-html="navItem.content">
                      </span>
                      <span class="link nav-item type-string" v-else>
                        {{ navItem.title }}
                      </span>
                      <!-- <span v-if="navItem.subnav">
                        &nbsp;<i class="fa fa-angle-down" style="vertical-align:middle;"></i>
                      </span> -->
                    </dropdown>

                  </a>

                  <!-- main sub navigation items -->
                  <template v-slot:dropdown>
                  <div
                    v-if="navItem.subnav"
                    class="dropdown-wrapper"
                    @mouseover="navItem.subnavOpen = true"
                    @mouseout="navItem.subnavOpen = false">
                    <div v-for="(navSubItem, navSubIndex) in navItem.subnav" :key="`${navSubIndex}`"
                      class="dropdown-item has-text-left">

                      <!-- logout -->
                      <a v-if="navSubItem.name === 'logout'"
                        :href="[navSubItem.section ? '/?anchor=' + navSubItem.section : (navSubItem.pathUrl ? navSubItem.pathUrl : '#')]"
                        @click.prevent="onLogout()"
                        :class="{ 'nav-item-link': true, active : $route.name === navSubItem.name }">

                        <div class="dropdown-item-inner" style="padding: 12px 24px;">
                          <i v-if="navSubItem.icon"
                            style="margin-top:3px;margin-right:8px;"
                            class="fa"
                            :class="{ [`fa-${navSubItem.icon}`]: true }"></i>
                          {{ navSubItem.title }}
                        </div>

                      </a>
                      <!-- subnav without dropdown -->
                      <!-- ======================= -->

                      <a
                        v-else-if="!navSubItem.modules || (navSubItem.modules && Utils.array.containsFromArray(navSubItem.modules, modules))"
                        :href="[navSubItem.section ? '/?anchor=' + navSubItem.section : (navSubItem.pathUrl ? navSubItem.pathUrl : '#')]"
                        @click="[ navSubItem.section ? onScrollTo($event, 'landing', navSubItem) : (navSubItem.pathUrl ? onClick($event, navSubItem.pathUrl, navSubItem, navItem) : $event.preventDefault())]"
                        :class="{ 'nav-item-link': true, 'is-parent': true, active : $route.name === navSubItem.name }"
                        @mouseover="onMouseOverSubnav($event, navSubItem.subnav, navSubItem)"
                        @mouseout="onMouseOutSubnav($event, navSubItem.subnav, navSubItem)"
                        :style="navSubItem.style">

                        <dropdown
                          :visible="navSubItem.subnavOpen || false"
                          :animation="'ani-fade'" :position="navSubItem.positions" @positioned="onDropdown2Position($event, navSubItem.position, navSubItem.indexActual)">

                          <div style="position:relative;width:100%;">
                            <div class="link" style="display:inline-block;padding:12px 24px;width:100%;">
                              {{ navSubItem.title }}
                            </div>
                            <div v-if="navSubItem.subnav && navSubItem.subnav.length > 0"
                              style="position:absolute;right:6px;top:12px;">
                              <i class="fa fa-chevron-right"></i>
                            </div>
                          </div>

                          <!-- main sub navigation items -->
                          <template v-slot:dropdown>
                          <div
                            v-if="navSubItem.subnav"
                            class="dropdown-wrapper"
                            @mouseover="navSubItem.subnavOpen = true"
                            @mouseout="navSubItem.subnavOpen = false">

                            <div v-for="(navSub2Item, navSub2Index) in navSubItem.subnav" :key="`${navSub2Index}`"
                              class="dropdown-item has-text-left">
                              <!-- subnav without dropdown -->
                              <a
                                v-if="!navSub2Item.modules || (navSub2Item.modules && Utils.array.containsFromArray(navSub2Item.modules, modules))"
                                :href="[navSub2Item.section ? '/?anchor=' + navSub2Item.section : (navSub2Item.pathUrl ? navSub2Item.pathUrl : '#')]"
                                @click="[ navSub2Item.section ? onScrollTo($event, 'landing', navSub2Item) : (navSub2Item.pathUrl ? onClick($event, navSub2Item.pathUrl, navSub2Item, navSubItem) : $event.preventDefault())]"
                                :class="{ 'nav-item-link': true, active : $route.name === navSub2Item.name }"
                                :style="navSub2Item.style">
                                <!-- @mouseover="onMouseOverSubnav($event, navSub2Item.subnav, navSub2Item)"
                                @mouseout="onMouseOutSubnav($event, navSub2Item.subnav, navSub2Item)" -->

                                <div class="dropdown-item-inner"
                                  :style="navSub2Item.styleInner">
                                  <i v-if="navSub2Item.icon"
                                    style="margin-top:3px;margin-right:8px;"
                                    class="fa"
                                    :class="{ [`fa-${navSub2Item.icon}`]: true }"></i>
                                  {{ navSub2Item.title }}
                                </div>

                              </a>

                            </div>

                          </div>
                          </template>

                        </dropdown>
                      </a>
                      <!-- ======================= -->

                    </div>
                  </div>
                  </template>

                </dropdown>
              </div><!-- end v-for navMain -->
            </div>

            <!-- in case no menu -->
            <div v-else class="column has-text-right link-wrapper">
              <div class="has-text-left" style="width:30px;display:inline-block;float:left;margin-top:8px;">
                <router-link class="logo-wrapper" to="/">
                  <img class="logo" src="../assets/logo.png" width="30px" style="width:30px;" />
                </router-link>

                <div v-if="isStaging" style="color:gray;display:inline-block;">
                  {{isLocalhost ? 'l' : ''}}stg
                </div>
                <div v-else-if="isDev" style="color:gray;display:inline-block;">
                  {{isLocalhost ? 'l' : ''}}dev
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div-container>

  </div>
</template>

<script>
// import _ from 'lodash'
import Constants from '@/constants'
import Utils from '@/utils'
import EventBus from '@/controllers/EventBus'
import DivContainer from '@/components/DivContainer'
import Dropdown from '@/components/VueMyDropdown'

export default {
  name: 'navbarfull',
  components: {
    DivContainer,
    Dropdown
  },
  props: ['toggle', 'menu'],
  data () {
    return {
      loading: false,
      isDev: !Constants.isLive,
      isStaging: Constants.isStaging,
      isLocalhost: Constants.isLocalhost,
      // isDesktop: true,
      showNav: false,
      navMain: EventBus.methods.getNav(),
      navSocial: [], // EventBus._data._navSocial,
      navEmail: [], // EventBus._data._navEmail,
      visible: false,
      dropdownPosition: {},
      dropdownPositions: ['center', 'bottom', 'center', 'top'],
      menus: [],
    }
  },
  computed: {
    // lodash: function () {
    //   return _
    // },
    Utils: function () {
      return Utils
    },
    modules: function () {
      return [Constants.ModuleType.ADMIN] // this.$store.getters.user.modules
    },
  },
  watch: {
    toggle: function (val) {
      this.showNav = val
    },
    '$route.name': function (val) {
      // only do this when overlay mode and route change
      if (this.showNav) {
        this.onToggle()
      }
    },
  },
  created () {
  },
  mounted () {
    // on event resize
    // this.isDesktop = EventBus._isDesktop
    // EventBus.$on('isDesktopChanged', function () {
    //   this.isDesktop = EventBus._isDesktop
    // }.bind(this))

    // // inject super admin menu
    // if (this.$route.name && this.$route.name.startsWith('contact.')) {
    //   this.navMain = EventBus._data._navSupplier.concat(EventBus._data._navAccount)
    // }
    //
    // if (this.$store.getters.user.role === Constants.RoleType.SUPERADMIN) {
    //   let index = _.findIndex(this.navMain, item => item.name === 'account')
    //   if (this.navMain[index] && this.navMain[index].subnav) {
    //     let hiddenMenu = []
    //     hiddenMenu = hiddenMenu.concat(EventBus._data._subnavHidden)
    //     hiddenMenu = hiddenMenu.concat(this.navMain[index].subnav)
    //     this.$set(this.navMain[index], 'subnav', hiddenMenu)
    //   }
    // }
  },
  methods: {
    onLogout () {
      this.loading = true
      this.$store.dispatch('LogOut').then(res => {
        let loginFrom = this.$store.getters.user.loginFrom
        if (loginFrom && loginFrom.length > 0) {
          console.log('auth:- LogOut, return to login from: ' + loginFrom)
          document.location.href = loginFrom
          this.loading = false
          return
        }
        console.log(res)
        location.reload()// In order to re-instantiate the vue-router object to avoid bugs
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        location.reload()
      })
    },

    onMouseOverSubnav ($event, subnav, navItem) {
      // try not use visible, only singular, use subnavOpen for multiple dropdown
      // if (subnav) {
      //   this.visible = true
      // }
      if (navItem) {
        navItem.subnavOpen = true
      }
    },

    onMouseOutSubnav ($event, subnav, navItem) {
      // try not use visible, only singular, use subnavOpen for multiple dropdown
      // if (subnav) {
      //   this.visible = false
      // }
      if (navItem) {
        navItem.subnavOpen = false
      }
    },

    onDropdownPosition ($event, offset, index) {
      // console.log('onDropdownPosition', { event: $event, offset, index })
      this.dropdownPosition = $event
      $event.top = '84px !important'
      if (offset && offset.top !== undefined) {
        if ($event.top && $event.top.match(/^([0-9])+px$/g)) {
          let val = parseFloat($event.top.replace(/(px)/g, ''))
          val += offset.top
          $event.top = `${val}px !important`
        }
      }
      if (offset && offset.left !== undefined) {
        if ($event.left && $event.left.match(/^([0-9])+px$/g)) {
          // console.log('-- before', $event.left)
          let val = parseFloat($event.left.replace(/(px)/g, ''))
          val += offset.left
          // console.log('-- after', val)
          $event.left = `${val}px !important`
        }
      }
      if (offset && offset.right !== undefined) {
        if ($event.right && $event.right.match(/^([0-9])+px$/g)) {
          let val = parseFloat($event.right.replace(/(px)/g, ''))
          val += offset.right
          $event.right = `${val}px !important`
        }
      }
    },

    onDropdown2Position ($event, info, index) {
      // console.log('onDropdown2Position', { event: $event, info, index })
      this.dropdownPosition = $event
      if (info && info.top !== undefined && index !== undefined) {
        let offset = 2
        let perRow = 40
        let total = offset + ((index) * perRow)
        $event.top = `${total}px !important`
      }
      // if (info && info.top !== undefined) {
      //   $event.top = `${info.top}px !important`
      // }
      if (info && info.left !== undefined) {
        $event.left = `${info.left}px !important`
      }
      if (info && info.right !== undefined) {
        $event.right = `${info.right}px !important`
      }
    },

    onScrollTo ($event, name, navItem) {
      let section = navItem && navItem.section
      let isCustom = navItem && navItem.isCustom
      $event.preventDefault()
      if (isCustom) {
        // console.log('-- custom scroll to')
        this.onCustomScrollTo(navItem)
      } else if (this.$route.name !== name) {
        // console.log('-- replace scroll to')
        this.$router.replace({ name: name, query: { anchor: section } })
      } else {
        // console.log('-- default scroll to')
        this.$scrollTo('#' + section, { offset: -20 })
      }
    },

    onCustomScrollTo (info) {
      this.$emit('on-custom-scroll-to', info)
    },

    onToggle: function () {
      this.showNav = !this.showNav
      var className = 'modal-open'
      var root = document.documentElement
      root.className = ''
      if (this.showNav) {
        root.className += className
      } else {
        root.className -= className
      }
      this.$emit('onToggle', this.showNav)
    },

    onClick: function ($event, path, navItem, navParentItem) {
      $event.preventDefault()
      // if (navItem && !navItem.subnav && navItem.hasOwnProperty('subnavOpen')) {
      //   this.$set(navItem, 'subnavOpen', false)
      // }
      // dismiss parent
      if (navParentItem && navParentItem.subnav && navParentItem.hasOwnProperty('subnavOpen')) {
        this.$set(navParentItem, 'subnavOpen', false)
      }
      if (typeof path === 'string' && path.length > 0 && path[0] !== '/') {
        path = `/${path}`
      }
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/app_setting.scss";

$navbar-background-color: transparent;

html.modal-open {
  .navbar-wrapper {
    background-color: $navbar-background-color;
  }
}

#navse {
  z-index: 20;
  // pointer-events: none;
  // padding-left: 0.75rem;
  // padding-right: 0.75rem;
  @media screen and (min-width: $desktop) {
    // pointer-events: auto;
  }
}

.navbar-wrapper {
  background-color: $navbar-background-color;
  // opacity: 0.8;

  .navbar-top {
     width: 100%;
     color: $color-white;
     font-family: $font-family;
     background-color: $color-black;

     a {
       &.fa {
         vertical-align: middle;
         color: $color-white;
         font-size: 0.8rem;
         padding: 8px 8px;

         &:nth-child(2) {
           padding-left: 25px;
         }

         &:last-child {
           padding-right: 5px;
         }

         &:hover {
           color: $color-gold;
         }
       }
     }
  }

  .navbar-full-wrapper {
    // padding-top: 6px;
    // padding-bottom: 6px;
  }

  // $fixed-navbar-height: 120px; // @search: 100height
  $fixed-navbar-height: 54px;
  $fixed-navbar-mobile-height: 54px;

  .navbar-full {
    height: $fixed-navbar-height;
    padding-left: calc(0.75rem + 10px);
    padding-right: calc(0.75rem + 10px);
    align-items: center;
    text-align: center;

    // this to workaround top bar positioning
    // position: absolute;
    // top: 0;
    // right: 0;
    // width: 40%;
    // this to workaround top bar positioning

    // background-color:rgba(0,0,0,0.5);
    @media screen and (max-width: $tablet) {
      height: $fixed-navbar-mobile-height;
    }

    // pointer-events: none;
    @media screen and (min-width: $desktop) {
      height: $fixed-navbar-height;
      // pointer-events: auto;
    }

    .dropdown-wrapper {
      // background-color: rgba(60, 60, 60, 1) !important;
      background-color: transparent !important;

      // border-top: 2px solid $color-blue;
      // border: 1px solid $color-gray;
      // border-radius: 3px;
      z-index: 8 !important;
      margin-left: 34%;
      margin-right: -34%;
      margin-top: -29px;
      padding-top: 8px;
      padding-bottom: 8px;

      .dropdown-item {
        padding: 0;
        &:hover {
          // background-color: rgba(30, 30, 30, 0.5);
          background-color: transparent !important;
        }

        .nav-item-link {
          margin: 0;
          padding: 0;

          .dropdown-item-inner {
            padding: 10px 18px;
          }
        }
      }
    }

    // .link-wrapper {
    //   &:last-child {
    //     // to fix last one extended beyond border
    //     margin-right: 18px;
    //   }
    // }

    .column {

      a {
        font-family: $font-family;
        font-weight: normal;
        color: $color-white;
        font-size: 14px;
        border-bottom: 0px solid $color-gray-light;
        transition: border 1.2s linear;

        .fa {
          font-size: 0.87rem;
        }

        @media screen and (max-width: 1160px) {
          margin: 0 0rem;
        }

        padding: 6px 4px;

        @media screen and (min-width: $desktop) {
          padding: 6px 4px;
        }

        @media screen and (min-width: $fullhd) {
          padding: 6px 4px;
        }

        &.is-parent { // nav-main menu
          display: block;
          padding: 12px 16px 12px 16px;

          @media screen and (min-width: $desktop) {
            padding: 12px 20px 12px 20px;
          }

          @media screen and (min-width: $fullhd) {
            padding: 12px 24px 12px 24px;
          }
        }

        span {
          padding-bottom: 5px;
        }

        &:hover {
          color: $color-gray-mid;
          // background-color: rgba(30, 30, 30, 0.5);
        }

        &.logo-wrapper:hover {
          background-color: transparent;
        }

        &.active span {
          // border-bottom: 2px solid $color-blue;
        }

        &.active {
          border-bottom: 1px solid $color-gray-light;
        }

        &.selected {
          .type-content {
            border-bottom: 1px solid $color-gray-light;
            transition: border 1.2s linear;
          }
        }

        &.fa {
          color: $color-orange;
          font-size: 1.4rem;
          padding: 8px 12px;

          &:last-child {
            padding-right: 5px;
          }

          &:hover {
            color: $color-gray-mid;
          }
        }
      }

      .navbar-logo {
        width: 320px;
        display: inline-block;
        float: right;
        margin-top: 8px;
        backgroud-color: gray;

        .title {
          color: white;
          font-family: 'Dosis';
          font-weight: 600;
          font-size: 18px;
          letter-spacing: 5px;
          margin-top: 3px;
          margin-bottom: 3px;

          @media screen and (max-width: $mobile) {
            font-size: 14px;
          }
        }
      }

      &.navbar-brand,
      &.navbar-menu {
        padding: 0px;
        margin: 0px;
      }

      &.navbar-menu {
        @media screen and (min-width: $desktop) {
          margin-left: 20px;
        }
      }

      &.navbar-brand {
        img {
          // margin-top: 20px;
          width: 220px;

          @media screen and (min-width: $tablet) {
          }

          @media screen and (min-width: $desktop) {
          }
        }
      }

      .button {
        font-size: 1.0em;
      }

      .link-ticket-wrapper {
        position: relative;

        .bg {
          width: 200px;
          position: absolute;
          top: 10px;
          right: 0;

          img {
            width: 200px;
          }
        }

        .text {
          position: absolute;
          top: 10px;
          right: 0;
          padding: 8px 23px;

          a {
            color: $color-black;

            &:hover {
              color: $color-gray;
            }
          }
        }
      }
    } // column

    .columns {
      height: 100%;
    }
  }

  .navbar-menu {
    height: 100%;
    margin: 0px;
    padding: 0px;
    margin-top: 5px;
    pointer-events: auto;
    box-shadow: 0 0 0;

    @media screen and (min-width: ($mobile + 1)) {
      display: none;
    }

    // abnormal fix on resizing back and forth
    html.modal-open & {

      @media screen and (min-width: ($mobile + 1)) {
        display: inherit;
      }
    }

    // cursor: pointer;
    // pointer-events: none;
    // @media screen and (min-width: ($mobile + 1)) {
    // 	pointer-events: auto;
    // }

    & .control-menu {
      cursor: pointer;
      padding: 8px 25px 16px 0px;
      // left: calc(50% - 12px);
      position: absolute;
      top: 25px;
      left: 0; // identical right

      & span {

        &, &:before, &:after {
          height: 2px;
          display: block;
          background: $color-white;
          position: absolute;
          // top: 45%;
          // right: 15px;
          width: 25px;
          display: block;
          content: '';
          transition: all 200ms ease-in-out;

          @media screen and (min-width: $desktop) {
            height: 2px;
            width: 30px;
            top: 50%;
          }
        }

        &:before, &:after {
          right: 0px;
          width: 25px;

          @media screen and (min-width: $desktop) {
            width: 25px;
          }
        }

        &:before {
          top: -6px;

          @media screen and (min-width: $desktop) {
            top: -8px;
          }
        }

        &:after {
          top: 6px;

          @media screen and (min-width: $desktop) {
            top: 8px;
          }
        }
      }
    }

    & .active span {
      background-color: transparent;
      border-bottom: 0px;
      right: 0px; // identical right

      &:before, &:after {
        top: 0;
        background: $color-white;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

</style>
