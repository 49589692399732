<template>
  <div class="page-notfound">
    <div-container>
      <div class="hero is-primary">
        <div class="hero-body content has-text-centered">
          <div style="padding:30px;">
            <h1 class="title">
              Uh oh
            </h1>
            <h2 class="subtitle">
              404 Page Not Found
            </h2>
          </div>
        </div>
      </div>
    </div-container>
  </div>
</template>

<script>
import DivContainer from '@/components/DivContainer'
export default {
  name: 'page-notfound',
  components: {
    DivContainer,
  },
  data () {
    return {
      isDesktop: true
    }
  }
}
</script>

<style lang="scss">
.page-notfound {
  position: relative;

  .hero {
    &.is-primary {
      background-color: transparent;

      .title, .subtitle {
        color: $color-black;
      }
    }
  }
}

</style>
